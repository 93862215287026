import React from 'react';
import {createBrowserRouter, Outlet, RouterProvider} from "react-router-dom";


import './App.css';
import './Homepage.css'; 
import Home from './pages/Home';
import Create from './pages/Create';
import Login from './pages/Login';
import Post from './pages/Post';
import Register from './pages/Register';
import Samples from './pages/Samples';
import Footer from './components/Footer';
import Header from './components/Header';
import Order from './pages/Order';
import Mdadminpage from './pages/Mdadminpage';
import Scrolltotop from './components/Scrolltotop';
import Terms from './pages/Terms';
import Privacypolicy from './pages/Privacypolicy';
import Bsn from './pages/Nursing/Bsn';
import Msn from './pages/Nursing/Msn';
import Dnp from './pages/Nursing/Dnp';
import Bspsy from './pages/Psychology/Bspsy';
import Bspsypre from './pages/Psychology/Bspsypre';
import Msapplied from './pages/Psychology/Msapplied';
import Msclinical from './pages/Psychology/Msclinical';
import Mspsy from './pages/Psychology/Mspsy';
import Msschool from './pages/Psychology/Msschool';
import Edsschool from './pages/Psychology/Edsschool';
import Phdbehaviour from './pages/Psychology/Phdbehaviour';
import Phdpsyc from './pages/Psychology/Phdpsyc';
import Psydclinical from './pages/Psychology/Psydclinical';
import Psydschool from './pages/Psychology/Psydschool';
import Service from './pages/Services/Service';
import Thankyou from './pages/Thankyou';
import CapellaFlexpath from './pages/Services/CapellaFlexpath';
import MsnFlexpath from './pages/Services/MsnFlexpath';
import Dnpflexpath from './pages/Services/Dnpflexpath';
import CapellacapstoneBsn from './pages/Services/Capellacapstonebsn';
import Nursingdissertation from './pages/Services/Nursingdissertation';
import CapellaFlexPathAssisstance from './pages/Services/Capellaflexpathassisstance';
import DNPCapstoneProjectHelp from './pages/Services/Dnpcapstoneprojecthelp';
import DNPDissertationHelp from './pages/Services/DNPdissertationhelp';
import CapellaUniversityHelp from './pages/Services/Capellauniversityhelp';
import ReliableNursingHomeworkHelp from './pages/Services/Reliablenursinghomeworkhelp';
import BoostAcademicPerformance from './pages/Services/Boostacademicperformance';
import TakeMyOnlineClass from './pages/Services/Takemyonlineclass';
import AcademicAssignmentHelp from './pages/Services/Academicassignmenthelp';
import AssignmentWritingServices from './pages/Services/Assignmentwritingservices';
import NursingAssignment from './pages/Services/Nursingassignment';
import CheapAssignmentWriting from './pages/Services/Cheapassignmentwriting ';
import DoMyAssignment from './pages/Services/Domyassignment ';
import OnlineNursingClass from './pages/Services/Onlinenursingclass';
import Hiresomeonetdoyourclass from './pages/Services/Hiresomeonetdoyourclass';
import DoMyClass from './pages/Services/Domyclass';
import OnlineClassHelp from './pages/Services/Onlineclasshelp';
import TakeMyClassOnline from './pages/Services/Takemyclassonline';
import Paysomeonetotakemyclass from './pages/Services/Paysomeonetotakemyclass';
import Searchingforaperson from './pages/Services/Searchingforaperson';
import TakeMyOnlineCourse from './pages/Services/Takemyonlinecourse';
import Unlockingacademicsuccess from './pages/Services/Unlockingacademicsuccess';
import Paytotakemyonlineclass from './pages/Services/Paytotakemyonlineclass';
import Paysomeonetotakeonlineclasses from './pages/Services/Paysomeonetotakeonlineclasses';
import Domyclassforme from './pages/Services/Domyclassforme';
import Mbafpx5002mbaleadership from './pages/Services/Mbafpx5002mbaleadership';
import Mbafpx5006businessstrategy from './pages/Services/Mbafpx5006businessstrategy';
import Mbafpx5008appliedbusinessanalytics from './pages/Services/Mbafpx5008appliedbusinessanalytics';
import Mba5010accountingmethodsforleaders from './pages/Services/Mba5010accountingmethodsforleaders';
import Mbafpx5012marketingmanagement from './pages/Services/Mbafpx5012marketingmanagement';
import Mbafpx5014appliedmanagerialfinance from './pages/Services/Mbafpx5014appliedmanagerialfinance';
import Mbafpx5016operationsmanagementforleaders from './pages/Services/Mbafpx5016operationsmanagementforleaders';
import Mhafpx5001 from './pages/Services/Mhafpx5001';
import Mhafpx5006 from './pages/Services/Mhafpx5006';
import Mhafpx5010 from './pages/Services/Mhafpx5010';

import NURSFPX4060 from './pages/Samples/Nursfpx4060';
import Nursfpx from './pages/Samples/Nursfpx';
import Nursfpx4050assessment2 from './pages/Samples/Nursfpx4050assessment2';
import Nursfpx4060assessment4 from './pages/Samples/Nursfpx4060assessment4';
import Nursfpx4060assessment2 from './pages/Samples/Nursfpx4060assessment2';
import Nursfpx4020assessment4 from './pages/Samples/Nursfpx4020assessment4';
import Nursfpx4000assessment2 from './pages/Samples/Nursfpx4000assessment2';
import Nursfpx4030assessment1 from './pages/Samples/Nursfpx4030assessment1';
import Nursfpx4030assessment2 from './pages/Samples/Nursfpx4030assessment2';
import Nursfpx4000assessment1 from './pages/Samples/Nursfpx4000assessment1';
import Mhafpx5006A1 from './pages/Samples/Mhafpx5006A1';
import Mhafpx5006A2 from './pages/Samples/Mhafpx5006A2';
import Mhafpx5006A3 from './pages/Samples/Mhafpx5006A3';
import Mhafpx5006A4 from './pages/Samples/Mhafpx5006A4';
import Mhafpx5012 from './pages/Services/Mhafpx5012';
import Mhafpx5014 from './pages/Services/Mhafpx5014';
import Nursfpx8030a2 from './pages/Samples/Nursfpx8030a2';
import Nursfpx6016a1 from './pages/Samples/Nursfpx6016a1';
import Nursfpx5004a2 from './pages/Samples/Nursfpx5004a2';
import Nursfpx5004a3 from './pages/Samples/Nursfpx5004a3';
import Mbafpx5006A5 from './pages/Samples/Mbafpx5006A5';
import Nursfpx4010assessment1 from './pages/Samples/Nursfpx4010assessment1';
import Nursfpx4060assessment3 from './pages/Samples/Nursfpx4060assessment3';
import Nursfpx4010assessment2 from './pages/Samples/Nursfpx4010assessment2';
import Nursfpx8010assessment1 from './pages/Samples/Nursfpx8010assessment1';
import Nursfpx4040assessment1 from './pages/Samples/Nursfpx4040assessment1';
import Nursfpx4030assessment4 from './pages/Samples/Nursfpx4030assessment4';
import Nursfpx4040assessment3 from './pages/Samples/Nursfpx4040assessment3';
import Nursfpx4060assessment1 from './pages/Samples/Nursfpx4060assessment1';
import Nursfpx4900assessment5 from './pages/Samples/Nursfpx4900assessment5';
import Nursfpx4900assessment6 from './pages/Samples/Nursfpx4900assessment6';
import Nursfpx6004assessment2 from './pages/Samples/Nursfpx6004assessment2';
import Nursfpx4050assessment3 from './pages/Samples/Nursfpx4050assessment3';
import Mbafpx5005assessment1 from './pages/Samples/Mbafpx5005assessment1';
import Mbafpx5006assessment1 from './pages/Samples/Mbafpx5006assessment1';
import Hypofpx5004assessment4 from './pages/Samples/Hypofpx5004assessment4';
import Bhafpx4104assessment1 from './pages/Samples/Bhafpx4104assessment1';
import Bhafpx4102assessment4 from './pages/Samples/Bhafpx4102assessment4';
import Nursfpx6004assessment2policyproposal from './pages/Samples/Nursfpx6004assessment2policyproposal';
import Nursfpx6111assessment1 from './pages/Samples/Nursfpx6111assessment1';
import Nursfpx6004assessment1 from './pages/Samples/Nursfpx6004assessment1';
import Nursfpx6011assessment2 from './pages/Samples/Nursfpx6011assessment2';
import Nursfpx6216assessment2 from './pages/Samples/Nursfpx6216assessment2';
import Nursfpx6004assessment3 from './pages/Samples/Nursfpx6004assessment3';
import Nursfpx6412assessment2 from './pages/Samples/Nursfpx6412assessment2';
import Nursfpx6025assessment2 from './pages/Samples/Nursfpx6025assessment2';
import Nursfpx6025assessment3 from './pages/Samples/Nursfpx6025assessment3';
import Bhafpx4020assessment2 from './pages/Samples/Bhafpx4020assessment2';
import Bhafpx4112assessment2 from './pages/Samples/Bhafpx4112assessment2';
import Nhsfpx4000assessment3 from './pages/Samples/Nhsfpx4000assessment3';
import Psycfpx3500assessment1 from './pages/Samples/Psycfpx3500assessment1';
import Nurs4900capstoneprojectfornursing from './pages/Services/Nurs4900capstoneprojectfornursing';
import Psycfpx3210assessment2 from './pages/Samples/Psycfpx3210assessment2';
import Nu631unit14dq from './pages/Samples/Nu631unit14dq';
import Dbafpx8420assessment1 from './pages/Samples/Dbafpx8420assessment1';
import Dbafpx8410assessment1 from './pages/Samples/Dbafpx8410assessment1';
import Dnp825topic3 from './pages/Samples/Dnp825topic3';
import Nu631unit14dq2 from './pages/Samples/Nu631unit14dq2';
import Cs212m5assignment from './pages/Samples/Cs212m5assignment';
import Hlt326topic1assignment from './pages/Samples/Hlt326topic1assignment';
import Dnp830topic1assignment from './pages/Samples/Dnp830topic1assignment';
import Dnp820topic5assignment from './pages/Samples/Dnp820topic5assignment';
import Ef205m3assessment from './pages/Samples/Ef205m3assessment';
import Mt140m2assignment from './pages/Samples/Mt140m2assignment';
import Mt140m1assignment1 from './pages/Samples/Mt140m1assignment1';
import Ha255m1assignment from './pages/Samples/Ha255m1assignment';
import Cs212m5assignmentcommunicatinginteams from './pages/Samples/Cs212m5assignmentcommunicatinginteams';
import Ss2901m1assignment from './pages/Samples/Ss2901m1assignment';
import Mt140m5assignment from './pages/Samples/Mt140m5assignment';
import Mt140m1competencyassessmentsolving from './pages/Samples/Mt140m1competencyassessmentsolving';
import Ha405m5assessment from './pages/Samples/Ha405m5assessment';
import Ha405m6assignment from './pages/Samples/Ha405m6assignment';
import Hs230m5assignment from './pages/Samples/Hs230m5assignment';
import Hs230m1assignment from './pages/Samples/Hs230m1assignment';
import N547week7assessment from './pages/Samples/N547week7assessment';
import Nursfpx6210assignment1 from './pages/Samples/Nursfpx6210assignment1';
import Nursfpx6212assignment4 from './pages/Samples/Nursfpx6212assignment4';
import N547week6assignment from './pages/Samples/N547week6assignment';
import N547week4assessment from './pages/Samples/N547week4assessment';
import Cs212m4assignment from './pages/Samples/Cs212m4assignment';
import Capellaflexpathrntobsn from './pages/Services/Capellaflexpathrntobsn';
import Capellauniversityflexpathnursing from './pages/Services/Capellauniversityflexpathnursing';
import Nursfpx6008assignment2 from './pages/Samples/Nursfpx6008assignment2';
import Ef205m1assignment from './pages/Samples/Ef205m1assignment';
import Mt140m4assignment from './pages/Samples/Mt140m4assignment';
import N599week4assignment from './pages/Samples/N599week4assignment';
import N550week5assignment from './pages/Samples/N550week5assignment';
import N550week4assignment from './pages/Samples/N550week4assignment';
import Mbafpx5012assessment1 from './pages/Samples/Mbafpx5012assessment1';
import Socfpx2000assessment3s from './pages/Samples/Socfpx2000assessment3s';
import Ef205m3assessmente from './pages/Samples/Ef205m3assessmente';
import Mt140m3assignment from './pages/Samples/Mt140m3assignment';
import Hu200m5assignment from './pages/Samples/Hu200m5assignment';
import Hs410m6assessment from './pages/Samples/Hs410m6assessment';
import Nursfpx6021assessment2 from './pages/Samples/Nursfpx6021assessment2';
import Nursfpx6030assessment2 from './pages/Samples/Nursfpx6030assessment2';
import Nursfpx6616assessment1 from './pages/Samples/Nursfpx6616assessment1';
import Psycfpx3210assessment3 from './pages/Samples/Psycfpx3210assessment3';
import N508week8assignment1 from './pages/Samples/N508week8assignment1';
import N508week6assignment from './pages/Samples/N508week6assignment';
import Hs450m2effectiveteambuilding from './pages/Samples/Hs450m2effectiveteambuilding';
import Mbafpx5910assessment2 from './pages/Samples/Mbafpx5910assessment2';
import Hs165m4assignment from './pages/Samples/Hs165m4assignment';
import Hs111m1assessment from './pages/Samples/Hs111m1assessment';
import Ha415m1assessment from './pages/Samples/Ha415m1assessment';
import Ha610unit5assignment from './pages/Samples/Ha610unit5assignment';
import Ha610unit6assignment from './pages/Samples/Ha610unit6assignment';
import N599module2assignment from './pages/Samples/N599module2assignment';
import N537module8assignment from './pages/Samples/N537module8assignment';
import N599module2assignmentn from './pages/Samples/N599module2assignmentn';
import Busfpx3040assessment3 from './pages/Samples/Busfpx3040assessment3';
import N586module1assignment3 from './pages/Samples/N586module1assignment3';
import Mhafpx5020assessment1 from './pages/Samples/Mhafpx5020assessment1';
import Mhafpx5016assessment4 from './pages/Samples/Mhafpx5016assessment4';
import Phifpx3200assessment2 from './pages/Samples/Phifpx3200assessment2';
import Nursfpx6218assessment3 from './pages/Samples/Nursfpx6218assessment3';
import Nursfpx9904assessment1 from './pages/Samples/Nursfpx9904assessment1';
import Nursfpx8040assessment3 from './pages/Samples/Nursfpx8040assessment3';
import Nursfpx5004assessment1 from './pages/Samples/Nursfpx5004assessment1';
import Hs101m1assignmenth from './pages/Samples/Hs101m1assignmenth';
import Nursfpx8012assessment1 from './pages/Samples/Nursfpx8012assessment1';
import Dnp830moduleassignment from './pages/Samples/Dnp830moduleassignment';
import Dnp830moduleassignmentg from './pages/Samples/Dnp830moduleassignmentg';
import Dnp830module7assignment from './pages/Samples/Dnp830module7assignment';
import Dnp830module8assignment from './pages/Samples/Dnp830module8assignment';
import DNP830Module3Assignment from './pages/Samples/Dnp830module3assignment';
import Nursfpx8040assessment1 from './pages/Samples/Nursfpx8040assessment1';
import Nursfpx9904assessment2 from './pages/Samples/Nursfpx9904assessment2';
import Nursfpx9904assessment3 from './pages/Samples/Nursfpx9904assessment3';
import Nursfpx9904assessment4 from './pages/Samples/Nursfpx9904assessment4';
import Nursfpx9904assessment5 from './pages/Samples/Nursfpx9904assessment5';
import Nursfpx9904assessment from './pages/Samples/Nursfpx9904assessment';
import Psycfpx2200assessment2 from './pages/Samples/Psycfpx2200assessment2';
import Psycfpx2700assessment1 from './pages/Samples/Psycfpx2700assessment1';
import Mhafpx5020assessment5 from './pages/Samples/Mhafpx5020assessment5';
import Nurs8123assignment2 from './pages/Samples/Nurs8123assignment2';
import Mhafpx5020assessment4 from './pages/Samples/Mhafpx5020assessment4';
import Mhafpx5020assessment3 from './pages/Samples/Mhafpx5020assessment3';
import Mhafpx5020assessment2 from './pages/Samples/Mhafpx5020assessment2';
import Mhafpx5016assessment2 from './pages/Samples/Mhafpx5016assessment2';
import Mhafpx5016assessment3 from './pages/Samples/Mhafpx5016assessment3';
import Nurs8123assignment7 from './pages/Samples/Nurs8123assignment7';
import Nurs8123week2assessment from './pages/Samples/Nurs8123week2assessment';
import Nurs8123assignment5 from './pages/Samples/Nurs8123assignment5';
import Dnp845module6assignment from './pages/Samples/Dnp845module6assignment';
import Nurs8123assignment6 from './pages/Samples/Nurs8123assignment6';
import Dnp845week8assignment1 from './pages/Samples/Dnp845week8assignment1';
import Universitypage from './pages/University/Universitypage';
import Mhafpx5012assessment2 from './pages/Samples/Mhafpx5012assessment2';
import Nursfpx9100assessment1 from './pages/Samples/Nursfpx9100assessment1';
import Nursfpx9100assessment4 from './pages/Samples/Nursfpx9100assessment4';
import Dnp845week7assignment from './pages/Samples/Dnp845week7assignment';
import Dnp850module3assignment from './pages/Samples/Dnp850module3assignment';
import Dnp850module4assignment from './pages/Samples/Dnp850module4assignment';
import Dnp850module8assignment from './pages/Samples/Dnp850module8assignment';
import Dnp850module5assignment from './pages/Samples/Dnp850module5assignment';
import Dnp855module1assignment1 from './pages/Samples/Dnp855module1assignment1';
import Dnp855module2assignment from './pages/Samples/Dnp855module2assignment';
import Dnp830module4assignment from './pages/Samples/Dnp830module4assignment';
import Dnpmodule4assignment1 from './pages/Samples/Dnpmodule4assignment1';
import Mbafpx5010assessment3 from './pages/Samples/Mbafpx5010assessment3';
import N547Assignment2 from './pages/Samples/N547Assignment2';
import Bhafpx4106assessment1 from './pages/Samples/Bhafpx4106assessment1';
import Mbafpx5002assessment3 from './pages/Samples/Mbafpx5002assessment3';
import Hrmfpx5310assessment1 from './pages/Samples/Hrmfpx5310assessment1';
import Hrmfpx5310assessment3 from './pages/Samples/Hrmfpx5310assessment3';
import Biofpx1000assessment2 from './pages/Samples/Biofpx1000assessment2';
import Nursing547module6assignment from './pages/Samples/Nursing547module6assignment';
import Nursing550assignment8 from './pages/Samples/Nursing550assignment8';
import N599module2assignmentnc from './pages/Samples/N599module2assignmentnc';
import N547week2assignment from './pages/Samples/N547week2assignment';
import N550module1assignment from './pages/Samples/N550module1assignment';
import Humfpx1150assessment4 from './pages/Samples/Humfpx1150assessment4';
import Nursfpx6216assessment1 from './pages/Samples/Nursfpx6216assessment1';
import Nursfpx6212assessment2 from './pages/Samples/Nursfpx6212assessment2';
import N538week1assignment from './pages/Samples/N538week1assignment';
import N550module1assignment1 from './pages/Samples/N550module1assignment1';
import N550module1assignment1p from './pages/Samples/N550module1assignment1p';
import N550module8assignment from './pages/Samples/N550module8assignment';
import N508week5assignment from './pages/Samples/N508week5assignment';
import N550week1assignment2 from './pages/Samples/N550week1assignment2';
import N586module3assignment from './pages/Samples/N586module3assignment';
import Mhafpx5012assessment4 from './pages/Samples/Mhafpx5012assessment4';
import Nursfpx8030assessment4 from './pages/Samples/Nursfpx8030assessment4';
import Bhafpx4110assessment1 from './pages/Samples/Bhafpx4110assessment1';
import Nursfpx8002assessment2 from './pages/Samples/Nursfpx8002assessment2';
import N600assignment2 from './pages/Samples/N600assignment2';
import N600assignment3 from './pages/Samples/N600assignment3';
import Bhafpx4010assessment1 from './pages/Samples/Bhafpx4010assessment1';
import N600assignment3a from './pages/Samples/N600assignment3a';
import N600assignment3b from './pages/Samples/N600assignment3b';
import N600assignment4 from './pages/Samples/N600assignment4';
import N600assignment5 from './pages/Samples/N600assignment5';
import N600assignment7 from './pages/Samples/N600assignment7';
import N600assignment7a from './pages/Samples/N600assignment7a';
import N599Module5Assignment from './pages/Samples/N599Module5Assignment';
import N599Module6Assignment from './pages/Samples/N599Module6Assignment';
import N600assignment8 from './pages/Samples/N600assignment8';
import N601assignment3 from './pages/Samples/N601assignment3';
import Dnp815week2assignment from './pages/Samples/Dnp815week2assignment';
import Bhafpx4106assessment3 from './pages/Samples/Bhafpx4106assessment3';
import Nursfpx9901assessment3 from './pages/Samples/Nursfpx9901assessment3';
import Nursfpx6612assessment1 from './pages/Samples/Nursfpx6612assessment1';
import Nursfpx4005assessment1 from './pages/Samples/Nursfpx4005assessment1';
import Biofpx1000assessment2b from './pages/Samples/Biofpx1000assessment2b';
import Nursfpx6025assessment6 from './pages/Samples/Nursfpx6025assessment6';
import Bhafpx4104assessment2 from './pages/Samples/Bhafpx4104assessment2';
import Nursfpx5006assessment1 from './pages/Samples/Nursfpx5006assessment1';
import Bhafpx4110assessment2 from './pages/Samples/Bhafpx4110assessment2';
import Bhafpx4106assessment2 from './pages/Samples/Bhafpx4106assessment2';
import Nursfpx8045assessment7 from './pages/Samples/Nursfpx8045assessment7';
import Nursfpx8045assessment5 from './pages/Samples/Nursfpx8045assessment5';
import Nursfpx8045assessment3 from './pages/Samples/Nursfpx8045assessment3';
import Nursfpx6216assessment3 from './pages/Samples/Nursfpx6216assessment3';
import Bhafpx4110assessment3 from './pages/Samples/Bhafpx4110assessment3';
import Nursfpx8030assessment2 from './pages/Samples/Nursfpx8030assessment2';
import Nursfpx8014assessment1 from './pages/Samples/Nursfpx8014assessment1';
import Nursfpx8010assessment2 from './pages/Samples/Nursfpx8010assessment2';
import Mbafpx5002assessment2 from './pages/Samples/Mbafpx5002assessment2';
import Bhafpx4104assessment4 from './pages/Samples/Bhafpx4104assessment4';
import Bhafpx4104assessment3 from './pages/Samples/Bhafpx4104assessment3';
import Mbafpx5002assessment1 from './pages/Samples/Mbafpx5002assessment1';
import Bhafpx4008assessment2 from './pages/Samples/Bhafpx4008assessment2';
import Bhafpx4010assessment4 from './pages/Samples/Bhafpx4010assessment4';
import Mbafpx5007assessment1 from './pages/Samples/Mbafpx5007assessment1';
import Bhafpx4010assessment2 from './pages/Samples/Bhafpx4010assessment2';
import Nursfpx9902assessment3 from './pages/Samples/Nursfpx9902assessment3';
import Mbafpx5007assessment3 from './pages/Samples/Mbafpx5007assessment3';
import Nursfpx8030assessment3 from './pages/Samples/Nursfpx8030assessment3';
import Rschfpx7864assessment4 from './pages/Samples/Rschfpx7864assessment4';
import Nursfpx8010assessment4 from './pages/Samples/Nursfpx8010assessment4';
import Nursfpx8010assessment3 from './pages/Samples/Nursfpx8010assessment3';
import Rschfpx7864assessment3 from './pages/Samples/Rschfpx7864assessment3';
import Bhafpx4020assessment1 from './pages/Samples/Bhafpx4020assessment1';
import Bhafpx4020assessment3 from './pages/Samples/Bhafpx4020assessment3';
import Nursfpx4005assessment2 from './pages/Samples/Nursfpx4005assessment2';
import Nursfpx4005assessment3 from './pages/Samples/Nursfpx4005assessment3';
import Nursfpx4005assessment4 from './pages/Samples/Nursfpx4005assessment4';
import Nursfpx4015assessment1 from './pages/Samples/Nursfpx4015assessment1';
import Nursfpx4015assessment2 from './pages/Samples/Nursfpx4015assessment2';
import Nursfpx4015assessment3 from './pages/Samples/Nursfpx4015assessment3';
import Nursfpx4015assessment4 from './pages/Samples/Nursfpx4015assessment4';
import Nursfpx4025assessment1 from './pages/Samples/Nursfpx4025assessment1';
import Nursfpx4025assessment2 from './pages/Samples/Nursfpx4025assessment2';
import Nursfpx4025assessment3 from './pages/Samples/Nursfpx4025assessment3';
import Nursfpx4025assessment4 from './pages/Samples/Nursfpx4025assessment4';
import Nursfpx4035assessment1 from './pages/Samples/Nursfpx4035assessment1';
import Nursfpx4035assessment2 from './pages/Samples/Nursfpx4035assessment2';
import Nursfpx4035assessment3 from './pages/Samples/Nursfpx4035assessment3';
import Nursfpx4035assessment4 from './pages/Samples/Nursfpx4035assessment4';
import Nursfpx4045assessment1 from './pages/Samples/Nursfpx4045assessment1';
import Nursfpx4045assessment2 from './pages/Samples/Nursfpx4045assessment2';
import Nursfpx4045assessment3 from './pages/Samples/Nursfpx4045assessment3';
import Nursfpx4045assessment4 from './pages/Samples/Nursfpx4045assessment4';
import Nursfpx4055assessment1 from './pages/Samples/Nursfpx4055assessment1';
import Nursfpx4055assessment2 from './pages/Samples/Nursfpx4055assessment2';
import Nursfpx4055assessment3 from './pages/Samples/Nursfpx4055assessment3';
import Nursfpx4055assessment4 from './pages/Samples/Nursfpx4055assessment4';
import Nursfpx4065assessment1 from './pages/Samples/Nursfpx4065assessment1';
import Nursfpx4065assessment2 from './pages/Samples/Nursfpx4065assessment2';
import Nursfpx4065assessment3 from './pages/Samples/Nursfpx4065assessment3';
import Nursfpx4065assessment4 from './pages/Samples/Nursfpx4065assessment4';
import Nursfpx4905assessment1 from './pages/Samples/Nursfpx4905assessment1';
import Nursfpx4905assessment2 from './pages/Samples/Nursfpx4905assessment2';
import Nursfpx4905assessment3 from './pages/Samples/Nursfpx4905assessment3';
import Nursfpx4905assessment4 from './pages/Samples/Nursfpx4905assessment4';
import Bhafpx4102assessment2 from './pages/Samples/Bhafpx4102assessment2';
import Bhafpx4102assessment3 from './pages/Samples/Bhafpx4102assessment3';
import Bhafpx4108assessment1 from './pages/Samples/Bhafpx4108assessment1';
import Bhafpx4108assessment3 from './pages/Samples/Bhafpx4108assessment3';
import Bhafpx4108assessment4 from './pages/Samples/Bhafpx4108assessment4';
import Mbafpx5012assessment3 from './pages/Samples/Mbafpx5012assessment3';
import Mbafpx5010assessment2 from './pages/Samples/Mbafpx5010assessment2';
import Rschfpx7864assessment1 from './pages/Samples/Rschfpx7864assessment1';
import Bhafpx4102assessment1 from './pages/Samples/Bhafpx4102assessment1';
import Bhafpx4009assessment1 from './pages/Samples/Bhafpx4009assessment1';
import Bhafpx4004assessment1 from './pages/Samples/Bhafpx4004assessment1';
import Mbafpx5910assessment1 from './pages/Samples/Mbafpx5910assessment1';
import Nursfpx6620assessment1 from './pages/Samples/Nursfpx6620assessment1';
import Nursfpx6620assessment2 from './pages/Samples/Nursfpx6620assessment2';
import Nursfpx6620assessment3 from './pages/Samples/Nursfpx6620assessment3';
import Nursfpx6620assessment4 from './pages/Samples/Nursfpx6620assessment4';
import Nursfpx6622assessment1 from './pages/Samples/Nursfpx6622assessment1';
import Nursfpx6622assessment2 from './pages/Samples/Nursfpx6622assessment2';
import Nursfpx6622assessment3 from './pages/Samples/Nursfpx6622assessment3';
import Nursfpx6622assessment4 from './pages/Samples/Nursfpx6622assessment4';
import Nursfpx6624assessment1 from './pages/Samples/Nursfpx6624assessment1';
import Nursfpx6624assessment2 from './pages/Samples/Nursfpx6624assessment2';
import Nursfpx6624assessment3 from './pages/Samples/Nursfpx6624assessment3';
import Nursfpx6624assessment4 from './pages/Samples/Nursfpx6624assessment4';
import Nursfpx6626assessment1 from './pages/Samples/Nursfpx6626assessment1';
import Nursfpx6626assessment2 from './pages/Samples/Nursfpx6626assessment2';
import Nursfpx6626assessment3 from './pages/Samples/Nursfpx6626assessment3';
import Nursfpx6626assessment4 from './pages/Samples/Nursfpx6626assessment4';
import Nursfpx6080assessment1 from './pages/Samples/Nursfpx6080assessment1';
import Nursfpx6080assessment2 from './pages/Samples/Nursfpx6080assessment2';
import Nursfpx6080assessment3 from './pages/Samples/Nursfpx6080assessment3';
import Nursfpx6080assessment4 from './pages/Samples/Nursfpx6080assessment4';
import Nursfpx6085assessment1 from './pages/Samples/Nursfpx6085assessment1';
import Nursfpx6085assessment2 from './pages/Samples/Nursfpx6085assessment2';
import Nursfpx6085assessment3 from './pages/Samples/Nursfpx6085assessment3';
import Nursfpx6085assessment4 from './pages/Samples/Nursfpx6085assessment4';
import Psy250week4 from './pages/Samples/Psy250week4';
import Soc315week1 from './pages/Samples/Soc315week1';
import Soc315week3 from './pages/Samples/Soc315week3';
import Soc315week4 from './pages/Samples/Soc315week4';
import Soc102topic3 from './pages/Samples/Soc102topic3';
import Jus212topic6 from './pages/Samples/Jus212topic6';
import Psy366topic6 from './pages/Samples/Psy366topic6';
import Bus4070unit5assignment1 from './pages/Samples/Bus4070unit5assignment1';
import Hcs475week5 from './pages/Samples/Hcs475week5';
import Psy250week2 from './pages/Samples/Psy250week2';
import Pys250week3 from './pages/Samples/Pys250week3';
import Nursfpx6212assessment3 from './pages/Samples/Nursfpx6212assessment3';
import Jus430week5 from './pages/Samples/Jus430week5';
import Jus430week6 from './pages/Samples/Jus430week6';
import Psy368topic4 from './pages/Samples/Psy368topic4';
import Nursfpx8030assessment1 from './pages/Samples/Nursfpx8030assessment1';
import Mhafpx5016assessment1 from './pages/Samples/Mhafpx5016assessment1';
import N601assignment1 from './pages/Samples/N601assignment1';
import Bhafpx4112assessment3 from './pages/Samples/Bhafpx4112assessment3';
import Mbafpx5007assessment2 from './pages/Samples/Mbafpx5007assessment2';
import Nursfpx9902assessment5 from './pages/Samples/Nursfpx9902assessment5';
import Nursfpx8002assessment3 from './pages/Samples/Nursfpx8002assessment3';
import Nursfpx6107assessment2 from './pages/Samples/Nursfpx6107assessment2';
import Mhafpx5012assessment1 from './pages/Samples/Mhafpx5012assessment1';
import Nursfpx6021assessment1 from './pages/Samples/Nursfpx6021assessment1';
import His12203assignment from './pages/Samples/His12203assignment';
import Nur3500assignment from './pages/Samples/Nur3500assignment';
import Nur3500assignmenta from './pages/Samples/Nur3500assignmenta';
import Nur4307assignment from './pages/Samples/Nur4307assignment';
import Nur3500module6assignment from './pages/Samples/Nur3500module6assignment';
import N584assignmentmodule1 from './pages/Samples/N584assignmentmodule1';
import Nr582week2assignment from './pages/Samples/Nr582week2assignment';
import Nr583week2assignment from './pages/Samples/Nr583week2assignment';
import Nr583week6assignment from './pages/Samples/Nr583week6assignment';
import Nr586npweek8 from './pages/Samples/Nr586npweek8';
import Hs450m4assignment from './pages/Samples/Hs450m4assignment';
import Hs450m6assessment from './pages/Samples/Hs450m6assessment';
import Hs165m2assignment from './pages/Samples/Hs165m2assignment';
import Hs230m3assignment from './pages/Samples/Hs230m3assignment';
import Ha255m4assignment from './pages/Samples/Ha255m4assignment';
import Nursfpx4040assessment2 from './pages/Samples/Nursfpx4040assessment2';
import Nursfpx6109assessment3 from './pages/Samples/Nursfpx6109assessment3';
import Nursfpx5005assessment4 from './pages/Samples/Nursfpx5005assessment4';
import Bhafpx4008assessment1 from './pages/Samples/Bhafpx4008assessment1';
import Nursfpx6100assessment1 from './pages/Samples/Nursfpx6100assessment1';
import Nursfpx6100assessment2 from './pages/Samples/Nursfpx6100assessment2';
import Nursfpx6100assessment3 from './pages/Samples/Nursfpx6100assessment3';
import Nursfpx6100assessment4 from './pages/Samples/Nursfpx6100assessment4';
import Nursfpx6108assessment1 from './pages/Samples/Nursfpx6108assessment1';
import Nursfpx6108assessment3 from './pages/Samples/Nursfpx6108assessment3';
import Nursfpx6108assessment4 from './pages/Samples/Nursfpx6108assessment4';
import Nursfpx6108assessment2 from './pages/Samples/Nursfpx6108assessment2';
import Nursfpx6112assessment1 from './pages/Samples/Nursfpx6112assessment1';
import Nursfpx6112assessment2 from './pages/Samples/Nursfpx6112assessment2';
import Nursfpx6112assessment3 from './pages/Samples/Nursfpx6112assessment3';
import Nursfpx6112assessment4 from './pages/Samples/Nursfpx6112assessment4';
import Nursfpx6116assessment1 from './pages/Samples/Nursfpx6116assessment1';
import Nursfpx6116assessment3 from './pages/Samples/Nursfpx6116assessment3';
import Nursfpx6116assessment4 from './pages/Samples/Nursfpx6116assessment4';
import Nursfpx6116assessment2 from './pages/Samples/Nursfpx6116assessment2';
import Nursfpx6400assessment1 from './pages/Samples/Nursfpx6400assessment1';
import Nursfpx6400assessment2 from './pages/Samples/Nursfpx6400assessment2';
import Nursfpx6400assessment3 from './pages/Samples/Nursfpx6400assessment3';
import Nursfpx6400assessment4 from './pages/Samples/Nursfpx6400assessment4';
import Nursfpx6422assessment1 from './pages/Samples/Nursfpx6422assessment1';
import Nursfpx6422assessment2 from './pages/Samples/Nursfpx6422assessment2';
import Nursfpx6422assessment3 from './pages/Samples/Nursfpx6422assessment3';
import Nursfpx6422assessment4 from './pages/Samples/Nursfpx6422assessment4';
import Nursfpx6424assessment1 from './pages/Samples/Nursfpx6424assessment1';
import Nursfpx6424assessment2 from './pages/Samples/Nursfpx6424assessment2';
import Nursfpx6424assessment3 from './pages/Samples/Nursfpx6424assessment3';
import Nursfpx6424assessment4 from './pages/Samples/Nursfpx6424assessment4';
import Nursfpx6426assessment1 from './pages/Samples/Nursfpx6426assessment1';
import Nursfpx6426assessment3 from './pages/Samples/Nursfpx6426assessment3';
import Nursfpx6426assessment4 from './pages/Samples/Nursfpx6426assessment4';
import Nursfpx6426assessment2 from './pages/Samples/Nursfpx6426assessment2';
import Nursfpx6200assessment1 from './pages/Samples/Nursfpx6200assessment1';
import Nursfpx6200assessment3 from './pages/Samples/Nursfpx6200assessment3';
import Nursfpx6200assessment4 from './pages/Samples/Nursfpx6200assessment4';
import Nursfpx6222assessment1 from './pages/Samples/Nursfpx6222assessment1';
import Nursfpx6222assessment2 from './pages/Samples/Nursfpx6222assessment2';
import Nursfpx6222assessment3 from './pages/Samples/Nursfpx6222assessment3';
import Nursfpx6222assessment4 from './pages/Samples/Nursfpx6222assessment4';
import Nursfpx6224assessment1 from './pages/Samples/Nursfpx6224assessment1';
import Nursfpx6200assessment2 from './pages/Samples/Nursfpx6200assessment2';
import Nursfpx6224assessment2 from './pages/Samples/Nursfpx6224assessment2';
import Nursfpx6224assessment3 from './pages/Samples/Nursfpx6224assessment3';
import Nursfpx6224assessment4 from './pages/Samples/Nursfpx6224assessment4';
import Nursfpx6226assessment1 from './pages/Samples/Nursfpx6226assessment1';
import Nursfpx6226assessment2 from './pages/Samples/Nursfpx6226assessment2';
import Nursfpx6226assessment3 from './pages/Samples/Nursfpx6226assessment3';
import Nursfpx6226assessment4 from './pages/Samples/Nursfpx6226assessment4';
import Mbafpx5910assessment3 from './pages/Samples/Mbafpx5910assessment3';
import Nr583week4 from './pages/Samples/Nr583week4';
import Nr584week3 from './pages/Samples/Nr584week3';
import Nr538week6assignment1 from './pages/Samples/Nr538week6assignment1';
import Mhafpx5012assessment3 from './pages/Samples/Mhafpx5012assessment3';
import Nursfpx6212assessment1 from './pages/Samples/Nursfpx6212assessment1';
import Nursfpx6214assessment3 from './pages/Samples/Nursfpx6214assessment3';
import Nursfpx8014assessment2 from './pages/Samples/Nursfpx8014assessment2';
import Soc102topic2 from './pages/Samples/Soc102topic2';
import Hcs487week5 from './pages/Samples/Hcs487week5';
import Nur504module2assignment1 from './pages/Samples/Nur504module2assignment1';
import Psy368topic5 from './pages/Samples/Psy368topic5';
import Nursfpx6026assessment1 from './pages/Samples/Nursfpx6026assessment1';
import Nur505module1assessment2 from './pages/Samples/Nur505module1assessment2';
import Nursfpx6105assessment1 from './pages/Samples/Nursfpx6105assessment1';
import Nursfpx6008assessment1 from './pages/Samples/Nursfpx6008assessment1';
import Nursfpx5010assessment4 from './pages/Samples/Nursfpx5010assessment4';
import Nursfpx6410assessment1 from './pages/Samples/Nursfpx6410assessment1';
import Nur600module2assignment from './pages/Samples/Nur600module2assignment';
import Nur600module3assignment from './pages/Samples/Nur600module3assignment';
import Nur505module3week4assignment from './pages/Samples/Nur505module3week4assignment';
import Nur506module4assessment from './pages/Samples/Nur506module4assessment';
import Nursfpx6011assessment3 from './pages/Samples/Nursfpx6011assessment3';
import Bhafpx4112assessment1 from './pages/Samples/Bhafpx4112assessment1';
import Nursfpx4030assessment3 from './pages/Samples/Nursfpx4030assessment3';
import Mbafpx5010assessment1 from './pages/Samples/Mbafpx5010assessment1';
import Nursfpx6410assessment3 from './pages/Samples/Nursfpx6410assessment3';
import Mbafpx5014assessment1 from './pages/Samples/Mbafpx5014assessment1';
import Nur600module5assessment from './pages/Samples/Nur600module5assessment';
import Nurs6051module5assignment from './pages/Samples/Nurs6051module5assignment';
import Nurs6050week6assignment from './pages/Samples/Nurs6050week6assignment';
import Nur506module2assignment from './pages/Samples/Nur506module2assignment';
import Nursfpx4900assessment3 from './pages/Samples/Nursfpx4900assessment3';
import Busfpx3030assessment4 from './pages/Samples/Busfpx3030assessment4';
import Bhafpx4010assessment3 from './pages/Samples/Bhafpx4010assessment3';
import Nursfpx6612assessment2 from './pages/Samples/Nursfpx6612assessment2';
import Nursfpx6030assessment3 from './pages/Samples/Nursfpx6030assessment3';
import Nursfpx6614assessment1 from './pages/Samples/Nursfpx6614assessment1';
import Nur505module5assignment from './pages/Samples/Nur505module5assignment';
import Nur501module4assignment1 from './pages/Samples/Nur501module4assignment1';
import Fnp593week2assignment from './pages/Samples/Fnp593week2assignment';
import Fnp593week3assignment from './pages/Samples/Fnp593week3assignmen';
import Nur600module2assignmentb from './pages/Samples/Nur600module2assignmentb';
import Fnp591week7assignment from './pages/Samples/Fnp591week7assignment';
import Rschfpx7864assessment2 from './pages/Samples/Rschfpx7864assessment2';
import Fnp597week5assignment from './pages/Samples/Fnp597week5assignment';
import Nur600week3assignment from './pages/Samples/Nur600week3assignment';
import Nur600week5assignment from './pages/Samples/Nur600week5assignment';
import Nurs600assignment1_2 from './pages/Samples/Nurs600assignment1_2';
import N550module5assignment from './pages/Samples/N550module5assignment';
import Nurs6373assignment2_1 from './pages/Samples/Nurs6373assignment2_1';
import Nursfpx8143week2assignment from './pages/Samples/Nursfpx8143week2assignment';
import N508module4assignment from './pages/Samples/N508module4assignment';
import Ha415m5Assessment from './pages/Samples/Ha415m5Assessment';
import Ha415m2assessment from './pages/Samples/Ha415m2assessment';
import Hs101m2assignment from './pages/Samples/Hs101m2assignment';
import Nurs601assignment1_1 from './pages/Samples/Nurs601assignment1_1';
import Nurs601assignment1_3 from './pages/Samples/Nurs601assignment1_3';
import Nurs601assignment3_1 from './pages/Samples/Nurs601assignment3_1';
import Nurs600assignment6_1 from './pages/Samples/Nurs600assignment6_1';
import Ha415m4assessment from './pages/Samples/Ha415m4assessment';
import Hs101m1assignment from './pages/Samples/Hs101m1assignment';
import Ha415m3assessment from './pages/Samples/Ha415m3assessment';
import Hs165m5assessment from './pages/Samples/Hs165m5assessment';
import Ef205m4assignment from './pages/Samples/Ef205m4assignment';
const Layout = ()=>{
    return(
        <>
        <Scrolltotop/>
        <Header />
        <Outlet />
        <Footer />
        </>
    )
}

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout/>,
      children: [
        {
            path: "/",
            element: <Home />
        },
        {
            path: "/samples",
            element: <Samples />
        },
        {
            path: "/post/:id",
            element: <Post />
        },
        {
            path: "/create",
            element: <Create />
        },
        {
          path: "/order",
          element : <Order/>
        },
        {
          path: "/terms-and-conditions",
          element: <Terms/>
        },
        {
          path: "/privacy-policy",
          element: <Privacypolicy/>
        },
        {
          path: "/RN-to-BSN",
          element: <Bsn/>
        },
        {
          path: "/MSN",
          element: <Msn/>
        },
        {
          path: "/DNP",
          element: <Dnp/>
        },
        {
          path: "/BS-in-Psychology",
          element: <Bspsy/>
        },
        {
          path: "/BS-in-Psychology-Pre-Counseling",
          element: <Bspsypre/>
        },
        {
          path: "/MS-in-Applied-Behavior-Analysis",
          element: <Msapplied/>
        },
        {
          path: "/MS-in-Clinical-Psychology",
          element: <Msclinical/>
        },
        {
          path: "/MS-in-Psychology",
          element: <Mspsy/>
        },
        {
          path: "/MS-in-School-Psychology",
          element: <Msschool/>
        },
        {
          path: "/EdS-in-School-Psychology",
          element: <Edsschool/>
        },
        {
          path: "/PhD-in-Behavior-Analysis",
          element: <Phdbehaviour/>
        },
        {
          path: "/PhD-in-Psychology",
          element: <Phdpsyc/>
        },
        {
          path: "/PsyD-in-Clinical-Psychology",
          element: <Psydclinical/>
        },
        {
          path: "/PsyD-in-School-Psychology",
          element: <Psydschool/>
        },
        {
          path: "/thank-you",
          element: <Thankyou/>
        },
        {
          path: "/capella-flexpath-rn-bsn-classes-help",
          element: <Service/>
        },
        {
          path: "/take-comprehensive-help-in-capella-flex-path-assessments",
          element: <CapellaFlexpath/>
        },
        {
          path: "/take-the-guidance-in-capella-msn-flexpath-courses",
          element: <MsnFlexpath/>
        },{
          path: "/seek-capella-university-dnp-flexpath-help",
          element: <Dnpflexpath/>
        },
        {
          path: "/capella-capstone-project-bsn-help",
          element: <CapellacapstoneBsn/>
        },
        {
          path: "/nursing-dissertation-writing-services",
          element: < Nursingdissertation />
        },
        {
          path: "/need-assistance-with-capella-flexpath-nursing",
          element: < CapellaFlexPathAssisstance />
        },
        {
          path: "/get-dnp-capstone-project-help",
          element: < DNPCapstoneProjectHelp />
        },
        {
          path: "/comprehensive-dnp-dissertation-help",
          element: < DNPDissertationHelp />
        },
        {
          path: "/capella-university-help",
          element: < CapellaUniversityHelp />
        },
        {
          path: "/reliable-nursing-homework-help-with-our-online-coursework-help",
          element: < ReliableNursingHomeworkHelp />
        },
         {
          path: "/boost-academic-performance-with-our-online-coursework-help",
          element: < BoostAcademicPerformance />
        },
        {
          path: "/pay-someone-to-take-an-online-class",
          element: < TakeMyOnlineClass />
        },
        {
          path: "/academic-assignment-help",
          element: < AcademicAssignmentHelp />
        },
        {
          path: "/assignment-writing-services",
          element: < AssignmentWritingServices />
        },
        {
          path: "/need-help-with-nursing-assignment",
          element: < NursingAssignment />
        },
        {
          path: "/nurs-fpx-4060-assessment-3-disaster-recovery-plan",
          element: < NURSFPX4060 />
        },
        {
          path: "/cheap-assignment-writing-help-services",
          element: < CheapAssignmentWriting />
        },
        {
          path: "/do-my-assignment-online",
          element: < DoMyAssignment />
        },
        {
          path: "/take-my-online-nursing-class",
          element: < OnlineNursingClass />
        },
        {
          path: "/hire-someone-to-do-your-online-class",
          element: < Hiresomeonetdoyourclass />
        },
        {
          path: "/pay-someone-to-do-my-class",
          element: < DoMyClass />
        },
        {
          path: "/get-best-online-class-help",
          element: < OnlineClassHelp />
        },
        {
          path: "/take-my-class-online-for-me",
          element: < TakeMyClassOnline />
        },
        {
          path: "/pay-someone-to-take-my-class",
          element: < Paysomeonetotakemyclass />
        },
        {
          path: "/searching-for-a-person-who-can-take-my-online-class-for-me",
          element: < Searchingforaperson />
        },
        {
          path: "/take-my-online-course-for-me",
          element: < TakeMyOnlineCourse />
        },
        {
          path: "/unlocking-academic-success-the-role-of-online-class-takers",
          element: < Unlockingacademicsuccess />
        },
        {
          path: "/pay-to-take-my-online-class",
          element: < Paytotakemyonlineclass />
        },
        {
          path: "/pay-someone-to-take-online-classes",
          element: < Paysomeonetotakeonlineclasses />
        },
        {
          path: "/do-my-class-for-me",
          element: < Domyclassforme />
        },
        {
          path: "/mba-fpx-5002-mba-leadership",
          element: < Mbafpx5002mbaleadership />
        },
        {
          path: "/mba-fpx-5006-business-strategy",
          element: < Mbafpx5006businessstrategy />
        },
        {
          path: "/mba-fpx-5008-applied-business-analytics",
          element: < Mbafpx5008appliedbusinessanalytics />
        },
        {
          path: "/mba-fpx-5010-accounting-methods-for-leaders",
          element: < Mba5010accountingmethodsforleaders />
        },
        {
          path: "/mba-fpx-5012-marketing-management",
          element: < Mbafpx5012marketingmanagement />
        },
        {
          path: "/mba-fpx-5014-applied-managerial-finance",
          element: < Mbafpx5014appliedmanagerialfinance />
        },
        {
          path: "/mba-fpx-5016-operations-management-for-leaders",
          element: < Mbafpx5016operationsmanagementforleaders />
        },
        {
          path: "/mha-fpx-5001-foundations-of-master’s-studies-in-healthcare-administration",
          element: < Mhafpx5001 />
        },
        {
          path: "/mha-fpx-5006-healthcare-finance-and-reimbursement",
          element: < Mhafpx5006 />
        },
        {
          path: "/mha-fpx-5010-strategic-healthcare-planning",
          element: < Mhafpx5010 />
        },
        {
          path: "/mha-fpx-5012-organizational-leadership-and-governance",
          element: < Mhafpx5012 />
        },
        {
          path: "/mha-fpx-5014-healthcare-quality-risk-and-regulatory-compliance",
          element: < Mhafpx5014 />
        },
        {
          path: "/nurs-fpx",
          element: < Nursfpx />
        },
        {
          path: "/nurs-fpx-4060-assessment-4",
          element: < Nursfpx4060assessment4 />
        },
        {
          path: "/nurs-fpx-4050-assessment-2",
          element: < Nursfpx4050assessment2 />
        },
        {
          path: "/nurs-fpx-4060-assessment-2",
          element: < Nursfpx4060assessment2 />
        },
        {
          path: "/nurs-fpx-4020-assessment-4",
          element: < Nursfpx4020assessment4 />
        },
        {
          path: "/nurs-fpx-4000-assessment-2",
          element: < Nursfpx4000assessment2 />
        },
        {
          path: "/nurs-fpx-4030-assessment-1",
          element: < Nursfpx4030assessment1 />
        },
        {
          path: "/nurs-fpx-4030-assessment-2",
          element: < Nursfpx4030assessment2 />
        },
        {
          path: "/nurs-fpx-4000-assessment-1-pledge-of-academic-honesty",
          element: < Nursfpx4000assessment1 />
        },
        {
          path: "/mha-fpx-5006-assessment-1",
          element: < Mhafpx5006A1 />
        },
        {
          path: "/mha-fpx-5006-assessment-2",
          element: < Mhafpx5006A2 />
        },
        {
          path: "/mha-fpx-5006-assessment-3",
          element: < Mhafpx5006A3 />
        },
        {
          path: "/mha-fpx-5006-assessment-4",
          element: < Mhafpx5006A4 />
        },
        {
          path: "/nurs-fpx-8030-assessment-2-evidenced-based-literature",
          element: < Nursfpx8030a2 />
        },
        {
          path: "/nurs-fpx-6016-assessment-1",
          element: < Nursfpx6016a1 />
        },
        {
          path: "/nurs-fpx-5004-assessment-2",
          element: < Nursfpx5004a2 />
        },
        {
          path: "/nurs-fpx-5004-assessment-3",
          element: < Nursfpx5004a3 />
        },
        {
          path: "/mba-fpx-5006-assessment-3",
          element: < Mbafpx5006A5 />
        },
        {
          path: "/nurs-fpx-4010-assessment-1-collaboration-and-leadership-reflection-video",
          element: < Nursfpx4010assessment1 />
        },
        {
          path: "/nurs-fpx-4060-assessment-3",
          element: < Nursfpx4060assessment3 />
        },
        {
          path: "/nurs-fpx-4010-assessment-2-interview-and-interdisciplinary-issue-identification",
          element: < Nursfpx4010assessment2 />
        },
        {
          path: "/nurs-fpx-8010-assessment-1",
          element: < Nursfpx8010assessment1 />
        },
        {
          path: "/nurs-fpx-4040-assessment-1",
          element: < Nursfpx4040assessment1 />
        },
        {
          path: "/nurs-fpx-4030-assessment-4",
          element: < Nursfpx4030assessment4 />
        },
        {
          path: "/nurs-fpx-4040-assessment-3-evidence-based-proposal-and-annotated-bibliography-on-technology-in-nursing",
          element: < Nursfpx4040assessment3 />
        },
        {
          path: "/nurs-fpx-4060-assessment-1-health-promotion-plan",
          element: < Nursfpx4060assessment1 />
        },
        {
          path: "/nurs-fpx-4900-assessment-5-intervention-presentation-and-capstone-video-reflection",
          element: < Nursfpx4900assessment5 />
        },
        {
          path: "/nurs-fpx-4900-assessment-6-acknowledging-the-completion-and-documentation-of-nine-practicum-hours",
          element: < Nursfpx4900assessment6 />
        },
        {
          path: "/nurs-fpx-6004-assessment-2-policy-proposal",
          element: < Nursfpx6004assessment2 />
        },
        {
          path: "/nurs-fpx-6016-assessment-2-quality-improvement-initiative-evaluationl",
          element: < Nursfpx6004assessment2 />
        },
        {
          path: "/nurs-fpx-4050-assessment-3-care-coordination-presentation-to-colleagues",
          element: < Nursfpx4050assessment3 />
        },
        {
          path: "/mba-fpx-5005-assessment-1-protecting-human-research-participants",
          element: < Mbafpx5005assessment1 />
        },
        {
          path: "/mba-fpx-5006-assessment-1-health-care-finance-overview",
          element: < Mbafpx5006assessment1 />
        },
        {
          path: "/hypo-fpx-5004-assessment-4-self-assessment-of-leadership-collaboration-and-ethics",
          element: < Hypofpx5004assessment4 />
        },
        {
          path: "/bha-fpx-4140-assessment-1-strategic-planning-and-leadership",
          element: < Bhafpx4104assessment1 />
        },
        {
          path: "/nurs-fpx-6004-assessment-2-policy-proposal",
          element: < Nursfpx6004assessment2policyproposal />
        },
        {
          path: "/nurs-fpx-6111-assessment-1-course-definition-and-alignment-table",
          element: < Nursfpx6111assessment1 />
        },
        {
          path: "/nurs-fpx-6004-assessment-1-dashboard-metrics-evaluation",
          element: < Nursfpx6004assessment1 />
        },
        {
          path: "/nurs-fpx-6011-assessment-2-evidence-based-population-health-improvement-plan",
          element: < Nursfpx6011assessment2 />
        },
        {
          path: "/nurs-fpx-6216-assessment-2-preparing-and-managing-an-operating-budget",
          element: < Nursfpx6216assessment2 />
        },
        {
          path: "/nurs-fpx-6004-assessment-3-training-session-for-policy-implementation",
          element: < Nursfpx6004assessment3/>
        },
        {
          path: "/nurs-fpx-6412-assessment-2-presentation-to-the-organization",
          element: < Nursfpx6412assessment2 />
        },
        {
          path: "/nurs-fpx-6025-assessment-2-practicum-and-experience-reflection",
          element: < Nursfpx6025assessment2 />
        },
        {
          path: "/nurs-fpx-6025-assessment-3-practicum-and-scholarly-article",
          element: < Nursfpx6025assessment3 />
        },
        {
          path: "/bha-fpx-4020-assessment-2-health-care-professional-feedback",
          element: < Bhafpx4020assessment2/>
        },
        {
          path: "/bha-fpx-4112-assessment-2-the-supply-and-demand-of-health-care",
          element: < Bhafpx4112assessment2/>
        },
        {
          path: "/nhs-fpx-4000-assessment-3-applying-ethical-principals",
          element: < Nhsfpx4000assessment3/>
        },
        {
          path: "/psyc-fpx-3500-assessment-1-popular-versus-scholarly-sources-worksheet",
          element: < Psycfpx3500assessment1/>
        },
        {
          path: "/nurs-4900-capstone-project-for-nursing",
          element: < Nurs4900capstoneprojectfornursing/>
        },
        {
          path: "/psyc-fpx-3210-assessment-3-pediatric-nurse",
          element: < Psycfpx3210assessment2/>
        },
        {
          path: "/nu-631-unit-14-dq-peer-response-for-discussion-post-1",
          element: < Nu631unit14dq/>
        },
        {
          path: "/dba-fpx-8420-assessment-1-course-welcome-message",
          element: < Dbafpx8420assessment1/>
        },
        {
          path: "/dba-fpx-8410-assessment-1-analyze-a-set-of-worker-complaints",
          element: < Dbafpx8410assessment1/>
        },
        {
          path: "/dnp-825-topic-3-assignment-population-health-part-i",
          element: < Dnp825topic3/>
        },
        {
          path: "/nu-631-unit-14-dq-peer-response-for-discussion-post-2",
          element: < Nu631unit14dq2/>
        },
        {
          path: "/cs-212-m5-assignment-communicating-in-teams",
          element: < Cs212m5assignment/>
        },
        {
          path: "/hlt-326-topic-1-assignment-application-of-statistics-in-healthcare",
          element: < Hlt326topic1assignment/>
        },
        {
          path: "/dnp-830-topic-1-assignment-examination-methods-and-designs",
          element: < Dnp830topic1assignment/>
        },
        {
          path: "dnp-820-topic-5-assignment-implementation-into-practice",
          element: < Dnp820topic5assignment/>
        },
        {
          path: "ef-205-m3-assessment-of-cardiovascular-fitness",
          element: < Ef205m3assessment/>
        },
        {
          path: "mt140-m2-assignment-four-functions-of-management",
          element: < Mt140m2assignment/>
        },
        {
          path: "mt140-m1-assignment-1-competency-assessment",
          element: < Mt140m1assignment1/>
        },
        {
          path: "ha-255-m1-assignment-foundations-of-human-resources",
          element: < Ha255m1assignment/>
        },
        {
          path: "cs-212-m5-assignment-teams-communication",
          element: < Cs212m5assignmentcommunicatinginteams/>
        },
        {
          path: "mt-140-m5-assignment-ethics-in-management",
          element: < Mt140m5assignment/>
        },
        {
          path: "mt140-m1-competency-assessment-solving-management-problems",
          element: < Mt140m1competencyassessmentsolving/>
        },
        {
          path: "ss2901-m1-assignment-competency-assessment",
          element: < Ss2901m1assignment/>
        },
        {
          path: "ha-405-m5-assessment-identify-leadership",
          element: < Ha405m5assessment/>
        },
        {
          path: "ha-405-m6-assignment-leadership-style",
          element: < Ha405m6assignment/>
        },
        {
          path: "hs230-m5-assignment-us-healthcare-delivery-system",
          element: < Hs230m5assignment/>
        },
        {
          path: "hs230-m1-assignment-leading-healthcare-organizations",
          element: < Hs230m1assignment/>
        },
        {
          path: "n547-week-7-assessment-healthcare-strategic-management",
          element: < N547week7assessment/>
        },
        {
          path: "nurs-fpx-6210-assignment-1-environmental-analysis",
          element: < Nursfpx6210assignment1/>
        },
        {
          path: "nurs-fpx-6212-assignment-4-planning-for-change",
          element: < Nursfpx6212assignment4/>
        },
        {
          path: "nurs-fpx-6008-assignment-2-needs-analysis-for-change",
          element: < Nursfpx6008assignment2/>
        },
        {
          path: "n547-week-6-assignment-healthcare-strategic-management",
          element: < N547week6assignment/>
        },
        {
          path: "n547-week-4-assessment-directional-strategies-alternatives",
          element: < N547week4assessment/>
        },
        {
          path: "cs212-m4-assignment-thinking-critically-and-ethically",
          element: < Cs212m4assignment/>
        },
        {
          path: "capella-flexpath-rn-to-bsn-program-assistance",
          element: < Capellaflexpathrntobsn/>
        },
        {
          path: "capella-university-flexpath-nursing-program-assistance",
          element: < Capellauniversityflexpathnursing/>
        },
        {
          path: "ef-205-m1-assignment-promoting-health",
          element: < Ef205m1assignment/>
        },
        {
          path: "mt140-m4-assignment-global-management-skills",
          element: < Mt140m4assignment/>
        },
        {
          path: "n599-week-4-assignment-education-program-for-patients",
          element: < N599week4assignment/>
        },
        {
          path: "n550-week-5-assignment-diabetes-self-management-program",
          element: < N550week5assignment/>
        },
        {
          path: "n550-week-4-assignment-1-self-management-education-program",
          element: < N550week4assignment/>
        },
        {
          path: "mba-fpx-5012-assessment-1-marketing-plan",
          element: < Mbafpx5012assessment1/>
        },
        {
          path: "soc-fpx-2000-assessment-3-social-activism",
          element: < Socfpx2000assessment3s/>
        },
        {
          path: "/bha-fpx-4102-assessment-4-conflict-resolution",
          element: < Bhafpx4102assessment4/>
        },
        {
          path: "/ef-205-m3-assessment-of-cardiovascular-fitness",
          element: < Ef205m3assessmente/>
        },
        {
          path: "/mt140-m3-assignment-change-management",
          element: < Mt140m3assignment/>
        },
        {
          path: "/hu200-m5-the-transformative-influence-of-television",
          element: < Hu200m5assignment/>
        },
        {
          path: "/hs410-m6-assessment-project-plan",
          element: < Hs410m6assessment/>
        },
        {
          path: "/nurs-fpx-6021-assessment-2-change-strategy",
          element: < Nursfpx6021assessment2/>
        },
        {
          path: "/nurs-fpx-6030-assessment-2-problem-statement",
          element: < Nursfpx6030assessment2/>
        },
        {
          path: "/nurs-fpx-6616-assessment-1-community-resources",
          element: < Nursfpx6616assessment1/>
        },
        {
          path: "/psyc-fpx-3210-assessment-3-case-study",
          element: < Psycfpx3210assessment3/>
        },
        {
          path: "/n508-week-8-assignment-1-theory-and-research",
          element: < N508week8assignment1/>
        },
        {
          path: "/n508-week-6-assignment-theory-and-research",
          element: < N508week6assignment/>
        },
        {
          path: "/hs-450-m2-effective-team-building",
          element: < Hs450m2effectiveteambuilding/>
        },
        {
          path: "/mba-fpx-5910-assessment-2-capstone-proposal",
          element: < Mbafpx5910assessment2/>
        },
        {
          path: "/hs-165-m4-assignment-understanding-asthma",
          element: < Hs165m4assignment/>
        },
        {
          path: "/hs-111-m1-assessment-components-of-a-medical-term",
          element: < Hs111m1assessment/>
        },
        {
          path: "/ha-415-m1-assessment-healthcare-economic-basics",
          element: < Ha415m1assessment/>
        },
        {
          path: "/ha-610-unit-5-assignment-education-attainment-policies",
          element: < Ha610unit5assignment/>
        },
        {
          path: "/ha-610-unit-6-assignment-strengthening-political-advocacy",
          element: < Ha610unit6assignment/>
        },
        {
          path: "/n599-module-2-assignment-education-for-diagnosed-patients",
          element: < N599module2assignment/>
        },
        {
          path: "/n537-module-8-assignment",
          element: < N537module8assignment/>
        },
        {
          path: "/n599-assignment-nursing-capstone",
          element: < N599module2assignmentn/>
        },
        {
          path: "/bus-fpx-3040-assessment-3-retention-and-separation",
          element: < Busfpx3040assessment3/>
        },
        {
          path: "/n586-module-1-assignment-3-individualized-learning-object",
          element: < N586module1assignment3/>
        },
        {
          path: "/mha-fpx-5020-assessment-1-project-topic-selection",
          element: < Mhafpx5020assessment1/>
        },
        {
          path: "/mha-fpx-5016-assessment-4-health-information",
          element: < Mhafpx5016assessment4/>
        },
        {
          path: "/phi-fpx-3200-assessment-2-a-right-to-experimental-drugs",
          element: < Phifpx3200assessment2/>
        },
        {
          path: "/nurs-fpx-6218-assessment-3-planning-for-community",
          element: < Nursfpx6218assessment3/>
        },
        {
          path: "/nurs-fpx-9904-assessment-1-cbt-sessions",
          element: < Nursfpx9904assessment1/>
        },
        {
          path: "/nurs-fpx-8040-assessment-3-planned-intervention",
          element: < Nursfpx8040assessment3/>
        },
        {
          path: "/nurs-fpx-5004-assessment-1-self-assessment-of-leadership",
          element: < Nursfpx5004assessment1/>
        },
        {
          path: "/hs-101-m1-assignment-standards-in-healthcare",
          element: < Hs101m1assignmenth/>
        },
        {
          path: "/nurs-fpx-8012-assessment-1-technology-informatics",
          element: < Nursfpx8012assessment1/>
        },
        {
          path: "/dnp-830-module-assignment-vulnerable-populations",
          element: < Dnp830moduleassignment/>
        },
        {
          path: "/dnp-830-module-assignment-global-population-health",
          element: < Dnp830moduleassignmentg/>
        },
        {
          path: "/dnp-830-module-7-assignment",
          element: < Dnp830module7assignment/>
        },
        {
          path: "/dnp-830-module-8-assignment-reflection",
          element: < Dnp830module8assignment/>
        },
        {
          path: "/dnp-830-module-3-assignment-methodology",
          element: < DNP830Module3Assignment/>
        },
        {
          path: "/nurs-fpx-8040-assessment-1-project-charter-part-1",
          element: < Nursfpx8040assessment1/>
        },
        {
          path: "/nurs-fpx-9904-assessment-2-project-summary",
          element: < Nursfpx9904assessment2/>
        },
        {
          path: "/nurs-fpx-9904-assessment-3-doctoral-project",
          element: < Nursfpx9904assessment3/>
        },
        {
          path: "/nurs-fpx-9904-assessment-4-handoff-procedures",
          element: < Nursfpx9904assessment4/>
        },
        {
          path: "/nurs-fpx-9904-assessment-5-doctoral-project-presentation",
          element: < Nursfpx9904assessment5/>
        },
        {
          path: "/nurs-fpx-9904-assessment-6-reflection",
          element: < Nursfpx9904assessment/>
        },
        {
          path: "/psyc-fpx-2200-assessment-2-educational-psychology",
          element: < Psycfpx2200assessment2/>
        },
        {
          path: "/psyc-fpx-2700-assessment-1-case-study-analysis",
          element: < Psycfpx2700assessment1/>
        },
        {
          path: "/mha-fpx-5020-assessment-5-health-care-leadership",
          element: < Mhafpx5020assessment5/>
        },
        {
          path: "/nurs-8123-assignment-2.1-feasibility-report",
          element: < Nurs8123assignment2/>
        },
        {
          path: "/mha-fpx-5020-assessment-4-data-project-and-presentation",
          element: < Mhafpx5020assessment4/>
        },
        {
          path: "/mha-fpx-5020-assessment-3-client-meeting-project-approval",
          element: < Mhafpx5020assessment3/>
        },
        {
          path: "/mha-fpx-5020-assessment-2-data-review-project-proposal",
          element: < Mhafpx5020assessment2/>
        },
        {
          path: "/mha-fpx-5016-assessment-2-information-system-job-Summary",
          element: < Mhafpx5016assessment2/>
        },
        {
          path: "/mha-fpx-5016-assessment-3-stakeholder-information-systems",
          element: < Mhafpx5016assessment3/>
        },
        {
          path: "/nurs-8123-assignment-7.1-theoretical-framework",
          element: < Nurs8123assignment7/>
        },
        {
          path: "/nurs-8123-week-20-assessment-feasibility-report",
          element: < Nurs8123week2assessment/>
        },
        {
          path: "/nurs-8123-assignment-5.1-retention-in-community-college-down",
          element: < Nurs8123assignment5/>
        },
        {
          path: "/dnp-845-module-6-assignment-implementing-evidence-practice",
          element: < Dnp845module6assignment/>
        },
        {
          path: "/nurs-8123-assignment-6.1-literature-matrix",
          element: < Nurs8123assignment6/>
        },
        {
          path: "/dnp-845-week-8-assignment-1-implementing-evidence-practice",
          element: < Dnp845week8assignment1/>
        },
        {
          path: "/aspen-university-assignment-help-services",
          element: < Universitypage/>
        },
        {
          path: "/mha-fpx-5012-assessment-2-human-resource-management",
          element: < Mhafpx5012assessment2/>
        },
        {
          path: "/nurs-fpx-9100-assessment-1-topic-approval-school-review",
          element: < Nursfpx9100assessment1/>
        },
        {
          path: "/nurs-fpx-9100-assessment-4-virtual-check-in-1",
          element: < Nursfpx9100assessment4/>
        },
        {
          path: "/dnp-845-week-7-assignment-evaluating-evidence",
          element: < Dnp845week7assignment/>
        },
        {
          path: "/dnp-850-module-3-assignment-policy-and-ethics",
          element: < Dnp850module3assignment/>
        },
        {
          path: "/dnp-850-module-4-assignment-prepared-nursing-educator",
          element: < Dnp850module4assignment/>
        },
        {
          path: "/dnp-850-module-8-assignment-advanced-nursing-leadership",
          element: < Dnp850module8assignment/>
        },
        {
          path: "/dnp-850-module-5-assignment-healthcare-promotion",
          element: < Dnp850module5assignment/>
        },
        {
          path: "/dnp-855-module-1-assignment-1.1-teleconference-summary",
          element: < Dnp855module1assignment1/>
        },
        {
          path: "/dnp-855-module-2-assignment-organizational-change-essay",
          element: < Dnp855module2assignment/>
        },
        {
          path: "/dnp-830-module-4-assignment-global-population-health",
          element: < Dnp830module4assignment/>
        },
        {
          path: "/dnp-module-4-assignment-1-teleconference-summary",
          element: < Dnpmodule4assignment1/>
        },
        {
          path: "/mba-fpx-5010-assessment-3-performance-evaluation",
          element: < Mbafpx5010assessment3/>
        },
        {
          path: "/nursing-547-assignment-2-service-area-competitor-analysis",
          element: < N547Assignment2/>
        },
        {
          path: "/bha-fpx-4106-assessment-1-information-collection",
          element: < Bhafpx4106assessment1/>
        },
        {
          path: "/mba-fpx-5002-assessment-3-storytelling",
          element: < Mbafpx5002assessment3/>
        },
        {
          path: "/hrm-fpx-5310-assessment-1-recruiting-plan-strategy-report",
          element: < Hrmfpx5310assessment1/>
        },
        {
          path: "/hrm-fpx-5310-assessment-3-total-rewards-package-strategy",
          element: < Hrmfpx5310assessment3/>
        },
        {
          path: "/bio-fpx-1000-assessment-2-neuromuscular-lab",
          element: < Biofpx1000assessment2/>
        },
        {
          path: "/nursing-547-module-6-assignment-Strategy",
          element: < Nursing547module6assignment/>
        },
        {
          path: "/nursing-550-assignment-8.3-interview-and-summative-paper",
          element: < Nursing550assignment8/>
        },
        {
          path: "/N599-module-2-assignment-nursing-capstone",
          element: < N599module2assignmentnc/>
        },
        {
          path: "/n547-week-2-assignment-healthcare-strategic",
          element: < N547week2assignment/>
        },
        {
          path: "/n550-module-1-assignment-1.2-initial-teleconference",
          element: < N550module1assignment/>
        },
        {
          path: "/hum-fpx-1150-assessment-4-creative-process",
          element: < Humfpx1150assessment4/>
        },
        {
          path: "/nurs-fpx-6216-assessment-1-mentor-interview",
          element: < Nursfpx6216assessment1/>
        },
        {
          path: "/nurs-fpx-6212-assessment-2-executive-summary",
          element: < Nursfpx6212assessment2/>
        },
        {
          path: "/N538-Week-1-Assignment-Nursing-Informatics",
          element: < N538week1assignment/>
        },
        {
          path: "/n550-module-1-assignment-1.2-initial-teleconference",
          element: < N550module1assignment1/>
        },
        {
          path: "/n550-module-1-assignment-1.3-nursing-administration-practicum-i",
          element: < N550module1assignment1p/>
        },
        {
          path: "/n550-module-8-assignment-interview",
          element: < N550module8assignment/>
        },
        {
          path: "/n508-week-5-assignment-implementation-of-research",
          element: < N508week5assignment/>
        },
        {
          path: "/n550-week-1-assignment-2-comprehensive-diabetes",
          element: < N550week1assignment2/>
        },
        {
          path: "/n586-module-3-assignment-annotated-bibliography",
          element: < N586module3assignment/>
        },
        {
          path: "/mha-fpx-5012-assessment-4-leadership-communication-strategy",
          element: < Mhafpx5012assessment4/>
        },
        {
          path: "/nurs-fpx-8030-assessment-4-methods-and-measurement",
          element: < Nursfpx8030assessment4/>
        },
        {
          path: "/bha-fpx-4110-assessment-1-vila-health-patient-flow",
          element: < Bhafpx4110assessment1/>
        },
        {
          path: "/nurs-fpx-8002-assessment-2-demonstrating-effective-leadership",
          element: < Nursfpx8002assessment2/>
        },
        {
          path: "/n600-assignment-2.1-theory-project",
          element: < N600assignment2/>
        },
        {
          path: "/n600-assignment-3.1-frankl-book-critique",
          element: < N600assignment3/>
        },
        {
          path: "/bha-fpx-4010-assessment-1-research-problem",
          element: < Bhafpx4010assessment1/>
        },
        {
          path: "/n600-assignment-3.2-theory-project-submit-content-outline",
          element: < N600assignment3a/>
        },
        {
          path: "/n600-assignment-3.3-connecting-developmental-theory",
          element: < N600assignment3b/>
        },
        {
          path: "/n600-assignment-4.1-virtual-field-trip",
          element: < N600assignment4/>
        },
        {
          path: "/n600-assignment-5.3-theory-project-artifact",
          element: < N600assignment5/>
        },
        {
          path: "/n600-assignment-7.1-outside-theorist/theory-outside-evaluation",
          element: < N600assignment7/>
        },
        {
          path: "/n600-assignment-7.2-theory-project",
          element: < N600assignment7a/>
        },
        {
          path: "/n599-module-5-assignment-nursing-capstone",
          element: < N599Module5Assignment/>
        },
        {
          path: "/n599-module-6-assignment-chapter-4",
          element: < N599Module6Assignment/>
        },
        {
          path: "/n600-assignment-8.1-theory-project-evaluation",
          element: < N600assignment8/>
        },
        {
          path: "/n601-assignment-3.1-critique-a-clinical-practice-guideline",
          element: < N601assignment3/>
        },
        {
          path: "/dnp-815-week-2-assignment-connecting-nursing-theory",
          element: < Dnp815week2assignment/>
        },
        {
          path: "/bha-fpx-4106-assessment-3-health-care-information",
          element: < Bhafpx4106assessment3/>
        },
        {
          path: "/nurs-fpx-9901-assessment-3-doctoral-project",
          element: < Nursfpx9901assessment3/>
        },
        {
          path: "/nurs-fpx-6612-assessment-1-triple-aim-outcome",
          element: < Nursfpx6612assessment1/>
        },
        {
          path: "/nurs-fpx-4005-assessment-1-collaboration-and-leadership",
          element: < Nursfpx4005assessment1/>
        },
        {
          path: "/bio-fpx-1000-assessment-2-neuromuscular-lab",
          element: < Biofpx1000assessment2b/>
        },
        {
          path: "/nurs-fpx-6025-assessment-6-practicum",
          element: < Nursfpx6025assessment6/>
        },
        {
          path: "/bha-fpx-4104-assessment-2-strategic-analysis",
          element: < Bhafpx4104assessment2/>
        },
        {
          path: "/nurs-fpx-6216-assessment-3-budget",
          element: < Nursfpx6216assessment3/>
        },
        {
          path: "/nurs-fpx-5006-assessment-1-strategic-process",
          element: < Nursfpx5006assessment1/>
        },
        {
          path: "/bha-fpx-4110-assessment-2-vila-health",
          element: < Bhafpx4110assessment2/>
        },
        {
          path: "/bha-fpx-4106-assessment-2-benchmarks",
          element: < Bhafpx4106assessment2/>
        },
        {
          path: "/nurs-fpx-8045-assessment-7-conceptualizing",
          element: < Nursfpx8045assessment7/>
        },
        {
          path: "/nurs-fpx-8045-assessment-5-evidence-for-obesity",
          element: < Nursfpx8045assessment5/>
        },
        {
          path: "/nurs-fpx-8045-assessment-3-differentiate-a-qi-pi-project",
          element: < Nursfpx8045assessment3/>
        },
        {
          path: "/bha-fpx-4110-assessment-3-leadership-in-the-dynamic-health-care-industry",
          element: < Bhafpx4110assessment3/>
        },
        {
          path: "/nurs-fpx-8030-assessment-2-evidenced-based",
          element: < Nursfpx8030assessment2/>
        },
        {
          path: "/nurs-fpx-8014-assessment-1-nongovernmental-agencies",
          element: < Nursfpx8014assessment1/>
        },
        {
          path: "/nurs-fpx-8010-assessment-2-strategic-plan",
          element: < Nursfpx8010assessment2/>
        },
        {
          path: "/mba-fpx-5002-assessment-2-defining-yourself-as-a-leader",
          element: < Mbafpx5002assessment2/>
        },
        {
          path: "/bha-fpx-4104-assessment-4-human-resources-strategy",
          element: < Bhafpx4104assessment4/>
        },
        {
          path: "/bha-fpx-4104-assessment-3-organizational-communication",
          element: < Bhafpx4104assessment3/>
        },
        {
          path: "/mba-fpx-5002-assessment-1-critical-thinking-in-action",
          element: < Mbafpx5002assessment1/>
        },
        {
          path: "/bha-fpx-4008-assessment-2-financial-statement",
          element: < Bhafpx4008assessment2/>
        },
        {
          path: "/bha-fpx-4010-assessment-4-develop-a-research-plan",
          element: < Bhafpx4010assessment4/>
        },
        {
          path: "/mba-fpx-5007-assessment-1-leadership-styles",
          element: < Mbafpx5007assessment1/>
        },
        {
          path: "/bha-fpx-4010-assessment-2-external-forces",
          element: < Bhafpx4010assessment2/>
        },
        {
          path: "/nurs-fpx-9902-assessment-3-literature-synthesis",
          element: < Nursfpx9902assessment3/>
        },
        {
          path: "/mba-fpx-5007-assessment-3-intervention-strategy",
          element: < Mbafpx5007assessment3/>
        },
        {
          path: "/nurs-fpx-8030-assessment-3-critical-appraisal",
          element: < Nursfpx8030assessment3/>
        },
        {
          path: "/rsch-fpx-7864-assessment-4-anova-application",
          element: < Rschfpx7864assessment4/>
        },
        {
          path: "/nurs-fpx-8010-assessment-4-quality-improvement",
          element: < Nursfpx8010assessment4/>
        },
        {
          path: "/nurs-fpx-8010-assessment-3-strategic-plan-development",
          element: < Nursfpx8010assessment3/>
        },
        {
          path: "/rsch-fpx-7864-assessment-3-test-application",
          element: < Rschfpx7864assessment3/>
        },
        {
          path: "/bha-fpx-4020-assessment-1-health-care-problem",
          element: < Bhafpx4020assessment1/>
        },
        {
          path: "/bha-fpx-4020-assessment-3-data-collection-and-analysis",
          element: < Bhafpx4020assessment3/>
        },
        {
          path: "/nurs-fpx-4005-assessment-2",
          element: < Nursfpx4005assessment2/>
        },
        {
          path: "/nurs-fpx-4005-assessment-3",
          element: < Nursfpx4005assessment3/>
        },
        {
          path: "/nurs-fpx-4005-assessment-4",
          element: < Nursfpx4005assessment4/>
        },
        {
          path: "/nurs-fpx-4015-assessment-1",
          element: < Nursfpx4015assessment1/>
        },
        {
          path: "/nurs-fpx-4015-assessment-2",
          element: < Nursfpx4015assessment2/>
        },
        {
          path: "/nurs-fpx-4015-assessment-3",
          element: < Nursfpx4015assessment3/>
        },
        {
          path: "/nurs-fpx-4015-assessment-4",
          element: < Nursfpx4015assessment4/>
        },
        {
          path: "/nurs-fpx-4025-assessment-1",
          element: < Nursfpx4025assessment1/>
        },
        {
          path: "/nurs-fpx-4025-assessment-2",
          element: < Nursfpx4025assessment2/>
        },
        {
          path: "/nurs-fpx-4025-assessment-3",
          element: < Nursfpx4025assessment3/>
        },
        {
          path: "/nurs-fpx-4025-assessment-4",
          element: < Nursfpx4025assessment4/>
        },
        {
          path: "/nurs-fpx-4035-assessment-1",
          element: < Nursfpx4035assessment1/>
        },
        {
          path: "/nurs-fpx-4035-assessment-2",
          element: < Nursfpx4035assessment2/>
        },
        {
          path: "/nurs-fpx-4035-assessment-3",
          element: < Nursfpx4035assessment3/>
        },
        {
          path: "/nurs-fpx-4035-assessment-4",
          element: < Nursfpx4035assessment4/>
        },
        {
          path: "/nurs-fpx-4045-assessment-1",
          element: < Nursfpx4045assessment1/>
        },
        {
          path: "/nurs-fpx-4045-assessment-2",
          element: < Nursfpx4045assessment2/>
        },
        {
          path: "/nurs-fpx-4045-assessment-3",
          element: < Nursfpx4045assessment3/>
        },
        {
          path: "/nurs-fpx-4045-assessment-4",
          element: < Nursfpx4045assessment4/>
        },
        {
          path: "/nurs-fpx-4055-assessment-1",
          element: < Nursfpx4055assessment1/>
        },
        {
          path: "/nurs-fpx-4055-assessment-2",
          element: < Nursfpx4055assessment2/>
        },
        {
          path: "/nurs-fpx-4055-assessment-3",
          element: < Nursfpx4055assessment3/>
        },
        {
          path: "/nurs-fpx-4055-assessment-4",
          element: < Nursfpx4055assessment4/>
        },
        {
          path: "/nurs-fpx-4065-assessment-1",
          element: < Nursfpx4065assessment1/>
        },
        {
          path: "/nurs-fpx-4065-assessment-2",
          element: < Nursfpx4065assessment2/>
        },
        {
          path: "/nurs-fpx-4065-assessment-3",
          element: < Nursfpx4065assessment3/>
        },
        {
          path: "/nurs-fpx-4065-assessment-4",
          element: < Nursfpx4065assessment4/>
        },
        {
          path: "/nurs-fpx-4905-assessment-1",
          element: < Nursfpx4905assessment1/>
        },
        {
          path: "/nurs-fpx-4905-assessment-2",
          element: < Nursfpx4905assessment2/>
        },
        {
          path: "/nurs-fpx-4905-assessment-3",
          element: < Nursfpx4905assessment3/>
        },
        {
          path: "/nurs-fpx-4905-assessment-4",
          element: < Nursfpx4905assessment4/>
        },
        {
          path: "/bha-fpx-4102-assessment-2-emotional-Intelligence",
          element: < Bhafpx4102assessment2/>
        },
        {
          path: "/bha-fpx-4102-assessment-3-cultural-competence",
          element: < Bhafpx4102assessment3/>
        },
        {
          path: "/bha-fpx-4108-assessment-1-analyze-community-health",
          element: < Bhafpx4108assessment1/>
        },
        {
          path: "/bha-fpx-4108-assessment-3-strategies-recommendations",
          element: < Bhafpx4108assessment3/>
        },
        {
          path: "/bha-fpx-4108-assessment-4-community-health-action-plan",
          element: < Bhafpx4108assessment4/>
        },
        {
          path: "/mba-fpx-5007-assessment-2-managing-toxic-leader",
          element: < Mbafpx5007assessment2/>
        },
        {
          path: "/mba-fpx-5012-assessment-3-digital-marketing",
          element: < Mbafpx5012assessment3/>
        },
        {
          path: "/mba-fpx-5010-assessment-2-product-pricing",
          element: < Mbafpx5010assessment2/>
        },
        {
          path: "/rsch-fpx-7864-assessment-1-descriptive-statistics",
          element: < Rschfpx7864assessment1/>
        },
        {
          path: "/bha-fpx-4102-assessment-1-personal-leadership",
          element: < Bhafpx4102assessment1/>
        },
        {
          path: "/bha-fpx-4009-assessment-1-reimbursement-models",
          element: < Bhafpx4009assessment1/>
        },
        {
          path: "/bha-fpx-4004-assessment-1-address-a-patient-safety",
          element: < Bhafpx4004assessment1/>
        },
        {
          path: "/mba-fpx-5910-assessment-1-capstone-project-description",
          element: < Mbafpx5910assessment1/>
        },
        {
          path: "/nurs-fpx-6620-assessment-1",
          element: < Nursfpx6620assessment1/>
        },
        {
          path: "/nurs-fpx-6620-assessment-2",
          element: < Nursfpx6620assessment2/>
        },
        {
          path: "/nurs-fpx-6620-assessment-3",
          element: < Nursfpx6620assessment3/>
        },
        {
          path: "/nurs-fpx-6620-assessment-4",
          element: < Nursfpx6620assessment4/>
        },
        {
          path: "/nurs-fpx-6622-assessment-1",
          element: < Nursfpx6622assessment1/>
        },
        {
          path: "/nurs-fpx-6622-assessment-2",
          element: < Nursfpx6622assessment2/>
        },
        {
          path: "/nurs-fpx-6622-assessment-3",
          element: < Nursfpx6622assessment3/>
        },
        {
          path: "/nurs-fpx-6622-assessment-4",
          element: < Nursfpx6622assessment4/>
        },
        {
          path: "/nurs-fpx-6624-assessment-1",
          element: < Nursfpx6624assessment1/>
        },
        {
          path: "/nurs-fpx-6624-assessment-2",
          element: < Nursfpx6624assessment2/>
        },
        {
          path: "/nurs-fpx-6624-assessment-3",
          element: < Nursfpx6624assessment3/>
        },
        {
          path: "/nurs-fpx-6624-assessment-4",
          element: < Nursfpx6624assessment4/>
        },
        {
          path: "/nurs-fpx-6626-assessment-1",
          element: < Nursfpx6626assessment1/>
        },
        {
          path: "/nurs-fpx-6626-assessment-2",
          element: < Nursfpx6626assessment2/>
        },
        {
          path: "/nurs-fpx-6626-assessment-3",
          element: < Nursfpx6626assessment3/>
        },
        {
          path: "/nurs-fpx-6626-assessment-4",
          element: < Nursfpx6626assessment4/>
        },
        {
          path: "/nurs-fpx-6080-assessment-1",
          element: < Nursfpx6080assessment1/>
        },
        {
          path: "/nurs-fpx-6080-assessment-2",
          element: < Nursfpx6080assessment2/>
        },
        {
          path: "/nurs-fpx-6080-assessment-3",
          element: < Nursfpx6080assessment3/>
        },
        {
          path: "/nurs-fpx-6080-assessment-4",
          element: < Nursfpx6080assessment4/>
        },
        {
          path: "/nurs-fpx-6085-assessment-1",
          element: < Nursfpx6085assessment1/>
        },
        {
          path: "/nurs-fpx-6085-assessment-2",
          element: < Nursfpx6085assessment2/>
        },
        {
          path: "/nurs-fpx-6085-assessment-3",
          element: < Nursfpx6085assessment3/>
        },
        {
          path: "/nurs-fpx-6085-assessment-4",
          element: < Nursfpx6085assessment4/>
        },
        {
          path: "/psy-250-week-4-behavioral-and-social-cognitive",
          element: < Psy250week4/>
        },
        {
          path: "/soc-315-week-1-cultural-diversity",
          element: < Soc315week1/>
        },
        {
          path: "/soc-315-week-3-cultural-heritage-traditions",
          element: < Soc315week3/>
        },
        {
          path: "/soc-315-week-4-function-of-the-eeoc",
          element: < Soc315week4/>
        },
        {
          path: "/soc-102-topic-3-bureaucracy-essay",
          element: < Soc102topic3/>
        },
        {
          path: "/jus-212-topic-6-differential-association-theory",
          element: < Jus212topic6/>
        },
        {
          path: "/psy-366-topic-6-sports-psychology-and-roles-they-play",
          element: < Psy366topic6/>
        },
        {
          path: "/bus-4070-unit-5-assignment-1-efficient-markets",
          element: < Bus4070unit5assignment1/>
        },
        {
          path: "/hcs-475-week-5-resolving-responsibility-evaluation",
          element: < Hcs475week5/>
        },
        {
          path: "/psy-250-week-2-psychoanalytic-personality",
          element: < Psy250week2/>
        },
        {
          path: "/pys-250-week-3-psychology-of-personality",
          element: < Pys250week3/>
        },
        {
          path: "/nurs-fpx-6212-assessment-3-outcome",
          element: < Nursfpx6212assessment3/>
        },
        {
          path: "/jus-430-week-5-opinion-about-prostitution",
          element: < Jus430week5/>
        },
        {
          path: "/jus-430-week-6-controlled-substances",
          element: < Jus430week6/>
        },
        {
          path: "/psy-368-topic-4-role-of-politics",
          element: < Psy368topic4/>
        },
        {
          path: "/nurs-fpx-8030-assessment-1-building-the-case-for-healthcare",
          element: < Nursfpx8030assessment1/>
        },
        {
          path: "/mha-fpx-5016-assessment-1-analysis",
          element: < Mhafpx5016assessment1/>
        },
        {
          path: "/n601-assignment-1.1-picot-question",
          element: < N601assignment1/>
        },
        {
          path: "/bha-fpx-4112-assessment-3-national-health-insurance",
          element: < Bhafpx4112assessment3/>
        },
        {
          path: "/nurs-fpx-9902-assessment-5-reflection",
          element: < Nursfpx9902assessment5/>
        },
        {
          path: "/nurs-fpx-8002-assessment-3-personal-leadership",
          element: < Nursfpx8002assessment3/>
        },
        {
          path: "/nurs-fpx-6107-assessment-2-course-development-and-influencing-factors",
          element: < Nursfpx6107assessment2/>
        },
        {
          path: "/nurs-fpx-6021-assessment-1-concept-map",
          element: < Nursfpx6021assessment1/>
        },
        {
          path: "/mha-fpx-5012-assessment-1-best-practices",
          element: < Mhafpx5012assessment1/>
        },
        {
          path: "/his-12203-assignment-article-review",
          element: < His12203assignment/>
        },
        {
          path: "/nur-3500-assignment-article-critique",
          element: < Nur3500assignment/>
        },
        {
          path: "/nur-3500-assignment-ebp-guidelines-part-i",
          element: < Nur3500assignmenta/>
        },
        {
          path: "/nur-4307-assignment-primary-prevention-paper",
          element: < Nur4307assignment/>
        },
        {
          path: "/nur-3500-module-6-assignment-final-ebp-project",
          element: < Nur3500module6assignment/>
        },
        {
          path: "/n584-assignment-module-1-application-of-course",
          element: < N584assignmentmodule1/>
        },
        {
          path: "/nr-586np-week-8-collaboration-cafe",
          element: < Nr586npweek8/>
        },
        {
          path: "/nr-582-week-2-assignment-leadership-self-assessment",
          element: < Nr582week2assignment/>
        },
        {
          path: "/nr-583-week-2-assignment-information-systems-evaluation",
          element: < Nr583week2assignment/>
        },
        {
          path: "/nr-583-week-6-assignment-summary-of-the-article",
          element: < Nr583week6assignment/>
        },
        {
          path: "/hs-450-m4-assignment-health-care-regulation",
          element: < Hs450m4assignment/>
        },
        {
          path: "/hs-450-m6-assessment-competency-assessment",
          element: < Hs450m6assessment/>
        },
        {
          path: "/hs-165-m2-assignment-title-vi-of-the-civil-rights-act",
          element: < Hs165m2assignment/>
        },
        {
          path: "/Hs-230-m3-assignment-health-care-access",
          element: < Hs230m3assignment/>
        },
        {
          path: "/ha-255-m4-assignment-ethical-and-legal-issues",
          element: < Ha255m4assignment/>
        },
        {
          path: "/nurs-fpx-4040-assessment-2-protected-health-information",
          element: < Nursfpx4040assessment2/>
        },
        {
          path: "/nurs-fpx-6109-assessment-3-educational-technologies-comparison",
          element: < Nursfpx6109assessment3/>
        },
        {
          path: "/nurs-fpx-5005-assessment-4-analysis-of-technology-in-nursing-practice",
          element: < Nursfpx5005assessment4/>
        },
        {
          path: "/bha-fpx-4008-assessment-1-developing-an-operating-budget",
          element: < Bhafpx4008assessment1/>
        },
        {
          path: "/nurs-fpx-6100-assessment-1",
          element: < Nursfpx6100assessment1/>
        },
        {
          path: "/nurs-fpx-6100-assessment-2",
          element: < Nursfpx6100assessment2/>
        },
        {
          path: "/nurs-fpx-6100-assessment-3",
          element: < Nursfpx6100assessment3/>
        },
        {
          path: "/nurs-fpx-6100-assessment-4",
          element: < Nursfpx6100assessment4/>
        },
        {
          path: "/nurs-fpx-6108-assessment-1",
          element: < Nursfpx6108assessment1/>
        },
        {
          path: "/nurs-fpx-6108-assessment-2",
          element: < Nursfpx6108assessment2/>
        },
        {
          path: "/nurs-fpx-6108-assessment-3",
          element: < Nursfpx6108assessment3/>
        },
        {
          path: "/nurs-fpx-6108-assessment-4",
          element: < Nursfpx6108assessment4/>
        },
        {
          path: "/nurs-fpx-6112-assessment-1",
          element: < Nursfpx6112assessment1/>
        },
        {
          path: "/nurs-fpx-6112-assessment-2",
          element: < Nursfpx6112assessment2/>
        },
        {
          path: "/nurs-fpx-6112-assessment-3",
          element: < Nursfpx6112assessment3/>
        },
        {
          path: "/nurs-fpx-6112-assessment-4",
          element: < Nursfpx6112assessment4/>
        },
        {
          path: "/nurs-fpx-6116-assessment-1",
          element: < Nursfpx6116assessment1/>
        },
        {
          path: "/nurs-fpx-6116-assessment-2",
          element: < Nursfpx6116assessment2/>
        },
        {
          path: "/nurs-fpx-6116-assessment-3",
          element: < Nursfpx6116assessment3/>
        },
        {
          path: "/nurs-fpx-6116-assessment-4",
          element: < Nursfpx6116assessment4/>
        },
        {
          path: "/nurs-fpx-6400-assessment-1",
          element: < Nursfpx6400assessment1/>
        },
        {
          path: "/nurs-fpx-6400-assessment-2",
          element: < Nursfpx6400assessment2/>
        },
        {
          path: "/nurs-fpx-6400-assessment-3",
          element: < Nursfpx6400assessment3/>
        },
        {
          path: "/nurs-fpx-6400-assessment-4",
          element: < Nursfpx6400assessment4/>
        },
        {
          path: "/nurs-fpx-6422-assessment-1",
          element: <Nursfpx6422assessment1/>
        },
        {
          path: "/nurs-fpx-6422-assessment-2",
          element: <Nursfpx6422assessment2/>
        },
        {
          path: "/nurs-fpx-6422-assessment-3",
          element: <Nursfpx6422assessment3/>
        },
        {
          path: "/nurs-fpx-6422-assessment-4",
          element: <Nursfpx6422assessment4/>
        },
        {
          path: "/nurs-fpx-6424-assessment-1",
          element: <Nursfpx6424assessment1/>
        },
        {
          path: "/nurs-fpx-6424-assessment-2",
          element: <Nursfpx6424assessment2/>
        },
        {
          path: "/nurs-fpx-6424-assessment-3",
          element: <Nursfpx6424assessment3/>
        },
        {
          path: "/nurs-fpx-6424-assessment-4",
          element: <Nursfpx6424assessment4/>
        },
        {
          path: "/nurs-fpx-6426-assessment-1",
          element: <Nursfpx6426assessment1/>
        },
        {
          path: "/nurs-fpx-6426-assessment-2",
          element: <Nursfpx6426assessment2/>
        },
        {
          path: "/nurs-fpx-6426-assessment-3",
          element: <Nursfpx6426assessment3/>
        },
        {
          path: "/nurs-fpx-6426-assessment-4",
          element: <Nursfpx6426assessment4/>
        },
        {
          path: "/Nurs-fpx-6200-assessment-1",
          element: <Nursfpx6200assessment1/>
        },
        {
          path: "/Nurs-fpx-6200-assessment-2",
          element: <Nursfpx6200assessment2/>
        },
        {
          path: "/Nurs-fpx-6200-assessment-3",
          element: <Nursfpx6200assessment3/>
        },
        {
          path: "/Nurs-fpx-6200-assessment-4",
          element: <Nursfpx6200assessment4/>
        },
        {
          path: "/Nurs-fpx-6222-assessment-1",
          element: <Nursfpx6222assessment1/>
        },
        {
          path: "/Nurs-fpx-6222-assessment-2",
          element: <Nursfpx6222assessment2/>
        },
        {
          path: "/Nurs-fpx-6222-assessment-3",
          element: <Nursfpx6222assessment3/>
        },
        {
          path: "/Nurs-fpx-6222-assessment-4",
          element: <Nursfpx6222assessment4/>
        },
        {
          path: "/Nurs-fpx-6224-assessment-1",
          element: <Nursfpx6224assessment1/>
        },
        {
          path: "/Nurs-fpx-6224-assessment-2",
          element: <Nursfpx6224assessment2/>
        },
        {
          path: "/Nurs-fpx-6224-assessment-3",
          element: <Nursfpx6224assessment3/>
        },
        {
          path: "/Nurs-fpx-6224-assessment-4",
          element: <Nursfpx6224assessment4/>
        },
        {
          path: "/Nurs-fpx-6226-assessment-1",
          element: <Nursfpx6226assessment1/>
        },
        {
          path: "/Nurs-fpx-6226-assessment-2",
          element: <Nursfpx6226assessment2/>
        },
        {
          path: "/Nurs-fpx-6226-assessment-3",
          element: <Nursfpx6226assessment3/>
        },
        {
          path: "/Nurs-fpx-6226-assessment-4",
          element: <Nursfpx6226assessment4/>
        },
        {
          path: "/mba-5910-assessment-3-strategy-recommendation",
          element: <Mbafpx5910assessment3/>
        },
        {
          path: "/nr-583-week-4-clinical-decision-support-teaching-tool",
          element: <Nr583week4/>
        },
        {
          path: "/nr-584-week-3-risk-management-case-study",
          element: <Nr584week3/>
        },
        {
          path: "/nr-538-week-6-assignment-1",
          element: <Nr538week6assignment1/>
        },
        {
          path: "/mha-fpx-5012-assessment-3",
          element: <Mhafpx5012assessment3/>
        },
        {
          path: "/nurs-fpx-6212-assessment-1-quality-and-safety-gap",
          element: <Nursfpx6212assessment1/>
        },
        {
          path: "/nurs-fpx-6214-assessment-3-implementation-plan",
          element: <Nursfpx6214assessment3/>
        },
        {
          path: "/nurs-fpx-8014-assessment-2-global-issue-problem",
          element: <Nursfpx8014assessment2/>
        },
        {
          path: "/soc-102-topic-2-social-stratification",
          element: <Soc102topic2/>
        },
        {
          path: "/hcs-487-week-5-the-role-of-a-healthcare-manager",
          element: <Hcs487week5/>
        },
        {
          path: "/nur-504-module-2-assignment-1-comprehensive-mental-health",
          element: <Nur504module2assignment1/>
        },
        {
          path: "/psy-368-topic-5-history-and-organization-of-sports",
          element: <Psy368topic5/>
        },
        {
          path: "/nurs-fpx-6026-assessment-1-analysis-of-position-papers",
          element: <Nursfpx6026assessment1/>
        },
        {
          path: "/nur-505-module-1-assessment-2-cultural-interview-questions",
          element: <Nur505module1assessment2/>
        },
        {
          path: "/nurs-fpx-6105-assessment-1-learning-theories-and-diversity",
          element: <Nursfpx6105assessment1/>
        },
        {
          path: "/nurs-fpx-6008-assessment-1-identifying-a-local-health-care",
          element: <Nursfpx6008assessment1/>
        },
        {
          path: "/nurs-fpx-5010-assessment-4-expansion-recommendation",
          element: <Nursfpx5010assessment4/>
        },
        {
          path: "/nurs-fpx-6410-assessment-1-presentation-to-informatics-staff",
          element: <Nursfpx6410assessment1/>
        },
        {
          path: "/nur-600-module-2-assignment-begin-research-for-your-drug",
          element: <Nur600module2assignment/>
        },
        {
          path: "/nur-600-module-3-assignment-draft-of-project",
          element: <Nur600module3assignment/>
        },
        {
          path: "/nur-505-module-3-week-4-assignment-cultural-assessment",
          element: <Nur505module3week4assignment/>
        },
        {
          path: "/nur-506-module-4-assessment-policy-analysis",
          element: <Nur506module4assessment/>
        },
        {
          path: "/nurs-fpx-6011-assessment-3-implementing-evidence-based",
          element: <Nursfpx6011assessment3/>
        },
        {
          path: "/bha-fpx-4112-assessment-1-health-production-function",
          element: <Bhafpx4112assessment1/>
        },
        {
          path: "/nurs-fpx-4030-assessment-3-picot-questions",
          element: <Nursfpx4030assessment3/>
        },
        {
          path: "/mba-fpx-5010-assessment-1-accounting-tools",
          element: <Mbafpx5010assessment1/>
        },
        {
          path: "/nurs-fpx-6410-assessment-3-exploration-of-regulations-and-implications",
          element: <Nursfpx6410assessment3/>
        },
        {
          path: "/mba-fpx-5014-assessment-1-financial-condition",
          element: <Mbafpx5014assessment1/>
        },
        {
          path: "/nur-600-module-5-assessment-drug-powerpoint-presentation",
          element: <Nur600module5assessment/>
        },
        {
          path: "/nurs-6051-module-5-assignment-role-of-nurse-informatics",
          element: <Nurs6051module5assignment/>
        },
        {
          path: "/nurs-6050-week-6-assignment-board-of-nursing",
          element: <Nurs6050week6assignment/>
        },
        {
          path: "/nur-506-module-2-assignment-regulatory-agencies",
          element: <Nur506module2assignment/>
        },
        {
          path: "/nurs-fpx-4900-assessment-3-assessing-the-problem-technology",
          element: <Nursfpx4900assessment3/>
        },
        {
          path: "/bus-fpx-3030-assessment-4-promotion-analysis",
          element: <Busfpx3030assessment4/>
        },
        {
          path: "/bha-fpx-4010-assessment-3-quantitative-research-questions",
          element: <Bhafpx4010assessment3/>
        },
        {
          path: "/nurs-fpx-6612-assessment-2-quality-improvement",
          element: <Nursfpx6612assessment2/>
        },
        {
          path: "/nurs-fpx-6030-assessment-3-intervention-plan",
          element: <Nursfpx6030assessment3/>
        },
        {
          path: "/nurs-fpx-6614-assessment-1-defining-a-gap",
          element: <Nursfpx6614assessment1/>
        },
        {
          path: "/nur-505-module-5-assignment",
          element: <Nur505module5assignment/>
        },
        {
          path: "/nur-501-module-4-assignment-1-theoretical-framework",
          element: <Nur501module4assignment1/>
        },
        {
          path: "/fnp-593-week-2-assignment-pediatric-illness",
          element: <Fnp593week2assignment/>
        },
        {
          path: "/fnp-593-week-3-assignment-early-pregnancy-obstetrics",
          element: <Fnp593week3assignment/>
        },
        {
          path: "/nur-600-module-2-assignment",
          element: <Nur600module2assignmentb/>
        },
        {
          path: "/fnp-591-week-7-assignment-healthcare-problem-hypertension",
          element: <Fnp591week7assignment/>
        },
        {
          path: "/rsch-fpx-7864-assessment-2-correlation-application",
          element: <Rschfpx7864assessment2/>
        },
        {
          path: "/fnp-597-week-5-assignment-aprn-employment-contract",
          element: <Fnp597week5assignment/>
        },
        {
          path: "/nur-600-week-5-assignment-methods-design",
          element: <Nur600week5assignment/>
        },
        {
          path: "/nur-600-week-3-assignment-evidence-based",
          element: <Nur600week3assignment/>
        },
        {
          path: "/nurs-600-assignment-1-2-theory-project-sign-up",
          element: <Nurs600assignment1_2/>
        },
        {
          path: "/n550-module-5-assignment-1-annotated-bibliography",
          element: <N550module5assignment/>
        },
        {
          path: "/nurs-6373-assignment-2-1-short-answer-assignment",
          element: <Nurs6373assignment2_1/>
        },
        {
          path: "/nurs-fpx-8143-week-2-assignment-critical-thinking",
          element: <Nursfpx8143week2assignment/>
        },
        {
          path: "/n508-module-4-assignment-stress-management-for-patients",
          element: <N508module4assignment/>
        },
        {
          path: "/ha-415-m5-assessment-competency-assessment-health-care",
          element: <Ha415m5Assessment/>
        },
        {
          path: "/ha-415-m2-assessment-presentation-of-policy-proposal",
          element: <Ha415m2assessment/>
        },
        {
          path: "/hs-101-m2-assignment-federal-and-state-healthcare-regulation",
          element: <Hs101m2assignment/>
        },
        {
          path: "/nurs-601-assignment-1.1-pico(t)-question",
          element: <Nurs601assignment1_1/>
        },
        {
          path: "/nurs-601-assignment-1.3-paraphrasing-practice",
          element: <Nurs601assignment1_3/>
        },
        {
          path: "/nurs-601-assignment-3.1-critique-a-clinical-practice",
          element: <Nurs601assignment3_1/>
        },
        {
          path: "/nurs-600-assignment-6.1-empowering-nursing-leadership",
          element: <Nurs600assignment6_1/>
        },
        {
          path: "/ha-415-m4-unit-3-assignment-health-care-policy",
          element: <Ha415m4assessment/>
        },
        {
          path: "/hs-101-m1-assignment-navigating-legal-and-ethical",
          element: <Hs101m1assignment/>
        },
        {
          path: "/ha-415-m3-assessment-roles-of-policy-makers-in-healthcare",
          element: <Ha415m3assessment/>
        },
        {
          path: "/hs-165-m5-assessment-advancing-health-equity-in-the-workplace",
          element: <Hs165m5assessment/>
        },
        {
          path: "/ef-205-m4-assignment-energy-metabolism-and-activity",
          element: <Ef205m4assignment/>
        },
      ]
    },
    {
        path: "/register",
        element: <Register/>,
    },
    {
        path: "/login",
        element: <Login/>,
    },
    {
      path: "/md-admin-page",
      element: <Mdadminpage/>
    }
  ]);


const App = () => {
  return (
    <>
        <RouterProvider router={router}/>
    </>
  )
}

export default App
