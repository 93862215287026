import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Nursfpx8014assessment2 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>NURS FPX 8014 Assessment 2 Global Issue Problem</title>
                <meta name='description'
                    content='Explore NURS FPX 8014 Assessment 2 Global Issue Problem, tackling maternal health challenges with strategic solutions. Read more now!' />
                <meta name='keywords' content='NURS FPX 8014 Assessment 2 Global Issue Problem' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>NURS FPX 8014 Assessment 2 < br /><span>Global Issue Problem Description</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Nursfpx8014assessment2.webp" alt="NURS FPX 8014 Assessment 2 Global Issue Problem" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><strong><em><span data-preserver-spaces="true">NURS FPX 8014 Assessment 2 Global Issue Problem Description.</span></em></strong></li>
                        </ul>
                        <h2><span data-preserver-spaces="true">Global Issue Problem Description</span></h2>
                        <p><span data-preserver-spaces="true">Maternal prosperity is one of the most challenging issues in Haiti, where socio-economic, political, and social issues contribute to high maternal mortality rates. The paper will search and analyze the prevalence of the problem, its effects on people and overall society, and the available information regarding socioeconomic, political, and social determinants.&nbsp;The proposed project seeks to revamp maternal prosperity by training midwives and birth attendants on current evidence-based care practices. </span></p>
                        <p><span data-preserver-spaces="true">This approach further improves clinical considerations in the country and underserved areas. The analysis involves surveying past strategies and identifying existing gaps, culminating in an all-inclusive intent to address the problem as an evident truth. Explore our assessment&nbsp;</span><a href="https://onlinecourseservices.us/nurs-fpx-8014-assessment-1-nongovernmental-agencies" target="_blank"><span data-preserver-spaces="true">NURS FPX 8014 Assessment 1 Nongovernmental Agencies Involved in Global Issues</span></a><span data-preserver-spaces="true"> for more information.</span></p>
                        <h2><span data-preserver-spaces="true">Social Determinants That Impact the Problem</span></h2>
                        <p>Numerous social determinants, such as poverty, education, and access to health care, strongly influence maternal well-being in Haiti. Poor-paying women, particularly those in comparable neighborhoods, are most distressed by poor maternal well-being outcomes (World Prosperity Association, 2019). Socioeconomic status becomes relevant here, as poverty constrains access to needed health care and resources. The differences in education also matter, as the lower the level of maternal preparedness, the higher the risks for poor well-being outcomes (Bain, 2020).<br /><br />Social beliefs and practices further impact the maternal prosperity. In the case of some communities, traditional practices and the mistrust of current clinical interventions are leading to either seeking care late or depending on unskilled birth attendants (Smith et al., 2020). Geological challenges, such as mountainous districts and distant locations, enhance these issues and make clinical consideration facilities inaccessible. As described in NURS FPX 8014 Assessment 2 Global Issue Problem Description, the problem is aggravated by the instability of the political system and the limitation of the clinical consideration infrastructure coupled with a lack of support and resources available for maternal prosperity programs (Brown et al., 2021).<br /><br />The current state of maternal prosperity in Haiti remains precarious, with huge challenges in metropolitan and country settings. The clinical benefits infrastructure in Haiti is young and often overburdened, especially after disastrous events such as the 2010 seismic shudder and offbeat hurricanes. These have greatly disrupted clinical consideration services and heightened already existing vulnerabilities. Despite global associates and the efforts of NGOs, many prosperity facilities lack supplies, satisfactory staffing, and solid support (UNICEF, 2021).</p>
                        <ul>
                            <li>
                                <h3><span data-preserver-spaces="true">Challenges in Maternal Healthcare Access</span></h3>
                            </li>
                        </ul>
                        <p>Transportation problems for organizations such as Doctors Without Borders (M&eacute;decins Sans Fronti&egrave;res, MSF) also put a shadow over the delivery of maternal welfare services in Haiti. Insecurity problems, among them political violence and gang brutality, pose life-threatening risks for health care workers and are a barrier to them reaching remote small communities. MSF and other organizations are often faced with strict and non-exacting roadblocks while trying to pass care on to distant areas at the same time. Apart from security threats, the country's sad infrastructure&mdash;many roads are in disrepair or impassable during the stormy season&mdash;makes travel testing time-consuming (Doctors Without Borders, 2020).</p>
                        <h3>NURS FPX 8014 Assessment 2 Global Issue Problem Description</h3>
                        <p>There are also the logistical challenges of a lack of reliable means of transportation and high fuel costs. These quality improvement issues become stumbling blocks to the smooth upgrading of clinical supplies and human resources to those areas most in need. With the combination of these factors together, there is an occurrence of delay and discontinuation of care with possibly devastating outcomes for the mother and newborn. Organizations come to use shrewd solutions, such as motorcycles or boats, in order to navigate a troublesome area, yet these solutions only help to assuage the deeper systemic issues somewhat (Global Prosperity Association, 2022).<br /><br />The effect of these social determinants, social beliefs, geological challenges, and logistic problems brings into stark relief the dizzying prospect of managing maternal well-being in Haiti. The fantastic problems just described demand a coordinated effort on the part of local communities, international organizations, and governmental bodies to guarantee that all women receive the care they deserve for safe pregnancy and delivery.</p>
                        <h2><span data-preserver-spaces="true">Exploration of Historical Strategies and Evolution of PIH's Approach Over Time</span></h2>
                        <p>Partners In Health (PIH) is an organization in the type of Ngo which for years confronted those issues around the delivery of health in Haiti. This was after 1987 founded by such members including: Paul Farmer, Jim Yong Kim, Ophelia Dahl, Todd McCormack, and Thomas J. White. Its Mission surrounds PIH by considering especially a preferential option of those afflicted and health and health as offered through caring and relief Farmer et al. (2019).<br /><br />Through the years, PIH has developed strategies to adjust to the changing landscape of clinical benefits in Haiti, with strong attention to maternal prosperity. In its early years, PIH's strategies were focused on local care, emphasizing the delivery of clinical care services directly within communities. In that way, this approach was quintessential in reaching underserved populations in the rural settings. PIH established clinics and hospitals in these remote areas, bringing clinical benefits to those populations that needed them most. It emphasized holistic care, not just clinical benefits but also social support, like housing, education, and nutrition (Rancher et al., 2019).</p>
                        <h3><span data-preserver-spaces="true">NURS FPX 8014 Assessment 2 Global Issue Problem Description</span></h3>
                        <p>A devastating earthquake in the year 2010 made a deep, necessary, critical defining moment for PIH. The disaster severely disrupted Haiti's right now sensitive clinical consideration infrastructure. In response, PIH scaled up efforts to re-endeavor and strengthen clinical consideration facilities with regard to speedy repercussions and resilience to a great extent. This consolidated the construction of the H&ocirc;pital Universitaire de Mirebalais, a state-of-the-art instructing hospital that serves as a sorted-out ground for Haitian clinical professionals (Mukherjee et al., 2021).<br /><br />Having witnessed the high rates of maternal deaths that occur in Haiti, PIH has placed increasing attention on maternal welfare. Traditionally, maternal welfare programs were integral to more prominent prosperity programs, but over time, PIH has written specific strategies to deal with this underlying issue. This includes combining diagram midwives and birth attendants for antenatal and postnatal concerns and ensuring that crisis obstetric services are available Mukherjee et al., 2021.<br /><br />PIH's model has also evolved to work with closer collaborations and partnerships with other groups, both nearby and worldwide. The PIH is able to mobilize a huge amount of resources and human capacity in the fight against maternal clinical challenges through supporting government agencies, other NGOs, and generally bodies. Such collaborations drive a more concerted and holistic approach to clinical consideration improvement (Rancher et al., 2019).</p>
                        <h2><span data-preserver-spaces="true">Strategies and Gaps</span></h2>
                        <p>In the past, several strategies have been employed in the attempt to handle the maternal clinical issues in Haiti. These include combination global accessory programs, government initiatives as well as interventions by NGOs. For instance, maternity care assembling programs and the establishment of maternal prosperity clinics have been seen to somewhat improve outcomes (Smith et al., 2020).<br /><br />Maternity care programmes, having been greatly effective in the past, drew in trained birth attendants able to cope with both normal and complicated deliveries that helped lower the rates of maternal and neonatal mortality. Sustenance has been challenging as these health institutions faced trial because of poor support and political interference. Past attempts are revitalised through the new proposal since, as pointed out, evidence-based practice remains well carried through with midwifes' and birth attendants' approaches in most instances of service provision.<br /><br />Programs of orchistration will be made to nibble at their perception and capabilities so that they may be attuned to give excellent maternal care. This strategy will incorporate working in tandem with effort to neighborhood prosperity, government authorities, and international NGOs to come up with broad stretching out material and programs for training (WHO, 2019).</p>
                        <ul>
                            <li>
                                <h3><span data-preserver-spaces="true">Improving Maternal Healthcare Standards</span></h3>
                            </li>
                        </ul>
                        <p>The effort will target the average at best and the underserved areas where knowledgeable clinical thinking providers are needed most. It will seek to improve maternal wellness outcomes in a sustainable manner by bringing in updated, evidence-based practices under existing frameworks. The proposed organizing will address a number of areas, including antenatal care, safe progress practices, postpartum care, and crisis obstetric interventions.<br /><br />The antenatal care modules will also give emphasis to regular check-ups, enabling support, and early recognition of potential complications. Safe delivery practices will revolve around the supervision of normal births and the early recognition of warning signs for complications.</p>
                        <h3>NURS FPX 8014 Assessment 2 Global Issue Problem Description</h3>
                        <p>The planning of postpartum care will bring to attention both maternal and neonatal health and wellness, including watching and managing postpartum hemorrhage and infection, while giving appropriate breastfeeding support. Crisis obstetric consideration will be organized for managing complications like blood poisoning and obstructed labor and when to refer patients to higher level of consideration facilities (UNICEF, 2021).</p>
                        <p>Despite several interventions, there are still significant gaps in maternal health care in Haiti. Among the most crucial gaps is the unstandardized making arrangements for midwives and birth attendants that contribute to the inconsistent quality of consideration (Smith et al., 2020). Currently, there are large discrepancies in the planning and certification of birth attendants, which gives rise to inequalities in the level of care given. National level planning standardization is required for all maternal clinical benefits providers to obtain proper skills and knowledge accordingly.</p>
                        <ul>
                            <li>
                                <h3><span data-preserver-spaces="true">Enhancing Maternal Healthcare Infrastructure</span></h3>
                            </li>
                        </ul>
                        <p>It further requires additional robust clinical benefits structure and ready availability of clinical consumables and facility. Most successful clinics in the rural setting lack basic equipment like sterilizing supplies and common drugs that form the basis of safe deliveries and crisis care. Enhancement in the supply chains along with continuity in accessing any clinical consumables will be the most intrinsic part of the proposed project (Brown et al., 2021).<br /><br />Political and financial instability further worsens efforts to control maternal health, as funds are often available in short supply and poorly accounted for (Bain, 2020). Successive changes in government and policy can destabilize advancing health programs and contribute to a lack of continuity of care. The proposed initiative will work with local and state governments to ensure that planning programs are strengthened and integrated into the larger clinical care system.<br /><br />The proposed activity, which aims at showing midwives and birth attendants the practices of evidence-based care, tries to fill these gaps by working on the skills and data of clinical benefits providers. The effort has the goal of addressing the possibility of maternal consideration in rustic and underserved areas, diminishing maternal passing rates, and promoting further prosperous outcomes for mothers and children in Haiti by its focus on organizing and schooling.<br /><br />The campaign will also consolidate mechanisms for progressing support and supervision, such as standard refresher courses and a readiness system, so that the skills and data acquired during orchestrating do not become ignorant and are kept refreshed over time (Jones &amp; McEwen, 2019).</p>
                        <h2><span data-preserver-spaces="true">Conclusion</span></h2>
                        <p>In conclusion, the maternal prosperity of Haiti requires a multi-dimensional approach that includes addressing the education and training of health care providers, strengthening the infrastructure of health care, and ensuring a stable supply of health care commodities.</p>
                        <p>As indicated in <a href="https://acemycourse.net/nurs-fpx-8014-assessment-2-global-issue-problem-description/">NURS FPX 8014 Assessment 2 Global Issue Problem Description</a>, the proposed project would work toward an expansion of the already existing strategies while addressing the continuing gaps, all with an orientation toward evidence-based practices and areas of greatest need. It seeks to refresh the skills and data of midwives and birth attendants, working to ensure that a lasting change will occur for the maternal prosperity outcomes of Haiti.</p>
                        <h2><span data-preserver-spaces="true">References</span></h2>
                        <p><span data-preserver-spaces="true">Bain, L. E. (2020). The influence of cultural beliefs on maternal health in Haiti.&nbsp;</span><em><span data-preserver-spaces="true">Global Health Action, 13(</span></em><span data-preserver-spaces="true">1), 1831793.&nbsp;</span><a href="https://doi.org/10.1080/16549716.2020.1831793" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.1080/16549716.2020.1831793</span></a></p>
                        <p><span data-preserver-spaces="true">Brown, M., Johnson, P., &amp; Lee, H. (2021). Financial sustainability of global health NGOs: Strategies and challenges.&nbsp;</span><em><span data-preserver-spaces="true">Global Health Research and Policy, 6</span></em><span data-preserver-spaces="true">(1), 22&ndash;34.&nbsp;</span><a href="https://doi.org/10.1186/s41256-021-00212-3" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.1186/s41256-021-00212-3</span></a></p>
                        <p><span data-preserver-spaces="true">Doctors Without Borders. (2020).&nbsp;</span><em><span data-preserver-spaces="true">Challenges and successes in maternal healthcare in Haiti.&nbsp;</span></em><a href="https://www.doctorswithoutborders.org/what-we-do/countries/haiti" target="_blank"><span data-preserver-spaces="true">https://www.doctorswithoutborders.org/what-we-do/countries/haiti</span></a></p>
                        <p><span data-preserver-spaces="true">Farmer, P., Mukherjee, J. S., Gupta, R., &amp; Williams, E. (2019). Community-based care and&nbsp;sustainable healthcare in Haiti.&nbsp;</span><em><span data-preserver-spaces="true">Global Health Innovations, 14</span></em><span data-preserver-spaces="true">(1), 45&ndash;61.&nbsp;Global Health Organization. (2022). Maternal health care practices in low-income countries.&nbsp;</span><em><span data-preserver-spaces="true">International Journal of Health Services, 52</span></em><span data-preserver-spaces="true">(3), 231&ndash;245.&nbsp;</span><a href="https://doi.org/10.1177/0020731421106923" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.1177/0020731421106923</span></a></p>
                        <p><span data-preserver-spaces="true">Jones, A., &amp; McEwen, S. (2019). Coordination between NGOs and government health services: Lessons from Haiti.&nbsp;</span><em><span data-preserver-spaces="true">Journal of International Development, 31</span></em><span data-preserver-spaces="true">(5), 743-758.&nbsp;</span><a href="https://doi.org/10.1002/jid.3427" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.1002/jid.3427</span></a></p>
                        <p><span data-preserver-spaces="true">Mukherjee, J. S., Farmer, P., Niyizonkiza, D., &amp; Atwood, S. (2021). The evolution of Partners In&nbsp;Health's strategies in Haiti.&nbsp;</span><em><span data-preserver-spaces="true">Health Systems and Reform, 7</span></em><span data-preserver-spaces="true">(2), 104-119 Partners In Health. (2023).&nbsp;</span><em><span data-preserver-spaces="true">Annual report</span></em><span data-preserver-spaces="true">.&nbsp;</span><a href="https://www.pih.org/" target="_blank"><span data-preserver-spaces="true">https://www.pih.org</span></a></p>
                        <p><span data-preserver-spaces="true">Smith, J., Brown, K., &amp; Nguyen, T. (2020). Challenges and opportunities for NGOs in global health.&nbsp;</span><em><span data-preserver-spaces="true">Global Public Health, 15</span></em><span data-preserver-spaces="true">(9), 1328&ndash;1344.&nbsp;</span><a href="https://doi.org/10.1080/17441692.2020.1724317" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.1080/17441692.2020.1724317</span></a></p>
                        <p><em><span data-preserver-spaces="true">Maternal and Child Health Journal, 25</span></em><span data-preserver-spaces="true">(7), 1041-1052.&nbsp;</span><a href="https://doi.org/10.1007/s10995-%20021-03139-2" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.1007/s10995- 021-03139-2</span></a></p>
                        <p><span data-preserver-spaces="true">World Health Organization. (2019). Maternal health in Haiti: A review.&nbsp;</span><em><span data-preserver-spaces="true">WHO Publications</span></em><span data-preserver-spaces="true">.&nbsp;</span><a href="https://www.who.int/publications/haiti-maternal-health" target="_blank"><span data-preserver-spaces="true">https://www.who.int/publications/haiti-maternal-health</span></a></p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Nursfpx8014assessment2
