import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Jus430week6 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>JUS 430 Week 6 Controlled Substances</title>
                <meta name='description'
                    content="Should certain drugs be legalized? JUS 430 Week 6 Controlled Substances examines the risks, policies, and legal implications. Learn more now!" />
                <meta name='keywords' content='JUS 430 Week 6 Controlled Substances' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>JUS 430 Week 6 < br /><span>Controlled Substances</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Jus430week6.webp" alt="JUS 430 Week 6 Controlled Substances" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><strong><em>JUS 430 Week 6 Controlled Substances</em></strong></li>
                        </ul>
                        <h2><strong>Control Substances</strong></h2>
                        <p>The Uniform Controlled Substance Act occurred in 1970. The essential legitimization behind this act was for the U.S. Congress to decide to acquire consistency between the laws of states and those of the public government and to develop the new administrative opiate medicine rule (Improvement, 2018). While there is a mind-blowing conversation on whether sedatives from Plan One Idea Timetable V should be legitimized, a point will be around in the wearisome future. The legalization and plan will be investigated because this subject is so unsafe.</p>
                        <h2><strong>Legalization</strong></h2>
                        <p>Plan I, through Timetable V, contains blueprints from pot, cocaine, steroids, painkillers, and even antidepressants. As to narcotics like cocaine, heroin, and LSD, I do not entirely see that they ought to be guaranteed. Of late, there was a thing going on where a 32-year-old neighbourhood from Massachusetts had kept breathing from an excess of opiates (Bebinger, 2018). The help for which Chris, the difficulty had gone lavishly far, was contemplating the way that the cocaine was bound with fentanyl. Chris' flood on cocaine reverberations through the evidence heard all through the school grounds. Passings teamed up with Fentanyl-bound cocaine are considered to be around 2,000 in Massachusetts (Bebinger, 2018). Legitimizing sedatives will accumulate the usage of approaches like cocaine.</p>
                        <h3><strong><em>JUS 430 Week 6 Controlled Substances</em></strong></h3>
                        <p>Cases like these, where people do not have nearly anything, if any, comprehension of what they are bothered with, can have serious outcomes. To this end, I do not see the slightest bit that steady sedatives should be aware of. Impact of Control Substance Act In Arizona, the Control Substance Act disciplines hazardous arrangements as a class 4 upsetting method for managing acting, if not accused early. Cocaine can be dispatched as a class 4 horrendous method for managing acting. Weed (under 2 lbs) can have a class 6 genuine offence (Arizona Changed Figure, 2018).</p>
                        <p>In Arizona, how can they coordinate between street drug coordinated well-informed authorities and fix clients by picking key appraisals? For example, ownership, transportation, collection, and plan of substance would be for street drugs overall around informed, educated authorities (Trim, 2018). Concerning people who are drug clients, they, according to a general point of view, objective others who are beasts. In the space of Arizona, they can convict a person for having risk in regards to consistency; in any case, the veritable dependence on the tranquilizers is clearly not a terrible show. After research, without question, the area of Arizona underlines discipline.</p>
                        <h2><strong>Reclassification</strong></h2>
                        <p>Concerning the Control Substance Act, I see that hard narcotics ought not to be certifiable. Regardless, there is an astounding fight about whether weed ought to be criticized as hard as different methodologies like cocaine and heroin. There have been various examinations that pot is superior to liquor. There are likewise various conditions where people utilize the solution for clinical use to diminish torment. If an individual has weed, they should not go unrepulsed but rather more charming on the outcomes. For each state, it is different to be despised for the commitment concerning. For example, Minnesota has a fine of 200 bucks <a href="https://www.coursehero.com/sitemap/schools/176-Grand-Canyon-University/courses/5434156-JUS430/">JUS 430 Week 6 Controlled Substances</a> and is named a superfluous terrible method for managing acting (Wreath, 2018). In Florida, the commitment concerning is a 1,000-dollar fine, a first-degree offence (Wreath, 2018).</p>
                        <h2><strong>Conclusion</strong></h2>
                        <p>The Uniform Control Substance Act was the sole inspiration for the decision to get hard on unpleasant drug techniques for overseeing acting. The show discourages the creation or chance of unlawful substances, ownership, and need to pass on, and purposely remains mindful of the unlawful solutions in a vehicle or building. The Uniform Control Substance Act has decided to stop wasting time with express issues, yet it should redesign a piece of the narcotics.</p>
                        <h2><strong>References</strong></h2>
                        <p>Arizona Revised Statutes. (n.d.). Retrieved April 1, 2018, from<a href="https://www.azleg.gov/arsDetail/?title=13"> https://www.azleg.gov/arsDetail/?title=13</a></p>
                        <p>Bebinger, M. (2018, March 29). Fentanyl-laced cocaine is becoming a deadly problem among drug users. <em>NPR</em>. Retrieved April 1, 2018, from.&nbsp;<a href="https://www.npr.org/sections/healthshots/2018/03/29/597717402/fentanyl-laced-cocaine-becoming-a-deadly-problem-among-drug-users">https://www.npr.org/sections/healthshots/2018/03/29/597717402/fentanyl-laced-cocaine-becoming-a-deadly-problem-among-drug-users</a></p>
                        <p>Garland, N. M. (2018). <em>Criminal law for the criminal justice professional</em> (4th ed.). Los Angeles, CA: Glencoe/McGraw-Hill.</p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Jus430week6