import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Soc315week4 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>SOC 315 Week 4 Function of the EEOC</title>
                <meta name='description'
                    content="Learn about the function of the EEOC in SOC 315 Week 4, focusing on its role in enforcing anti-discrimination laws and promoting equal employment opportunities." />
                <meta name='keywords' content='SOC 315 Week 4 Function of the EEOC' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>SOC 315 Week 4 < br /><span>Function of the EEOC</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Soc315week4.webp" alt="SOC 315 Week 4 Function of the EEOC" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><strong><em>SOC 315 Week 4 Function of the EEOC</em></strong></li>
                        </ul>
                        <h2><strong>Equal Employment Opportunity Commission</strong></h2>
                        <h3><strong>Introduction</strong></h3>
                        <p>The social game plan in the working climate is essentially each of the distinctions that everyone has that assist with making an association more enormous. The Equal Employment Opportunity Commission (EEOC) ensures no discrimination issues within the associations it can investigate.</p>
                        <p>This paper will outline the functions of the EEOC, a handicap discrimination case, the EEOC's role in the circumstance, whether or not the case moves social change, and a comparison between a genuine EEOC study and what is written in an article made by a local news outlet. Finally, I will give an arrangement I would use if I were the association's ranking director to ensure that future compliance and inclusion are incorporated into the working climate.</p>
                        <h2><strong>Compliance issues led to the lawsuit and its ramifications.</strong></h2>
                        <p>Jones Lang LaSalle Americas, Inc. (JLL) is a business land firm sued for handicap discrimination. They excused unofficial guidelines when they rescinded a bid for employment they made for Another new development and Asset System Creation Backing Examiner position after the candidate revealed her need to work genuinely one day seven days to go to her Post Terrible Pressure Issue (PTSD) treatment appointments.</p>
                        <h2><strong>EEOC Lawsuit Against JLL</strong></h2>
                        <p>JLL made it clear during the interview that they could be flexible with the methodology for getting work done and a couple of other required blueprints. Right when the candidate told JLL about the PTSD, they, in every practical sense, pulled their offer according to the EEOC. The candidate tried to pull her request work decently and suggested that they don't, yet JLL wouldn't give it another look. The ramifications of JLL's actions are that the EEOC plans to get the candidate back pay close by compensatory and medicinal damages paid to the candidate. They will search for injunctive lighting to ensure this will not happen from here on out. ("U.S. E, qual Employment Opportunity Commission," n.d.).</p>
                        <h2><strong>Equal Employment Opportunity Commission</strong></h2>
                        <p>The Equal Employment Opportunity Commission (EEOC) maintains unofficial guidelines that safeguard work candidates and current experts from being discriminated against for any reason. A few instances of this discrimination would be race, combination, sex, age, and incapacity. They will other than maintain guidelines against an association if they retaliate against someone who introduced an inquiry, was blamed for discrimination, or partook in a discrimination investigation against them.</p>
                        <p>These guidelines apply to all bits of the business, from hiring to firing and everything in between, similar to new developments, wages, and benefits. The EEOC can investigate discrimination charges against all enterprises, with something like 15 working at an association. Other roles of the EEOC are providing initiative and heading about the Focal Government's Equal Opportunity Program, ensuring compliance, providing express assistance, and training to all associations on the Equal Opportunity Guidelines and Guidelines ("U.S. Equal Employment Opportunity Commission," n.d.).</p>
                        <h2><strong>The EEOC's role in the lawsuit</strong></h2>
                        <p>The EEOC filed a lawsuit against JLL for the candidate for violating the Americans with Inabilities Show of 1990. To begin with, the EEOC will attempt to show up at a pre-indictment settlement through its pacification interaction. If a settlement isn't reached, a lawsuit will be filed to get compensation and set out training and passages for the association to better themselves. It explains how the EEOC focuses on such infringement and underlines its principal reasoning for tackling working climate discrimination.</p>
                        <h3><strong>EEOC Recruitment Barrier Priorities</strong></h3>
                        <p>According to the "U.S. Equal Employment Opportunity Commission" (n.d.), "Eliminating barriers in recruitment and hiring, especially class-based recruitment and hiring practices that discriminate against racial, ethnic, and senseless gatherings, more coordinated trained professionals, ladies, and individuals with handicaps, is one of six public priorities saw by the Commission's Immense Execution Plan (SEP)." ("U.S. Equal Employment Opportunity Commission", n.d.).</p>
                        <h2><strong>Promoting Social Change Through Legal Action</strong></h2>
                        <p>This lawsuit will drive social change. After everything settles out, whether it needs to go to court or not, the EEOC will give the fundamental tools to ensure this doesn't repeat. This suit will influence every individual who tries to apply for work at JLL, other than the experts who presently work there yet haven't revealed their inabilities in setting on the worry about being terminated or not getting some work.</p>
                        <p>This lawsuit will show a piece of the unsatisfactory actions that were committed, yet it will show JLL's commitment to changing its structures and making it a predominant working climate. These updates will equivalently be an extraordinary pursuit in the public eye, improving the association's image and showing its commitment to developing a multicultural working environment.</p>
                        <h2><strong>Comparison of the EEOC press release to the local news</strong></h2>
                        <p>As a result of reviewing the news article, I have seen no enormous divisions other than the other articles appearing to revamp the EEOC article. This lawsuit is, generally speaking, so there hasn't been sufficient time for the story to be turned, or the suit won't be a topic that can be used to stain any lofty individuals or associations that the news is right presently targeting.</p>
                        <p>I would never rely upon local, state, or public news outlets or social media for strong sources. Instead, I would investigate the topics on solid locales like the EEOC to educate and keep myself current on fundamental events like this one, which could genuinely influence me since I'm furthermore during the time spent being outlined for PTSD.</p>
                        <ul>
                            <li>
                                <h3><strong><strong>Implementing a Multicultural Program to Promote Inclusion in the Workplace</strong></strong></h3>
                            </li>
                        </ul>
                        <p>I have taken in various structures that could be useful in this ongoing situation. For any circumstance, I would conduct a climate outline, which would determine this issue and all of the other areas of assortment in blueprints to transform into a superb contemplated where the representatives feel the association regards combination in the working climate.</p>
                        <p>Promising you don't fix one district and move backward in another is crucial. In an unending stockpile of the outline, I demand volunteers to shape a multicultural program board and delegates from all locales to add to it to change the association culture, giving them the feeling of inclusion that will convey further results.</p>
                        <h2><strong>SOC 315 Week 4 Function of the EEOC</strong></h2>
                        <p>We would then investigate what other associations have done to succeed and check whether it would work for JLL. Then, again, we would use it to influence and examine actions to help our association. The board will then introduce the technique for dealing with the administrative gathering and proprietors.</p>
                        <p>When embraced, we would begin planning training to move combination and multiculturalism in the working climate. The last advance is to plan racial mindfulness coaching twice continually and continue with the environment outlines yearly to monitor our development or find other locales that need improvement<strong>.</strong></p>
                        <h2><strong>Conclusion</strong></h2>
                        <p>It is crucial to consider social assortment in the workplace and equally essential to report any discrimination within the working climate. Highlights the focal role the EEOC plays in addressing such issues. The EEOC can outfit you with everything. You should know about making a case; if there is one, they will report a lawsuit for you.</p>
                        <p>One model would be the case I read about JLL discriminating against a candidate who has PTSD. The case will drive <a href="https://tutorsacademy.co/soc-315-week-4-function-of-the-eeoc/">SOC 315 Week 4 Function of the EEOC</a> social change within the association. Holding coaching in racial mindfulness and conducting climate outlines are the keys to advancing in establishing or correcting current multicultural and racial mindfulness coaching programs.</p>
                        <h2><strong>References</strong></h2>
                        <p>U.S. Equal Employment Opportunity Commission. (n.d.). <em>About the EEOC</em>. Retrieved from <a href="https://www.eeoc.gov/about-eeoc">https://www.eeoc.gov/about-eeoc</a></p>
                        <p>U.S. Equal Employment Opportunity Commission. (n.d.). <em>Eliminating Barriers in Recruitment and Hiring</em>. Retrieved from <a href="https://www.eeoc.gov/initiatives/compensation-discrimination">https://www.eeoc.gov/initiatives/compensation-discrimination</a></p>

                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Soc315week4