import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Nur600module3assignment = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>NUR 600 Module 3 Assignment Draft of Project</title>
                <meta name='description'
                    content='Gain valuable knowledge with NUR-600 Module 3 Assignment Draft of Project. Enhance your skills—start learning today!' />
                <meta name='keywords' content='NUR 600 Module 3 Assignment Draft of Project' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>NUR 600 Module 3 Assignment < br /><span>Draft of Project</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Nur600module3assignment.webp" alt="NUR 600 Module 3 Assignment Draft of Project" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><strong><em>NUR 600 Module 3 Assignment Draft of Project.</em></strong></li>
                        </ul>
                        <div>
                            <div>
                                <div>
                                    <h2>Slide 1: Title Slide Title:</h2>
                                    <p>PowerPoint Presentation of a Medication.</p>
                                    <p>SubTitle: Pharmacology of Ibuprofen.</p>
                                    <p>Presented by: Elcio Roque.</p>
                                    <p>Course: NUR-600. Date: May, 2024.</p>
                                    <h2>Slide 2: Introduction</h2>
                                    <h3>A Brief Introduction to Ibuprofen</h3>
                                    <p>The presentation theme consists of the following parts:</p>
                                    <p>1. Pharmacology</p>
                                    <p>2. Classification</p>
                                    <p>3. Dosage forms</p>
                                    <p>4. Indications</p>
                                    <p>5.Adverse effects</p>
                                    <p>6. Warnings</p>
                                    <p>7. Safety in pregnancy</p>
                                    <p>8. Cost Factors</p>
                                    <p>9. Case analysis</p>
                                    <p>10. Patient Instruction</p>
                                    <p>11. Nurse Practitioner Responsibilities</p>
                                    <p>12. Evaluation and Reporting</p>
                                    <h2>Slide 3: Drug Pharmacology and Pharmacokinetics</h2>
                                    <h3>Pharmacology:</h3>
                                    <p>NSAID is a mechanisim of action COX 1 AND 2 Prostaglandin Production Blocking</p>
                                    <h3>Pharmacokinetics:</h3>
                                    <p>The way it enters the body and the primary area of blood concentration Binding to plasma proteins and liver metabolism Excretion</p>
                                    <h2>Slide 4: Brand Name And Generic Name</h2>
                                    <h3>Brand Names:</h3>
                                    <p>Advil Nurofen Motrin</p>
                                    <h3>Generic Name:</h3>
                                    <p>Ibuprofen</p>
                                    <h2>Slide 5: Dosing Guidelines</h2>
                                    <h3>Adult Dose:</h3>
                                    <p>200 to 800 mg every 6-8 hours with the maximum dosage at 3200mg per day.</p>
                                    <h3>Pediatric dose:</h3>
                                    <p>10mg per kilogram of weight every 6-8 hours</p>
                                    <h2>Slide 6: Indications For Use Conditions</h2>
                                    <h3>Treated:</h3>
                                    <ul>
                                        <li>Mild to moderate painful discomforts.</li>
                                        <li>Inflammatory arthritis.</li>
                                        <li>Elevated body temperature.</li>
                                    </ul>
                                    <h2>Slide 7: Side Effects Nausea.</h2>
                                    <ul>
                                        <li>Vomiting.</li>
                                        <li>Dyspepsia.</li>
                                        <li>Dizziness.</li>
                                        <li>Headaches.</li>
                                    </ul>
                                    <h3>Some of the worst side effects are:</h3>
                                    <ul>
                                        <li>Gastrointestinal bleeding.</li>
                                        <li>Kidney.</li>
                                        <li>Increase the rate Heart attack or stroke</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <div>
                                    <h2>Slide 8: Contraindications</h2>
                                    <h3>Conditions:</h3>
                                    <ul>
                                        <li>Severe kidney and liver failure.</li>
                                        <li>Gastrointestinal draining or ulcers.</li>
                                        <li>Known outstanding intolerance to ibuprofen or other NSAIDs.</li>
                                        <li>Medical treatment strategy involving late coronary artery bypass graft (CABG) surgery.</li>
                                    </ul>
                                    <h2>Slide 9: Safety in Pregnancy</h2>
                                    <h3>D Medication Category:</h3>
                                    <p>Always inquire from the patient and the family during late stage of pregnancy regarding usage of drugs that are contraindicated.</p>
                                    <h3><em>NUR-600 Module 3 Assignment Draft of Project</em></h3>
                                    <h2>Slide 10: Cost Analysis of the Drug Generic</h2>
                                    <h3>ibuprofen:</h3>
                                    <p>10-20 dollars for one hundred to two hundred milligram per dosage tablet.</p>
                                    <h3>Brand Names:</h3>
                                    <p>Purchases have been made at a higher cost compared to the generic interpretations.</p>
                                    <h2>Slide 11: Patient Case Study</h2>
                                    <h3>Case Study:</h3>
                                    <p>Ms. Jane Doe is a 45 years old female aged osteoarthritis.</p>
                                    <p><strong> Recommendation:</strong></p>
                                    <p>Ibuprofen after leaving Acetaminophen 500 mg tablet behind.</p>
                                    <strong>Prescription:</strong>
                                    <p>400 mg was given six to eight hourly, two thousand four hundred mg a day maximum.</p>
                                    <p>Proof Based Investigations: Osteoarthritis patients are found to suffer great pain and have severely little movement.</p>
                                    <h2>Slide 12: Patient Education Dosage increase effects of ibuprofen:</h2>
                                    <h3>Instructions:</h3>
                                    <ul>
                                        <li>Prescribe with meals usage in patients suffering discomfort to the gastrointestinal tract.</li>
                                        <li>Counsel the patient the possible side effects.</li>
                                        <li>Do not mix with other NSAIDs without the doctor&rsquo;s consent.</li>
                                    </ul>
                                    <h2>Slide 13: Responsibilities of a Nurse Practitioner</h2>
                                    <h3>Outlined These responsibilities are as follows:</h3>
                                    <ul>
                                        <li>Study the patient&rsquo;s/hospital case history and focus on complaints regarding pain.</li>
                                        <li>Changes Made Rationally:</li>
                                        <li>Justification for ibuprofen signatories and instructions on its use.</li>
                                        <li>Describe how an examination on the patient&rsquo;s condition is to be conducted.</li>
                                        <li>Adjust treatment as you feel is appropriate, balancing any concerns overthe effect of those changes.</li>
                                    </ul>
                                    <h2>Slide 14: Final Remarks</h2>
                                    <h3>Advantages and Limitations of Ibuprofen</h3>
                                    <p>Ibuprofen is a famous over-the-counter analgesic and antipyretic medicine used for various types of pain, inflammation, and fever. It can be used for muscle pain, headaches, arthritis, menstrual cramps, and many other ailments. Moreover, because there are no restrictions on buying it, patients with immediate needs can purchase it without prescriptions.</p>
                                    <p>But these functionalities come with certain drawbacks. Using ibuprofen for too long, or using it too often leads to greater chances of cardiovascular event. It also increases the risk of healthcare issues like kidney problems, stomach bleeding, and ulcers. People who have kidney issues, stomach ulcers, heart disease, and other problems should take ibuprofen in moderation and with guidance from their physicians.</p>
                                    <p>As outlined in NUR-600 Module 3 Assignment Draft of Project, protecting the patient during hospitalization and after discharging them with the Implanted Port requires diligent patient follow-up for side effects. Failing to see them results in worsening of symptoms or exposing the patient to unnecessary healthcare problems.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                {/* <p>We provide excellent Capella University BSN FlexPath assessment solutions for students in their Capella BSN FlexPath Courses.</p> */}
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )
}

export default Nur600module3assignment
