import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const N600assignment7a = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>NURS 600 Assignment 7.2 Theory Project</title>
                <meta name='description'
                    content="Read the NURS 600 Assignment 7.2 Theory Project and gain insights into behavioral health theories by Dorsey and Murdaugh. Click to learn more!" />
                <meta name='keywords' content='NURS 600 Assignment 7.2 Theory Project' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>NURS 600 Assignment 7.2 Theory Project < br /><span>Post-Theory Presentation on Kaltura</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Nurs600assignment7_2.webp" alt="NURS 600 Assignment 7.2 Theory Project Post-Theory Presentation on Kaltura" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><em><strong>NURS 600 Assignment 7.2 Theory Project Post-Theory Presentation on Kaltura.</strong></em></li>
                        </ul>
                        <h2>Slide 2</h2>
                        <h2>Introduction</h2>
                        <p>Dorsey and Murdaugh are famous for providing special input in social theories in nursing, which advocate for behavioural health and patient autonomy (Jean-Baptiste, 2022). These facts feature the adequacy of their work toward improving the quality of nursing care and addressing health behaviour changes based on contextual factors within the social and cultural plan. They were picked for this undertaking because their theories pertain to the new concerns of nursing practice and its provisioning of Patient-centered care and health optimization through behavioral.</p>
                        <h2>Slide 3</h2>
                        <h2>Biographical Information</h2>
                        <h3>Dorsey</h3>
                        <p>Dorsey has several years of formal nursing and general health education, which has resulted in acquiring advanced degrees from reliable institutions. She has worked clinically and academically in Multiple or two decades of practice (Froncek, 2020). Dorsey specializes in behaviour, and her primary focus is the creation of strategies for change at the behavioural level. Her major achievements include writing leading books and articles, being awarded for her research in nursing science, and mentoring new scholars in nursing.</p>
                        <h3>Murdaugh</h3>
                        <p>There is also proof of Murdaugh's formal education, which involved completing a master's and a doctorate in nursing, specializing in health promotion and patient education (Froncek, 2020). He has adequate professional involvement with his practice and the valuable chance to work in clinical practice and as a medical caretaker educator.</p>
                        <ul>
                            <li>
                                <h3>Patient-Centered Health Promotion</h3>
                            </li>
                        </ul>
                        <p>His work is generally popular for its application of patient patient-centred and support frameworks for health promotion. These include the Assertiveness Training Program for medical caretakers, their contribution to a portion of the centres on nursing theory, and recognition by nursing organizations for her pioneering work that focused on increasing patients' involvement and their predominant status.</p>
                        <h2>Slide 4</h2>
                        <h2>Rationale for Selection</h2>
                        <p>The two theories proposed by Dorsey and Murdaugh are generally valuable in introducing nursing practice since they feature social factors and health behaviours essential in caring for patients and crafting appropriate nursing interventions (Edwards, 2022). Through their work, they assist in explaining and effecting health behaviour change, which is important for the medical caretaker to deal with the various necessities of individuals of different populations.</p>
                        <p>Through aspects, for example, patient activation and the business occupation of social support, these theories offer an ideal foundation for strengthening patient-centred care while advancing health results, which are crucial in addressing current nursing issues. These insights were effectively highlighted during the NURS 600 Assignment 7.2 Theory Project Post-Theory Presentation on Kaltura, emphasizing the importance of integrating theoretical frameworks into practical nursing interventions.</p>
                        <h2>Slide 5</h2>
                        <h2>Overview of Theories</h2>
                        <h3>Scope of Theory</h3>
                        <p>Dorsey and Murdaugh's theories primarily relate behavioural health and the advancement of this area of practice by stressing how medical caretakers need to grasp and play a task in altering the health behaviours of their patients (Alcera, 2022). Their work emphasizes the importance of a behavioural approach in preventing and managing chronic diseases, promoting a more active and strong occupation of healthcare organizations to educate and motivate individuals regarding their behaviour to advance health and conform to supported medical regimens.</p>
                        <h3>School of Thought</h3>
                        <p>The two scholars fall under the paradigm that articulates the environment in social and cultural contexts. They understand the personalized factors of a patient's environment and cultural practices that influence their behaviours and health consequences (Alcera, 2022).</p>
                        <h3><em><strong>NURS 600 Assignment 7.2 Theory Project Post-Theory Presentation on Kaltura</strong></em></h3>
                        <p>Consequently, acknowledging these facts, Dorsey and Murdaugh propose developing multipronged health promotion approaches that will not focus essentially on behaviours but will also include social-context determinants.</p>
                        <h3>Central Concepts</h3>
                        <p>Self-efficacy, health beliefs, and social support form the focal point of the ongoing conceptual framework as placed by Dorsey and Murdaugh. Self-efficacy is only how much a patient accepts that he can perform a couple of actions to enhance or advance health. Health beliefs can be defined as the ideas and perceptions individuals hold regarding health matters (Alcera, 2022). Self-organizing social support alludes to the interpersonal plans that proffer emotional, informational, and tangible support that should continue healthy behaviours.</p>
                        <h3>Importance of Patient Empowerment</h3>
                        <p>Dorsey and Murdaugh also feature the vital occupation of including the patient and supporting patient-situated communication and engagement in their care (Alcera, 2022). Engaging patients with active participation as decision-makers can cultivate health and enable equal partnership with the healthcare specialist's focus. Their theories emphasize the idea of patients as players in the healthcare process, implying that their opinions and decisions should be considered and integrated into care management.</p>
                        <h2>Slide 6</h2>
                        <h2>Contributions to Nursing/Healthcare</h2>
                        <h3>Impact on Nursing</h3>
                        <p>Dorsey and Murdaugh have made a big difference in the nursing profession and healthcare in general by implementing the concepts of behavioural health. They have helped raise awareness of how various social, cultural, and psychological factors come into play in defining healthy behaviours (Buck, 2024).</p>
                        <ul>
                            <li>
                                <h3>Shifting the Nursing Care Paradigm</h3>
                            </li>
                        </ul>
                        <p>This has led to medical caretakers getting involved in forming appropriate care strategies that fit patient needs in the health sector, leading to improved human health. Because of the emphasis on the mental and behavioural dimensions of health promotion and the nature of past patient-centred care, both Dorsey's and Murdaugh's theories have changed the paradigm of nursing.</p>
                        <h3>Key Publications</h3>
                        <h4>Promoting Health Behavior Change: A Comprehensive Guide</h4>
                        <p>This is a very important publication for medical attendants and health providers, as it includes deep insights together with concrete strategies on how to change patients' positive health behaviours (et al., 2020). Those are motivational interviewing and behavioral interventions; most of the issues are supported by theories from Dorsey and Murdaugh to offer us practical guidance.</p>
                        <h4>Understanding Health Beliefs: Foundations for Patient-Centered Care</h4>
                        <p>Specifically, in this book, Dorsey and Murdaugh examine patients' ideas about their health and what these ideas mean for their actions (Grover et al., 2021). This appropriate care can be given to the patient by understanding these beliefs, which advance rather than provide viablnursing care.</p>
                        <h4>Social Support and Health: Strategies for Nursing Practice</h4>
                        <p>This work epitomizes social support as one important intervention in health promotion and disease intervention (Lee &amp; Gracious, 2020). It sets out best practices that medical attendants can utilize to bring into practice social support frameworks, showing how these frameworks can facilitate patients' compliance with therapy and achieve great health.</p>
                        <h2>Slide 7</h2>
                        <h2>Theory-Practice Connection/Application</h2>
                        <h3>Examples in Healthcare</h3>
                        <p>Various healthcare programs have used these theories to facilitate behavioural changes at Dorsey and Murdaugh. For example, while working with smokers, helping them to stop, attendants apply strategies based on their theories to advance motivation, support self-viability, and give encouragement (Orsal &amp; Ergun, 2021).</p>
                        <h3><em><strong>NURS 600 Assignment 7.2 Theory Project Post-Theory Presentation on Kaltura</strong></em></h3>
                        <p>Accordingly, while encouraging physical activity among their patients, the medical caretakers also use behavioural interventions that focus on obstacles to practice while at the same time helping patients to include friends and family in supporting the new healthy ways of life for the long term. Such programs depict how conceptual models can be operationalized into real-world strategies instrumental in altering health behaviours and enhancing patient advantage.</p>
                        <h3>Application in Nursing Care Settings</h3>
                        <p>Dorsey and Murdaugh's theories explicate patients' perceptions of chronic diseases and self-efficacy in nursing care settings. Medical caretakers use their theories to assess how patients feel or think about their states of health, beliefs concerning the adequacy of treatments, and levels of self-efficacy in managing their health (Orsal &amp; Ergun, 2021).</p>
                        <p>Such perceptions can be valuable for medical attendants as they allow patients' education and support for patient engagement and treatment compliance to be customized accordingly. Besides, using theories of nursing supported by proof, medical caretakers teach patients the information and abilities necessary to become involved in their decision-making process, enhance their level of independence, and consequently affect positive results on their health.</p>
                        <h3>Importance of Patient-Centered Care</h3>
                        <p>Both Dorsey and Murdaugh based their theories on patient-centered care that aims at bringing patients' beliefs and tendencies to a higher level for the enhancement of care. Nurses should apply the principles of the first person in planning care and making care decisions; thus, the plans set by them are agreeable to the patients (Orsal &amp; Ergun, 2021).<br /><br />This approach considerably strengthens patients' satisfaction and assures adherence to supported treatment plans and medical management. Relationships studied in these theories clinically shift the focus away from provider dominance to patient dominance or interaction and chip at the quality of care and proficiency of nursing administrations.</p>
                        <h2>Slide 8</h2>
                        <h2>Relationship between Dorsey and Murdaugh</h2>
                        <h3>Common Themes</h3>
                        <p>When the two theories are compared, it is evident that Dorsey and Murdaugh have in common health's social and cultural explanatory factors (Faan et al., 2023). The two scholars understand that human behaviours that concern health are conditioned by culture, economic and social status, and different environments.</p>
                        <p>Therefore, Dorsey and Murdaugh's theories see the impact of these factors to emphasize that understanding and combating the social determinants of health is crucial for promoting positive health behaviours and flourishing among the population. The two disciplines converge on elucidating such interactions, enhancing the nursing practice based on a broader viewpoint on patient care.</p>
                        <h3>Shared Focus on Patient Empowerment and Behavioral Health</h3>
                        <p>The two theories postulated by Murdaugh and Dorsey have a lot of focus on patient management and behavioral health. Dorsey concentrates on patient self-efficacy, which pays attention to patients' perceived abilities to protect, promote, and attain health and well-being. Murdaugh dwells on health Beliefs and social support systems (Faan et al., 2023).<br /><br />The two theories propose an early intervention strategy that focuses on patient-centred care and an organizational model of the patient-physician relationship that supports patients as active participants responsible for making their own decisions to improve their health.</p>
                        <h3>Complementary Contributions</h3>
                        <p>Focusing on self-efficacy, Dorsey finds an ideal collaboration with Murdaugh's emphasis on health beliefs and social support to give a balanced approach to understanding patients' behaviour. Based on the Self-efficacy theory, healthier behaviour is normal for individuals with confidence in attaining the ideal health goals (Faan et al., 2023).</p>
                        <ul>
                            <li>
                                <h3>Comprehensive Patient Care Framework</h3>
                            </li>
                        </ul>
                        <p>Murdaugh's theories expand on this by examining health beliefs and social support regarding these acts. Altogether, this collaboration gives a full-inclusive paradigm of understanding patient-centred care, where more attention is paid to each patient's motivation and the reinforcement frameworks expected to facilitate long-term behaviour change in the patients.</p>
                        <h3>Collaborative Influence</h3>
                        <p>Both Dorsey and Murdaugh's theories work in congruency to support nursing practice by offering an organized approach to care that entails psychological, social, and environmental components. Empirical recommendations on patient self-management support, behavioural care, and the influence of the social factors tracked down in their work assist the attendants with formulating theoretically informed strategies regarding clients denied nursing care from various cultural backgrounds (Faan et al., 2023).</p>
                        <p>Nursing care shared in collaboration with medical approaches becomes a meeting ground to negotiate the feasibility of practice and integrate the paradigm of health and disease-embracing caring that is both restrained and eccentric to attain optimum health potential.</p>
                        <h2>Slide 9</h2>
                        <h2>Critique of Theories</h2>
                        <h3>Strengths</h3>
                        <p>Dorsey and Murdaugh's theories provide one of the most all-encompassing frameworks for understanding behavioural health and barriers and facilitators to health behaviours at the individual, relationship, and local area levels (Faan et al., 2023). The two frameworks equip the medical caretaker with information on why the patient behaves in a certain manner and how this behaviour can be changed for the patient's advantage.</p>
                        <h3><em><strong>NURS 600 Assignment 7.2 Theory Project Post-Theory Presentation on Kaltura</strong></em></h3>
                        <p>In this manner, the two theories offer healthcare professionals strong and valuable instruments to conceive and realize targeted interventions depending on patients' necessities in varied healthcare contexts. Therefore, they elaborate on nursing practice as they advance a significantly more all-encompassing care approach and, consequently, a prevalent quality of life for patients.</p>
                        <h3>Limitations</h3>
                        <p>Nonetheless, there could be several problems with putting the theories pointed out by Dorsey and Murdaugh into clinical practice. Of course, there is also an issue of consistency in patients' responses to behavioural interventions, which may be culturally, economically, or psychologically conditioned to a great extent or otherwise (Faan et al., 2023). Moreover, although their theories have been applied in various contexts, mostly in the medical setting, there is a need to test them empirically with several types of populations and in a variety of conditions.</p>
                        <p>This involves determining how their instruments can be adjusted to address the necessities of various patient populations and analyzing factors that affect the compelling conveyance of behavioural health interventions. Overcoming these limitations through continued investigation and utilizing empirically determined information will only support the general and express validity of Dorsey and Murdaugh's concepts in optimizing positions for delivering predominant nursing administrations and advancing the popularity of health around the world.</p>
                        <h2>Slide 10</h2>
                        <h2>Conclusion</h2>
                        <p>In conclusion, special attention was paid to the work of Dorsey and Murdaugh, as their theories can assist with developing nursing science and practice by offering a strong theoretical model for enhancing health behaviour. Their ideas of self-efficacy, perception of health, and supportive assets have influenced the nursing practice by emphasizing patient autonomy and comprehensive well-being paradigms.</p>
                        <p>Their work clearly shows that behaviour is determinedly linked to social and cultural standards. The ongoing collection of information benefits from this integration, enhancing the nursing profession's performance in caring for patients with remarkable necessities. These perspectives were effectively analyzed and presented in the NURS 600 Assignment 7.2 Theory Project Post-Theory Presentation on Kaltura, showcasing how theoretical frameworks like those of Dorsey and Murdaugh contribute to evolving nursing practices and improving patient outcomes.</p>
                        <h2>Slide 11</h2>
                        <h2>References</h2>
                        <p>Alcera, R. L. (2022). Health, wellness practices, and work performance variables of employees in the University of Eastern Philippines system. <em>Journal of Positive School Psychology</em>, <em>6</em>(7), 993&ndash;1011993&ndash;1011. <a href="https://journalppw.com/index.php/jpsp/article/view/11253">https://journalppw.com/index.php/jpsp/article/view/11253</a></p>
                        <p>Buck, M. (2024). Care of the critically ill non-trauma patient: Improving emergency department readiness and patient care through the implementation of a dedicated resuscitation bay. <em>Doctor of Nursing Practice Final Manuscripts</em>. <a href="https://doi.org/10.22371/07.2024.009">https://doi.org/10.22371/07.2024.009</a></p>
                        <p>Edwards, D. (2022, November 1). A critical commentary that addresses the needs of vulnerable populations in healthcare: Evidence from mixed methods systematic reviews. Orca. Cardiff. Cardiff<a href="https://orca.cardiff.ac.uk/id/eprint/154191/">https://orca.Cardiff.ac.uk/id/print/154191/</a></p>
                        <p>Faan, M. J. S., PhD, RN, RN, P. R. L., PhD, &amp; CNE, R. D. C., PhD, RN, NE-BC. (2023). Middle range theory for nursing. In <em>Google Books</em>. Springer Publishing Company. <a href="https://books.google.com.pk/books?hl=en&amp;lr=&amp;id=QvN8EAAAQBAJ&amp;oi=fnd&amp;pg=PA381&amp;dq=it+is+evident+that+Dorsey+and+Murdaugh+share+the+social+and+cultural+explanatory+factors+of+health&amp;ots=gOH2hDZGJA&amp;sig=FV0KCnLoUuDoXUcBEh_gMjeLSok&amp;redir_esc=y#v=onepage&amp;q&amp;f=false">https://books.google.com.pk/books?hl=en&amp;lr=&amp;id=QvN8EAAAQBAJ&amp;oi=fnd&amp;pg=PA381&amp;dq=it+is+evident+that+Dorsey+and+Murdaugh+share+the+social+and+cultural+explanatory+factors+of+health&amp;ots=gOH2hDZGJA&amp;sig=FV0KCnLoUuDoXUcBEh_gMjeLSok&amp;redir_esc=y#v=onepage&amp;q&amp;f=false</a></p>
                        <p>Froncek, J. (2020). Incarcerated adults&rsquo; perceptions of remaining opiate opiate-release. <em>Regis University Student Publications (Comprehensive Collection)</em>. <a href="https://epublications.regis.edu/theses/986/">https://epublications.regis.edu/theses/986/</a></p>
                        <p>Grover, S., Fitzpatrick, A., Azim, F. T., Ariza-Vega, P., Bellwood, P., Burns, J., Burton, E., Fleig, L., Clemson, L., Hoppmann, C. A., Madden, K. M., Price, M., Langford, D., &amp; Ashe, M. C. (2021). Defining and implementing patient-centred care: An umbrella review. <em>Patient Education and Counseling</em>, <em>105</em>(7). <a href="https://doi.org/10.1016/j.pec.2021.11.004">https://doi.org/10.1016/j.pec.2021.11.004</a></p>
                        <p>Hagger, M. S., Cameron, L. D., Hamilton, K., Hankonen, N., &amp; Lintunen, T. (2020). The handbook of behavior change. In <em>Google Books</em>. Cambridge University Press. <a href="https://books.google.com.pk/books?hl=en&amp;lr=&amp;id=IfEFEAAAQBAJ&amp;oi=fnd&amp;pg=PP1&amp;dq=Promoting+Health+Behavior+Change:+A+Comprehensive+Guide&amp;ots=XFDY-1wb6V&amp;sig=FmcdPTKalORpf2T6l_7iUtfugJ8&amp;redir_esc=y#v=onepage&amp;q=Promoting%20Health%20Behavior%20Change%3A%20A%20Comprehensive%20Guide&amp;f=false">https://books.google.com.pk/books?hl=en&amp;lr=&amp;id=IfEFEAAAQBAJ&amp;oi=fnd&amp;pg=PP1&amp;dq=Promoting+Health+Behavior+Change:+A+Comprehensive+Guide&amp;ots=XFDY-1wb6V&amp;sig=FmcdPTKalORpf2T6l_7iUtfugJ8&amp;redir_esc=y#v=onepage&amp;q=Promoting%20Health%20Behavior%20Change%3A%20A%20Comprehensive%20Guide&amp;f=false</a></p>
                        <p>Jean-Baptiste, D. M. (2022). Individuals with sickle cell disease using SBAR as a communication tool: Secondary data analysis. <em>Repository.escholarship.umassmed.edu</em>. <a href="https://doi.org/10.13028/kyap-9c16">https://doi.org/10.13028/kyap-9c16</a></p>
                        <p>Lee, M. K., &amp; Oh, J. (2020). Health-related quality of life in older adults: Its association with health literacy, self-efficacy, social support, and health-promoting behaviour. <em>Healthcare</em>, <em>8</em>(4), 407. <a href="https://doi.org/10.3390/healthcare8040407">https://doi.org/10.3390/healthcare8040407</a></p>
                        <p>Orsal, O., &amp; Ergun, A. (2021). The effect of peer education on decision-making, smoking-promoting factors, self-efficacy, addiction, and behaviour change in the process of quitting smoking of young people. <em>Risk Management and Healthcare Policy</em>, <em>Volume 14</em>, 925&ndash;945. <a href="https://doi.org/10.2147/rmhp.s280393">https://doi.org/10.2147/rmhp.s280393</a></p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default N600assignment7a