import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Nursfpx8002assessment3 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>NURS FPX 8002 Assessment 3 Personal Leadership</title>
                <meta name='description'
                    content="Explore NURS FPX 8002 Assessment 3 Personal Leadership Portrait. Learn leadership styles, personal growth, and practical healthcare tips. Read now!" />
                <meta name='keywords' content='NURS FPX 8002 Assessment 3 Personal Leadership' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>NURS FPX 8002 Assessment 3  < br /><span>Personal Leadership Portrait</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Nursfpx8002assessment3.webp" alt="NURS FPX 8002 Assessment 3 Personal Leadership Portrait" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><strong><em><span data-preserver-spaces="true">NURS FPX 8002 Assessment 3 Personal Leadership Portrait.</span></em></strong></li>
                        </ul>
                        <h2><span data-preserver-spaces="true">Personal Leadership Portrait</span></h2>
                        <p><span data-preserver-spaces="true">A portrait of a dispatch in healthcare can come in different plans. These plans include alluring, contingency, participative, taught power, indispensable, quality, and groundbreaking approaches. A pioneer will involve their ethical compass to coordinate their qualities as they lead their gathering using the approach that the pioneer is bowed toward.</span></p>
                        <p><span data-preserver-spaces="true">Groundbreaking leadership is what I am inclined toward when taking the outline. Brito et al. (2020) stated, "Implementing groundbreaking leadership in institutions favors relationship with the gathering and subsequently the accomplishment of shared targets." Pioneers use different styles and endeavours to work on the bits of their healthcare field and staff.&nbsp;Explore our assessment&nbsp;</span><a href="https://onlinecourseservices.us/nurs-fpx-8002-assessment-2-demonstrating-effective-leadership" target="_blank"><span data-preserver-spaces="true">NURS FPX 8002 Assessment 2 Demonstrating Effective Leadership</span></a><span data-preserver-spaces="true"> for more information.</span></p>
                        <h2><span data-preserver-spaces="true">Personal Approach to Leadership</span></h2>
                        <p><span data-preserver-spaces="true">A pioneer should decide to work with delegates using firm communication limits. The leadership and the ability to see the Worth in anyone on an extra fundamental level quality that I now have to include groundbreaking leadership, according to Capella's Leadership Self-Assessment. I respect helping clinical caretakers under my leadership exceptionally far in requesting to work at their fullest potential. I show others how it's finished with a committed demeanour and mindset. I'm an encourager constantly, leading with positive advancement instead of negative reminders to my collaborators.</span></p>
                        <p><span data-preserver-spaces="true">Qualities I have as a pioneer include being a determined delegate close to the staff I train. I associate with them in their positive undertakings. When a clinical caretaker commits a goof under my leadership, I guide them carefully in the right direction instead of making the expert feel inadequate. My constraints as a pioneer are ruling with an iron thumb and delegating with the capacity to finish things rapidly.</span></p>
                        <p><span data-preserver-spaces="true">That is a definite point of shortcoming for me. When clinical caretakers backtalk leadership or are not going to play out an errand well, I will generally take the undertaking and do it without anyone's help instead of dealing with Fight. This can create time restraints, especially if this was an undertaking not on my outline in setting on reality it should have been given out.</span></p>
                        <h3><span data-preserver-spaces="true">NURS FPX 8002 Assessment 3 Personal Leadership Portrait</span></h3>
                        <p><span data-preserver-spaces="true">Utilizing the ability to see the Worth in individuals on a massive level in my approach to healthcare leadership is commendatory. I became a clinical caretaker, serving abroad as a nursing collaborator. I respected that I could utilize my shock to examine the room and care for patients who couldn't meet their necessities. Steward (2021) states that the capacity to understand individuals on a fundamental level is "invaluable for nursing managers and pioneers as well as all clinical experts as it assists with providing the focal abilities to coordinate fight with patients, families, and other staff, as well as cultivating a reliable gathering climate."</span></p>
                        <p><span data-preserver-spaces="true">I have an intuitiveness that awards me they see a need, assistance address the issue, and permit the patient to feel respected and cared for. Compared to other predominant leadership styles, I have learning experiences. I have found that my leadership style isn't going to thrive in running a clinic, making delegate cuts, and being outstandingly speedy or outrageous in my communication style. I thrive better in leadership occupations where I'm training and encouraging staff in their levels of cutoff points.</span></p>
                        <ul>
                            <li>
                                <h3><span data-preserver-spaces="true">Leadership Reflection and Improvement</span></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">Regarding leadership, I scored outstandingly, coming up short on my self-assessment. This is because the personality credits are genuine and based on plans. I work with pioneers and directors thriving at that level of cutoff points. I don't disdain their positions or disciplining techniques for grown-up staff clinical caretakers.</span></p>
                        <p><span data-preserver-spaces="true">Leadership styles that I ought to integrate into my well-being repertoire to restore my reasonableness include appealing leadership. Alluring leadership involves being confident and influential. I don't show up clearly, to be specific. As I train staff, they regard my 18 years of nursing experience in my field; however, I'm unassuming about my work level of cutoff points. I love teaching and guiding, yet I won't walk my level of cutoff points, as I feel it is inherent and not About me. Being gutsier would deal with my reasonableness as a pioneer to stunningly direct change in healthcare more quickly.</span></p>
                        <h2><span data-preserver-spaces="true">Interprofessional Communication, Collaboration, and Change Management through Communication Best Practices</span></h2>
                        <p><span data-preserver-spaces="true">My approach to interprofessional relationships with staff and partners inclines toward being encouraging and enticing in groundbreaking leadership. I talk in a kind style that thinks about direct communication. I, in like way, scored high on master leadership, which shows to be OK since I genuinely love serving, listening, and being both splendid and moral. I convey this kindly and purposefully when dealing with management and staff. This construction is often more reasonable than being uncivil and unforgiving, as I reflect on in my NURS FPX 8002 Assessment 3 Personal Leadership Portrait.</span></p>
                        <p><span data-preserver-spaces="true">Credits for interprofessional affiliations include the reality that I seek after the general benefit of the gathering and have a pack mindset for headway. I'm an encourager to all and kind through helping staff spread out and flourish in the working climate. One shortcoming I have is dealing with staff destructiveness and fighting. This has been interminable, with nursing inadequacies and clinical caretaker turnover at an unmatched high. The compromise I am inclined toward is being kind and conscious, yet fresher orderlies are inclined toward independence and financial compensation, which I have nothing to do with.</span></p>
                        <p><span data-preserver-spaces="true">This can be touchy, considering staff stops if their course isn't met immediately. Utilizing check-based writing to help with my working environment would lead to better-determined results and expert outcomes. Current writing can be used to create new updates and practices that address points of weakness in the unit. By improving on these sensitive parts, patients would have higher fulfilment scores.</span></p>
                        <ul>
                            <li>
                                <h3><span data-preserver-spaces="true">Interprofessional Communication and Leadership</span></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">Further, it will energize management and staff's mindset towards their work. When positive outcomes happen using affirmed-based writing, additional things, such as staff and patients, have progressed results. Best practices for interprofessional communication include being kind, insightful, and having participative leadership. This thinks about the region, promoting open, fair discussion and improvement. In meetings, I show others how it's finished and how I convey and coordinate staff. I based my decision on all sides and thought twice about dealing with management and staff regarding their necessities and suppositions for the nursing unit.</span></p>
                        <p><span data-preserver-spaces="true">My communication cutoff points and qualities include those in worker and groundbreaking leadership by being inspirational, moral, and a decent gathering. I talk less and listen more to set up to ensure the staff feels genuinely appreciated and cared for. I love teaching and guiding. I could work more toward prohibitive leadership by speaking more definitively and obviously.</span></p>
                        <h2><span data-preserver-spaces="true">Ethical Leadership in Professional Practice</span></h2>
                        <p><span data-preserver-spaces="true">Pertinent ethical leadership principles guiding my nursing unit include patient/client-driven care. Flite and Harman (2013) define ethical leadership as "the meaning of schooling and thought limit, patient security, information realness and precision, genuineness, sympathy, and commitment to providing quality administrations in professional positions." Most clinical escorts became experts in caring for their local service and meeting their physical and fundamental necessities. While points of viewpoint on specific clinical experts have changed to extra covetous results, I genuinely see that most clinical escorts sincerely need to care for patients.</span></p>
                        <p><span data-preserver-spaces="true">Unfortunately, The coronavirus has changed different things financially about nursing, creating nursing inadequacies and cash-hungry clinical caretakers. Keeping patients at the point of relationship of our nursing care through using based investigation to best care for our patients will permit experts to practice the ethical commitment we guaranteed when we became clinical caretakers. The ANA Set of rules for Clinical caretakers plays a tremendous part in my professional moral compass.</span></p>
                        <h3><span data-preserver-spaces="true">NURS FPX 8002 Assessment 3 Personal Leadership Portrait</span></h3>
                        <p><span data-preserver-spaces="true">The main principles include regard, non-evil, respect, obligation, autonomy, responsibility, and veracity. Applying these to energy practice and using showed-based research, it considers expected results with staff and patients. An instance of using the ANA Understood set of rules includes practising Worth, which begins after the nursing staff needs to be on my clinic unit. As a staff support training pioneer clinical caretaker making triple my compensation, our unit was extraordinarily harmful. Short staffing started as clinical caretakers continued toward other more beneficial positions.</span></p>
                        <p><span data-preserver-spaces="true">I practised respect and went to management and accomplices to instruct those with difficulties. Rewards happen and assist clinical caretakers with feeling made up for their work endeavours. A second instance of practising strength includes being kind and firm. I help deliver various children that are not appropriate for life, yet moms can't bear the cost of responsibility administrations. Our clinic, through the push of clinical caretakers, has started yearly assistance for infants who pass for a free expense, allowing moms to have a conclusion on their experience growing up. This unselfish show put on by the escorts in practising comfort helps clinical caretakers and patients.</span></p>
                        <p><span data-preserver-spaces="true">Best practices include having open, fair, integrity-filled discussions with staff. Nursing is infuriating work, and as time goes by, it continues to become more challenging to satisfy the guidelines of upper management and the longings of patients. By having continual direct discussions with upper management about staffing and gear needs, I endeavour to think twice about it. This licenses us to have serious solid areas for gigantic integrity and practice our morals in caring for patients.</span></p>
                        <h2><span data-preserver-spaces="true">Diversity and Inclusion in Health Care Leadership</span></h2>
                        <p><span data-preserver-spaces="true">Diversity and inclusion mean working with individuals from different foundations and social convictions and including the experts in undertakings on that unit floor. Including all agents through diversity and inclusion is considered to be more secure, satisfying, and solid working conditions since workers feel respected in their work environment. Splendor et al. (2021) state, " Regard-minded pioneers will become central in the change cycle and a key to future initiatives that will convey different positive changes in clear-level training and affiliations."</span></p>
                        <p><span data-preserver-spaces="true">Being a pioneer and practising the meaning of diversity and inclusion endorses being a pleasant gathering to staff and evaluating conditions genuinely finish work conditions that include all representatives. A compelling pioneer will maintain a culture/working environment of positive diversity and inclusion by laying out the inspiration for rules and a perceptive mindset. This should happen through intelligent listening to each trained professional and staff in all discussions. ItSettingset academic/preposterous conviction is essential if my convictions change from those of a more trained professional.</span></p>
                        <ul>
                            <li>
                                <h3><span data-preserver-spaces="true">Diversity, Inclusion, and Leadership</span></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">The key nursing morals should be followed as shown in nursing school and finished in the clinic/clinical clinic setting where experts are working. Diversity and inclusion add to health care quality and administration in the area, providing the same ethical care to patients and staff. Incorporating diversity and inclusion in the care of workers will, if all else fails, bring down to inclusive and ethically extraordinary care of patients. If staff is well-controlled and regarded in their work environment, the care of patients will limp along accordingly, considering how healthy the workplace is.</span></p>
                        <p><span data-preserver-spaces="true">A model I assisted with diversity and inclusion was helping to combine male experts in our Work and Improvement unit. As a story on an exceptionally central level made of females, individuals felt limited and faulted for performing a historically "female work." I held staff and personal expert meetings to help connect with energy in the new bearing of Work and Transport. Following a half year of good movement, the male staff on our floor felt more maintained and appreciated. It was a smooth occurrence to see as patients regarded having male and female clinical caretakers.</span></p>
                        <p><span data-preserver-spaces="true">Best practices to determine issues with diversity and inclusion include laying a genuine explanation of communication and regard while hiring one more taught power so their establishment is solid. Continual schooling and progress management are significant for agents to have an overwhelming inclination of well-being and excellence in meetings, as well as helping staff feel that they are being included in their groupings. I share half-yearly meetings with all staff to maintain relationships with the trained professionals and continue getting their examinations. As a pioneer, I ensure the emergency clinic unit progresses with genuine energy toward a healthy floor.</span></p>
                        <h2><span data-preserver-spaces="true">Scholar-Practitioners in Health Care</span></h2>
                        <p><span data-preserver-spaces="true">A scholar-practitioner is a practitioner who is working on a doctorate or has a doctorate, who is scholarly and involves their insight and involvement with a professional practice setting. The meaning of unequivocal thinking as a scholar-professional is integral as a pioneer since working with clinical orderlies, patients, and clinical care, for the most part, requires flexibility, versatility, working through current circumstances, and preparing for what's to come.</span></p>
                        <p><span data-preserver-spaces="true">Scholar-practitioners' influence on healthcare leadership and professional improvement is excellent if their abilities benefit and grow the patients and staffing in a positive positive advancement. I have seen management address the deciding second unit floor with their systems and leadership limits. I ought to be a scholar-professional who inspires staff to do perfect, show-based work that streams down to give fantastic patient care. This way, staff and patients benefit from a thriving, healthy climate that maintains physical and fundamental health.</span></p>
                        <ul>
                            <li>
                                <h3><span data-preserver-spaces="true">Scholar-Practitioners and Healthcare Improvement</span></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">Scholar-practitioners are vast and monstrous by expanding the information base, applying new and existing information, and improving healthcare quality and security. Bloomquist and Georges (2022) express, "Pioneers from different disciplines should have the decision to genuinely figure out together complex issues." As a scholar-practitioner, I love to learn and deal with present-day times concerning my leadership cutoff points and training delegates on my clinic unit. In integrating that into my practice, I trust my staff will continue to bloom in their professional lives since I have inspired positive care from my agents and, as such, their patients.</span></p>
                        <p><span data-preserver-spaces="true">Scholar-practitioners' Worth in Labor and Transport is invaluable; today, there are many changes to work and post-pregnancy. In my different monstrous stretches of work and transport nursing, many updates and enhancements have been made to foster patient results in a manner confirmed based on writing. At the bearing of a chief or educator, our unit has strived to make upgrades yearly. Since I have become the scholar-master in my unit, I have had the craving and inspiration to keep with the current writing and do it into our practice to work on lively results.</span></p>
                        <h2><span data-preserver-spaces="true">Conclusion</span></h2>
                        <p><span data-preserver-spaces="true">In conclusion, healthy leadership is integral to positive progress in healthcare. Groundbreaking leadership is what I use to coordinate the clinical caretakers in my clinic unit. I save a strong locale for a compass of unchanging morals, considering my centre convection design to coordinate my gathering instead of wavering with the ongoing social environment. Using current validated-based Examinations to execute change in inevitable positive progression regarding patient care is a solid area for experts who draw in and spread out a non-toxic working climate that routinely prompts improvement and healthy new turns of events. This is my presumption as a clinical caretaker pioneer who has become a scholar-professional through this program at Capella, reflected in my&nbsp;</span><a href="https://onlineclassservices.com/nurs-fpx8002-assessment-3-personal-leadership-portrait/" target="_blank"><span data-preserver-spaces="true">NURS FPX 8002 Assessment 3 Personal Leadership Portrait</span></a><span data-preserver-spaces="true">.</span></p>
                        <h2><span data-preserver-spaces="true">References</span></h2>
                        <p><span data-preserver-spaces="true">Bloomquist, C. D., &amp; Georges, L. (2022). INTERDISCIPLINARY LEADERSHIP: A Leadership Development Model for Scholar-Practitioners.&nbsp;</span><em><span data-preserver-spaces="true">Journal of Leadership Education</span></em><span data-preserver-spaces="true">,&nbsp;</span><em><span data-preserver-spaces="true">21</span></em><span data-preserver-spaces="true">(4), 58&ndash;75.&nbsp;</span><a href="https://doi.org/10.12806/V21/I4/A4" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.12806/V21/I4/A4</span></a></p>
                        <p><span data-preserver-spaces="true">Brito Ferreira, V., Coelho Amestoy, S., Reis da Silva, G. T., de Lima Trindade, L., Reis dos Santos, I. A., &amp; Alves Galhardo Varanda, P. (2020). Transformational leadership in nursing practice: challenges and strategies.&nbsp;</span><em><span data-preserver-spaces="true">Revista Brasileira de Enfermagem</span></em><span data-preserver-spaces="true">,&nbsp;</span><em><span data-preserver-spaces="true">73</span></em><span data-preserver-spaces="true">, 1&ndash;7.&nbsp;</span><a href="https://doi.org/10.1590/0034-7167-2019-0364" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.1590/0034-7167-2019-0364</span></a></p>
                        <p><span data-preserver-spaces="true">Butler, J. (2021). Emotional Intelligence in Nursing Leadership.&nbsp;</span><em><span data-preserver-spaces="true">Australian Nursing &amp; Midwifery Journal</span></em><span data-preserver-spaces="true">,&nbsp;</span><em><span data-preserver-spaces="true">27</span></em><span data-preserver-spaces="true">(5), 18&ndash;21.</span></p>
                        <p><span data-preserver-spaces="true">Flite, C. A., &amp; Harman, L. B. (2013). Code of Ethics: Principles for Ethical Leadership.&nbsp;</span><em><span data-preserver-spaces="true">Perspectives in Health Information Management</span></em><span data-preserver-spaces="true">, 1&ndash;11.</span></p>
                        <p><span data-preserver-spaces="true">Luster, R., Cooper, H. A., Aikman, G., Sanders, K., Jacobs, G., &amp; Tierney, R. (2021). Relational leadership: Perspectives of key constructs on diversity, inclusion, and social equity in higher education.&nbsp;</span><em><span data-preserver-spaces="true">Journal of Leadership Studies</span></em><span data-preserver-spaces="true">,&nbsp;</span><em><span data-preserver-spaces="true">15</span></em><span data-preserver-spaces="true">(1), 57&ndash;62.&nbsp;</span><a href="https://doi.org/10.1002/jls.21734" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.1002/jls.21734</span></a></p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Nursfpx8002assessment3