import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Soc102topic2 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>SOC 102 Topic 2 Social Stratification</title>
                <meta name='description'
                    content="Explore social hierarchy in SOC 102 Topic 2 Social Stratification. Uncover class structures, inequality, and their impact on society now!" />
                <meta name='keywords' content='SOC 102 Topic 2 Social Stratification' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>SOC 102 Topic 2 < br /><span>Social Stratification</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Soc102topic2.webp" alt="SOC 102 Topic 2 Social Stratification" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><strong><em><span data-preserver-spaces="true">SOC 102 Topic 2 Social Stratification.</span></em></strong></li>
                        </ul>
                        <h2><span data-preserver-spaces="true">Social Stratification Analysis</span></h2>
                        <h3><span data-preserver-spaces="true">Part One:</span></h3>
                        <p><span data-preserver-spaces="true">Social stratification is an arrangement in which parties are segregated, examining power, property, and respect (Larkin, 2015) and making a social moderate plan. Social stratification shifts relying upon the arrangement wherein it is worked. It is a titanic part of the time to ponder the general individual's qualities, such as religion, organization, and underground bug rank. Today, there are four specific stratification structures: bondage, position, middle age, and class. Each improvement contrasts in flexibility and convenience.</span></p>
                        <p><span data-preserver-spaces="true">SOC 102 Topic 2 Social Stratification&nbsp;explores social adaptability, which is the capacity to rise or fall within a social stratification interest (Larkin, 2015) and is subject to whether the stratification structure is open or closed. As per the course book&nbsp;</span><em><span data-preserver-spaces="true">Our Social World: A Prelude to Social Science</span></em><span data-preserver-spaces="true">, portion 7, an open improvement considers progress in status within a general group, while a closed system allows no movement, as seen in subjugation.</span></p>
                        <h3><span data-preserver-spaces="true">Types of Social Hierarchies</span></h3>
                        <p><span data-preserver-spaces="true">As conveyed as of now, each design shifts in the spot of its loved ones. Of the four plans, the first is a framework for oppression. Oppression is the most insane and cumbersome social quality (Larkin, 2015). Inside this construction, an individual can be forced into social and military positions under an individual of high status. As the abused individual is viewed as property and a thing, the "proprietor" is given the alleged right to treat and incorporate the abused persona despite they please. The strategy of misuse comes with additional room for improvement as there is, in each valuable sense, no monstrous admittance to move into a higher position or one of credibility.</span></p>
                        <p><span data-preserver-spaces="true">Next is the station structure, which is most usually utilized in India. This improvement depends on the genetic situation, meaning the social position spread out upon birth. Nonetheless, those brought into the world in a particular station should stay inside that standing. Overall, a cast is a crazy blueprint of limits that individuals should follow. People ought to wed inside their situation and find that blending standings is not permitted in places viewed as pleasurable for their station. This is a plan of restricted convenience.</span></p>
                        <ul>
                            <li>
                                <h3><span data-preserver-spaces="true">SOC 102 Topic 2 Social Stratification</span></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">The middle age structure, other than known as a home framework. It is called this since it sorts out its family, considering how much land every family or person moves. The middle age structure was known as ideal during the Bygone times, commonly through later. It can be depicted when educated authorities, individuals of decency, and rulers ensured scenes and laborers worked and focused on them. The landowner occurred with a more extraordinary and reasonable way of life than the informed authority.</span></p>
                        <p><span data-preserver-spaces="true">Like the standing design, the old advancement could moreover be standard, and the custom of land came through rank or status of birth. Higher positions could soon be acquired as time passed, determined by studies and infuriating work (Larkin, 2015). As the general class structure opened, the outdated framework was pulled out, and the making of the regular led to the farthest uttermost compasses of feudalism. This happens to the class stratification structure.</span></p>
                        <p><span data-preserver-spaces="true">Not equivalent to the dated framework, the class structure positions people by monetary position and flourishing (Larkin, 2015). The class stratification structure had the best degree of social flexibility. Limits are less depicted in any case, but there is still divergence as the class structure is discrete by an unbalanced enlistment for food sources, affiliations, and life choices for individuals relying upon class (lower, concentrate, high) (Domhoff, 2006).</span></p>
                        <p><span data-preserver-spaces="true">In the class stratification structure, birth organizes social and monetary position, which is fantastically affected by family beginning, race, and heading, as well as secret accomplishment and need.</span></p>
                        <h3><span data-preserver-spaces="true">Part Two:</span></h3>
                        <p><span data-preserver-spaces="true">In the eight parts of Our Social Work: A Preface to Humanism, various frameworks, charts, and assessments show the capability differences between class, race, and heading. Generally speaking, there is a vast partition, as should be observable on the outline. Shockingly, men get more pay than ladies, notwithstanding, while at the same time working a similar work. As seen in 2009, men will acquire something like $10,000+ more than ladies, depending upon the level of cutoff.</span></p>
                        <p><span data-preserver-spaces="true">Separating those and Overall planning, the limit between yearly compensation is more than $30,000, and for a Very much educated power, men get reimbursed nearly $60,500 more than ladies.</span></p>
                        <img src="images/Soc102topic2Ia.png" alt="SOC 102 Topic 2 Social Stratification" />
                        <p>Men will dependably get more remuneration than ladies, yet this does not simply go by heading or pay. Men will undoubtedly get the progression of ladies, yet inside the social stratification structure, there is likewise the subject of race, class, and orchestrating. In this following outline, it will be seen that Asians get, by and large, the most money in joined family pay, coming at $64,308, with White non-Hispanics coming nearby at more than $54,500. Powerless' make the least, with Hispanics (any race) coming in second, and all races by and large around familiar in the center between the more significant part and the minority.</p>
                        <img src="images/Soc102topic2Ib.png" alt="SOC 102 Topic 2 Social Stratification" />
                        <ul>
                            <li>
                                <h3><span data-preserver-spaces="true">Social Stratification and Inequality</span></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">From these divulgences, it is shown that inside the social stratification structure, ladies come after men, and minorities come after another race. Whites and Asians hold the highest pay and higher monetary status and stand apart from the Powerless and Hispanics. All around, those with an Overall readiness or higher will get more than $20,000 a year separated from someone with a Discretionary School Affirmation, and expecting that we survey race with heading for benefit, it will all things considered be reasoned that male Asians hold the most power in the class stratification structure and monetary standings.</span></p>
                        <p><span data-preserver-spaces="true">Males will, without even batting an eye, continually get the most money and the higher the degree, the higher their status, and the more they get; taking a gander at the second diagram, Asians as of now earn nearly $10,000 more than one more noteworthy identity in the U.S.</span></p>
                        <p><span data-preserver-spaces="true">The social stratification approach to this nonstop fact of the matter is particularly confusing. In&nbsp;</span><a href="https://www.writinkservices.com/soc-102-topic-2-social-stratification/" target="_blank"><span data-preserver-spaces="true">SOC 102 Topic 2, Social Stratification</span></a><span data-preserver-spaces="true">, social stratification relies upon the country and social worth. The cost and solace of living will depend on the bearing and ethnic and social conditions inside a general group and its economy. The stratification setup will open and separate us by rank, race, class, monetary positions, orchestrating, and heading, and it will depend vigorously on how the country is run.</span></p>
                        <h2><span data-preserver-spaces="true">References</span></h2>
                        <p><span data-preserver-spaces="true">Larkin, T. M. (2015). Sociological Stratification. In Grand Canyon University (Ed.)&nbsp;</span><em><span data-preserver-spaces="true">Our Social World: An Introduction to Sociology.&nbsp;</span></em><span data-preserver-spaces="true">Available from&nbsp;</span><u><a href="https://lc.gcumedia.com/soc102/our-social-world-an-introduction-to-sociology/v1.1/#/chapter/7" target="_blank"><span data-preserver-spaces="true">https://lc.gcumedia.com/soc102/our-social-world-an-introduction-to-sociology/v1.1/#/chapter/7</span></a></u></p>
                        <p><span data-preserver-spaces="true">Larkin, T. M. (2015). U.S. Organizing Principles of Stratification&mdash;Class, Race, and Gender. In Grand Canyon University (Ed.)&nbsp;</span><em><span data-preserver-spaces="true">Our Social World: An Introduction to Sociology.&nbsp;</span></em><span data-preserver-spaces="true">Available from&nbsp;</span><u><a href="https://lc.gcumedia.com/soc102/our-social-world-an-introduction-to-sociology/v1.1/#/chapter/8" target="_blank"><span data-preserver-spaces="true">https://lc.gcumedia.com/soc102/our-social-world-an-introduction-to-sociology/v1.1/#/chapter/8</span></a></u></p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Soc102topic2