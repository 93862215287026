import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Nursfpx6107assessment2 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>NURS FPX 6107 Assessment 2 Course Development and Influencing Factors</title>
                <meta name='description'
                    content="Understand NURS FPX 6107 Assessment 2 Course Development and Influencing Factors. Discover curriculum building, caregiver care, and more. Click to learn!" />
                <meta name='keywords' content='NURS FPX 6107 Assessment 2 Course Development and Influencing Factors' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>NURS FPX 6107 Assessment 2  < br /><span>Course Development and Influencing Factors</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Nursfpx6107assessment2.webp" alt="NURS FPX 6107 Assessment 2 Course Development and Influencing Factors" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><strong><em><span data-preserver-spaces="true">NURS FPX 6107 Assessment 2 Course Development and Influencing Factors.</span></em></strong></li>
                        </ul>
                        <h2><span data-preserver-spaces="true">Course Development and Influencing Factors</span></h2>
                        <p><span data-preserver-spaces="true">The curriculum plan process was conventionally something that everyday presence would make and control; notwithstanding, as the instructive world re-appears rapid progress, other tremendous factors are being thought about in curriculum development (Boland, 2017).</span></p>
                        <p><span data-preserver-spaces="true">In nursing, the circumstances that a clinical regulator should, from an overall perspective, believe in during a shift have helped with formulating how these individuals check out at the nursing curriculum. It is pivoted toward what information students will leave the program with and what limits they genuinely need to take straightforwardly into the workforce which workforce, with part of the manner that nursing curriculums are facilitated, added to, or changed.</span></p>
                        <h2><span data-preserver-spaces="true">Carin, for the Caregiver</span></h2>
                        <p><span data-preserver-spaces="true">In the oncology setting, orchestrating has a monster impact on the outing. Not only are staff individuals educating patients. However, staff are strongly being shown new subjects in the oncology field, new meds, their inspirations, and understanding endpoints. In the curriculum that clinical advantages experts should participate in during their heading as new experts in oncology at UPMC, some central courses and centers are worked into the program to set up the staff to begin their positions. The oncology field is a chafing field not shown here in the cerebrum from beginning to end, so the curriculum these courses make is crucial for setting up staff.</span></p>
                        <p><span data-preserver-spaces="true">In the&nbsp;NURS FPX 6107 Assessment 2 Course Development and Influencing Factors, an additional course could be added to this curriculum that would be incredibly beneficial to new staff:&nbsp;Caring for the Caregiver. According to common practice, clinical guards show compassion and sympathy to their patients and relatives. However, when a patient and their family receive a serious diagnosis, it is difficult for both the patient to process and for the loved ones who will become the caregivers.</span></p>
                        <ul>
                            <li>
                                <h3><span data-preserver-spaces="true">Role of Caregivers in Treatment</span></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">In the US alone in 2019, there was a Recorded 1,752,735 at this point hanging out there to have compromising development, and most will have something like one Caregiver pulled in with their journey (Spots for Persuading Suspicion and Avoidance, 2022). A caregiver can be any person helping the patient through their trip without being paid. This can incorporate mates, guards, grown-up kids, dear accomplices, and neighbors.</span></p>
                        <p><span data-preserver-spaces="true">These people could assist with valuing, making dues, getting food/supplies, washing, administering holds, giving transportation, or even providing key help (American Ailment Society, 2019). These people significantly impact the patient's treatment as the patients are with us for a limited amount of time, disengaged from the time spent at home.</span></p>
                        <h3><span data-preserver-spaces="true">The Course Outline1</span></h3>
                        <p><span data-preserver-spaces="true">The presentation of this course in the curriculum is ideal when it is put around the fulfillment of the curriculum. The current curriculum includes courses that outline what contamination is, treatment choices, and yielded results that patients can be informed of. This is all gigantic information that would talk with what the caregivers will direct while offering help to the patients. These courses make sense of what is, for the most part, expected to treat the centers they are examining, so by including this course that sorts out who is caring for the patients when the staff is not, it can permit the staff to see a more wholistic perspective on what a patient's life could resemble and how to help them best.</span></p>
                        <p><span data-preserver-spaces="true">Adding this course to the furthest reaches of the curriculum would permit the expert to proactively find out about the hardship processes of various issues as well as the therapy and caring of the patient. Hence, they have solid locales for and could extensively more whenever rapidly understand why caregivers need the help and organization given in the course to go through the bothering process of being a caregiver to a dangerous developed patient.</span></p>
                        <p><strong><span data-preserver-spaces="true">The course would merge the following with centres:</span></strong></p>
                        <p><span data-preserver-spaces="true">I. Who is viewed as a "caregiver"?</span></p>
                        <p><span data-preserver-spaces="true">II. Caregivers very close process</span></p>
                        <p><span data-preserver-spaces="true">III. How caregivers should focus on themselves (counting, alluding to help, directing themselves, dealing with their family, etc.)</span></p>
                        <p><span data-preserver-spaces="true">IV. Resources for Caregivers (support gatherings, FMLA)</span></p>
                        <p><span data-preserver-spaces="true">This decision of material is the fundamental information that ought to have been organized to start caring for a caregiver. It will walk the staff through how to manage someone as a caregiver. Hence, they understand that someone can, without an astonishing stretch, be relegated to a caregiver and not understand it. In this manner, the course will determine that caregivers, like patients, come to many conclusions with the sickness affirmation. A caregiver could be miserable, shocked, culpable, and forsaken if they do not have assistance and are in a tough spot (Public Turmoil Foundation, 2019). Following this, the course will inspect how caregivers must ensure they are directing themselves.</span></p>
                        <h3><span data-preserver-spaces="true"><strong><em>NURS FPX 6107 Assessment 2 Course Development and Influencing Factors</em></strong></span></h3>
                        <p><span data-preserver-spaces="true">Caregivers ought to understand that it is all right not to have the choice to do all that in separation and that referring to help is something they should not be reluctant to do. They may need help understanding how to work in their families still and in their ordinary presences past being a caregiver. Finally, the course will cover resources that caregivers can use. These may consolidate things, such as support gatherings, assistance with work, FMLA, and even assistance with being a caregiver (i.e., home care). The development of this course will be an enormous aid for these new clinical benefits specialists toward learning and understanding. It will help better care for the patient's caregivers.</span></p>
                        <h2><span data-preserver-spaces="true">Collaboration</span></h2>
                        <p><span data-preserver-spaces="true">Collaboration solidifies anticipating shared targets and responsibilities while the sharing gatherings rapidly assemble and make decisions (Alberto &amp; Herth, 2009). This occurs when various disciplines share solidarity to accomplish a common goal (Alberto &amp; Herth, 2019). Collaboration is an enormous consideration when making or adding to a curriculum for it to continue progressing.</span></p>
                        <p><span data-preserver-spaces="true">This occurs in various ways with both internal and outside partners. These individuals cannot try to be people from the imaginative world, government, clinical benefits, and regions with a particular interest or stake in a situation and who collaborate to pick (Belita, Carter, and Bryant-Lukosius, 2020). Frill obligation in curriculum development is central as these individuals are all around related to the clinical benefits calling and can see the necessities and limits that newly graduated class will expect while entering the workforce (Tiwari, Chan and Rule, 2002).</span></p>
                        <p><span data-preserver-spaces="true">The partners hold different interests and targets in clinical idea reform, which sets out the opportunity for them to constantly give their commitment to curriculum development from a broad level of transcendence to ensure the curriculum has tremendous parts and is not gotten from a lopsided view (Tiwari, Chan and Rule, 2002).</span></p>
                        <h3><span data-preserver-spaces="true">NURS FPX 6107 Assessment 2 Course Development and Influencing Factors</span></h3>
                        <p><span data-preserver-spaces="true">Colleagues are internal and outside people from many positions depending on the thing being managed. In curriculum development, you could have inside relates that are educators, other experts like affiliation, and students (Lindsten, Auvinen, and Juuti, 2019). This frill will affect curriculum development in another way. The teachers give information and considerations as exhibited by an instructor's perspective to ensure that things are being worked into the curriculum that the students will grasp.</span></p>
                        <p><span data-preserver-spaces="true">Additional trained professionals, for instance, people from the trailblazers, who may be office bosses or instructive council people, will endeavor to ensure that the supporting sheets' fundamental standards and nuts and bolts are met. Finally, the students play an essential work in curriculum development. The students assist with giving the learning perspective with siding while at the same time fostering a curriculum. By allowing students to be a part of the development, they can provide information on how students learn and different learning frameworks to achieve the best curriculum plan they need to understand.</span></p>
                        <ul>
                            <li><strong><span data-preserver-spaces="true">External Stakeholders in Curriculum</span></strong></li>
                        </ul>
                        <p><span data-preserver-spaces="true">Close by inward frills, outside extras likewise desire to be a gigantic part of controlling and fostering a curriculum. Outside embellishments could contain individuals from bordering crisis centers, future students, practicing clinicians, well-informed authorities, and neighborhoods (Lindsten, Auvinen, and Juuti, 2019). Individuals from neighborhood centers and before lengthy practicing orderlies will need to give their obligation on what is correct now basic information and limits concerning clinical directors to leave school with, which is vital for ensuring that the students are all set into the workforce following graduation.</span></p>
                        <p><span data-preserver-spaces="true">Future students being allowed to collaborate on the curriculum design can consider them to give information on what they are looking for in a school and another game plan of eyes on the curriculum versus students who might have currently taken part in courses. Finally, clinical idea neighborhoods are outside embellishments, giving input from the area and what is expected for these escorts to make genuine progress experts when they have completed their licenses.</span></p>
                        <h2><span data-preserver-spaces="true">Factors that Affect the Curriculum Development Process</span></h2>
                        <p><span data-preserver-spaces="true">Close partners and their knowledge obliged curriculum development, and other enormous factors impact the process. Cash-related subsidizing can play an immense part in the curriculum development process. Typically, this total is based on the accomplishment rate and without adequate support, NCLEX passing scores of students prompt limited class sizes and a reduction in the selection of students (Reyes, 2017). If many students are bound, this could instigate courses being forgotten about due to staffing needs for the straightforward number of students.</span></p>
                        <p><span data-preserver-spaces="true">Besides staffing and student count, government support also affects connection reimbursement related to gigantic clinical site decisions (Reyes, 2017). The clinical districts used during the tutoring process are essential since they outfit the understudies with the hands-on experiences expected to become helpful trained professionals and license the affiliations used to enlist new specialists who have proactively coordinated in their office, near supporting, statement, and managerial bodies other than expecting a goliath part in curriculum development.</span></p>
                        <ul>
                            <li><strong><span data-preserver-spaces="true">Accreditation and Curriculum Development</span></strong></li>
                        </ul>
                        <p><span data-preserver-spaces="true">Two fundamental permit bodies are the Commission on School Nursing Arranging (CCNE) and the Approval Commission for Coaching in Nursing (ACEN) (Reyes, 2017). These bodies manage the nursing curriculum and attempt to ensure they fulfill the basic standards for current patient thought (Reyes, 2017). Notwithstanding how these bodies screen this, they tell schools how long students should spend in the review vestibule and clinical settings, having the choice to sit for the NCLEX (Reyes, 2017).</span></p>
                        <p><span data-preserver-spaces="true">In the&nbsp;</span><a href="https://onlineclassservices.com/nurs-fpx-6107-assessment-2-course-development-and-influencing-factors/" target="_blank"><span data-preserver-spaces="true">NURS FPX 6107 Assessment 2 Course Development and Influencing Factors</span></a><span data-preserver-spaces="true">, curriculum development is a tangled system that involves many factors. To add the course&nbsp;Caring for Caregivers, the curriculum design should be evaluated through internal and external partners to propose the best course for students to engage in. This new addition will provide new providers with the fantastic opportunity to learn a practical approach to focusing on family members after finding a valuable strategy to work with the patient. Incorporating this course into the curriculum allows students to be well-rounded in caring for patients, including care for their caregivers.</span></p>
                        <h2><span data-preserver-spaces="true">References</span></h2>
                        <p><span data-preserver-spaces="true">Alberto, J., &amp; Herth, K. (2009). Interprofessional collaboration within faculty roles: Teaching, service, and research.&nbsp;</span><em><span data-preserver-spaces="true">OJIN: The Online Journal of Issues in Nursing</span></em><span data-preserver-spaces="true">,&nbsp;</span><em><span data-preserver-spaces="true">14</span></em><span data-preserver-spaces="true">(2).&nbsp;</span><a href="https://doi.org/10.3912/ojin.vol14no02ppt02" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.3912/ojin.vol14no02ppt02</span></a></p>
                        <p><span data-preserver-spaces="true">American Cancer Society. (2019).&nbsp;</span><em><span data-preserver-spaces="true">Becoming a cancer caregiver: What you need to know</span></em><span data-preserver-spaces="true">. American Cancer Society. Retrieved June 13, 2022, from&nbsp;</span><a href="https://www.cancer.org/treatment/caregivers/if-youre-about-to-become-a-cancer-%20caregiver.html" target="_blank"><span data-preserver-spaces="true">https://www.cancer.org/treatment/caregivers/if-youre-about-to-become-a-cancer- caregiver.html</span></a></p>
                        <p><span data-preserver-spaces="true">Belita, E., Carter, N., &amp; Bryant-Lukosius, D. (2020). Stakeholder engagement in nursing curriculum development and Renewal Initiatives: A literature review.&nbsp;</span><em><span data-preserver-spaces="true">Quality Advancement in Nursing Education - Avanc&eacute;es En Formation Infirmi&egrave;re</span></em><span data-preserver-spaces="true">,&nbsp;</span><em><span data-preserver-spaces="true">6</span></em><span data-preserver-spaces="true">(1).&nbsp;</span><a href="https://doi.org/10.17483/2368-6669.1200" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.17483/2368-6669.1200</span></a></p>
                        <p><span data-preserver-spaces="true">Boland, D. (2017).&nbsp;</span><em><span data-preserver-spaces="true">Developing curriculum: Frameworks, outcomes, and competencies</span></em><span data-preserver-spaces="true">. Nurse Key. Retrieved June 13, 2022, from&nbsp;</span><a href="https://nursekey.com/developing-curriculum-%20frameworks-outcomes-and-competencies/" target="_blank"><span data-preserver-spaces="true">https://nursekey.com/developing-curriculum- frameworks-outcomes-and-competencies/</span></a></p>
                        <p><span data-preserver-spaces="true">Centers for Disease Control and Prevention. (2022).&nbsp;</span><em><span data-preserver-spaces="true">Cancer Data and Statistics</span></em><span data-preserver-spaces="true">. CDC. Retrieved June 13, 2022, from&nbsp;</span><a href="https://www.cdc.gov/cancer/dcpc/data/index.htm" target="_blank"><span data-preserver-spaces="true">https://www.cdc.gov/cancer/dcpc/data/index.htm</span></a></p>
                        <p><span data-preserver-spaces="true">Lindsten, H. H., Auvinen, P. J., &amp; Juuti, T. S. (2019).&nbsp;</span><em><span data-preserver-spaces="true">Internal and external stakeholders' impact on product development curriculum design</span></em><span data-preserver-spaces="true">. The Design Society - a worldwide community. Retrieved from https://</span><a href="http://www.designsociety.org/publication/42281/INTERNAL%2BAND" target="_blank"><span data-preserver-spaces="true">www.designsociety.org/publication/42281/INTERNAL%2BAND</span></a></p>
                        <p><span data-preserver-spaces="true">National Cancer Institute. (2019).&nbsp;</span><em><span data-preserver-spaces="true">Caring for the Caregiver</span></em><span data-preserver-spaces="true">. US Department of Health and Human Services. Retrieved June 13, 2022, from https://</span><a href="http://www.cancer.gov/publications/patient-education/caring-for-the-caregiver" target="_blank"><span data-preserver-spaces="true">www.cancer.gov/publications/patient-education/caring-for-the-caregiver</span></a></p>
                        <p><span data-preserver-spaces="true">Reyes, J. (2017).&nbsp;</span><em><span data-preserver-spaces="true">External Factors Influencing Nursing Programs</span></em><span data-preserver-spaces="true">. Retrieved from&nbsp;</span><a href="https://study.com/academy/lesson/external-factors-influencing-nursing-programs.html" target="_blank"><span data-preserver-spaces="true">https://study.com/academy/lesson/external-factors-influencing-nursing-programs.html</span></a></p>
                        <p><span data-preserver-spaces="true">Tiwari, A., Chan, S., &amp; Law, B. (2002). Stakeholder involvement in curriculum planning.&nbsp;</span><em><span data-preserver-spaces="true">Nurse Educator</span></em><span data-preserver-spaces="true">,&nbsp;</span><em><span data-preserver-spaces="true">27</span></em><span data-preserver-spaces="true">(6), 265&ndash;270.&nbsp;</span><a href="https://doi.org/10.1097/00006223-200211000-00007" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.1097/00006223-200211000-00007</span></a></p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Nursfpx6107assessment2