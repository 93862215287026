import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Nursfpx6214assessment3 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>NURS FPX 6214 Assessment 3 Implementation Plan</title>
                <meta name='description'
                    content='Unlock the potential of NURS FPX 6214 Assessment 3 Implementation Plan with expert tips on executing your healthcare plan. Learn more!' />
                <meta name='keywords' content='NURS FPX 6214 Assessment 3 Implementation Plan' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>NURS FPX 6214 Assessment 3 < br /><span>Implementation Plan</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Nursfpx6214assessment3.webp" alt="NURS FPX 6214 Assessment 3 Implementation Plan" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><strong><em><span data-preserver-spaces="true">NURS FPX 6214 Assessment 3 Implementation Plan.</span></em></strong></li>
                        </ul>
                        <h2><span data-preserver-spaces="true">Implementation Plan</span></h2>
                        <p><span data-preserver-spaces="true">As we have examined in our past evaluations, the telehealth structure has transformed the healthcare field. We have had meetings with the assistants to examine the new technology. Before, the organization was functioning to deploy the integrated EHR-telehealth structure in the healthcare setting. For this reason, the accomplices urge the senior individuals to give an implementation plan to deploy this new technology.</span></p>
                        <p><span data-preserver-spaces="true">This evaluation will examine the factors crucial for deploying this technology and how the implementation plan can help us solve the issues. We will examine the meaning of Structure Progress Life Cycle (SDLC) tasks in implementing our plan for deploying new technology. The Implementation Plan will foster key strategies and examine the factors that unendingly bring about requests to give certainty to the healthcare staff and collaborators.</span></p>
                        <h2><span data-preserver-spaces="true">Assessing the Adequacy of Existing Telehealth Technology Infrastructure in the Organization</span></h2>
                        <p><span data-preserver-spaces="true">The methodology involved with assessing the adequacy of the existing telehealth structure infrastructure is the most fundamental part that must be discussed in the implementation plan. In our past evaluation, we found that the new technology has a few plan requirements and parts that should be seen before it is installed in a healthcare setting. The Integrated EHR-Telehealth Configuration will require a well-established infrastructure to work sensibly in healthcare organizations.</span></p>
                        <p><span data-preserver-spaces="true">Our organization's existing telehealth technology infrastructure will require remodelling and developing a suitable structure that can work with the EHR-Telehealth Development. The infrastructure should have the critical systems installed for working with our new technology (Velayati et al., 2022). In that capacity, the implementation plan is fundamental to drawing out all the essential information and setting up a full-fledged report on deploying the new technology.</span></p>
                        <p><span data-preserver-spaces="true">As we have examined in our past evaluations, some information openings in a healthcare setting influence assistants' determinations to use any new technology for any situation. The main thing is that it will generally be coordinated by conducting coaching meetings with the nursing workforce regarding technology and software use. Uncertainty exists everywhere, yet we need to encourage steady efforts to handle these areas of uncertainty. These areas may be related to the closeness of plans or software and stuff requirements.</span></p>
                        <ul>
                            <li>
                                <h3><span data-preserver-spaces="true">Challenges in Implementing Technology</span></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">There are a few factors that influence the working of the new technology, including Software and staff requirements, Mystery of patients and security stresses, In-closeness of contraptions used with EHR-Telehealth Systems, Trustworthiness, UI (UI), Client Experience (UX), Illustration of conclusions, Quality improvement, Chances of risks, Insecure Plans, and nonappearance of information regarding the use of the new technology. Consequently, upgrading existing infrastructure, especially software, is essential to incorporating the latest technology (Ko et al., 2018).</span></p>
                        <p><span data-preserver-spaces="true">The End Clients, patients in our circumstance, have experience with these cycle frameworks when they see a positive response in their healthcare overhauls and quality standards. Thus, the NURS FPX 6214 Assessment 3 Implementation Plan is essential to address every one of the advancements speculated that the infrastructure should genuinely deploy the Integrated EHR-Telehealth Structure.</span></p>
                        <h2><span data-preserver-spaces="true">Task Assigning and Responsibilities to Deploy New Telehealth Technology</span></h2>
                        <p><span data-preserver-spaces="true">In the past, we have examined and reviewed the meaning of pleasing joint efforts in requesting the deployment of the integrated EHR-telehealth structure in our healthcare organization. The central part of the deployment cycle is initially training the nursing staff and any remaining healthcare individuals on utilizing the new.</span></p>
                        <p><span data-preserver-spaces="true">technology (Ko et al., 2018). The implementation plan, therefore, withdraws the work and assigns the tasks to the individual divisions. Hence, according to our implementation plan, the IT division can train the nurses regarding the software and new technology. They will assist the nurses and doctors in gaining proficiency with the system used in the prototype. Since the nurses and doctors are trained, the extras will likely invest in the technology (Brown et al., 2020).</span></p>
                        <h3><span data-preserver-spaces="true">NURS FPX 6214 Assessment 3 Implementation Plan</span></h3>
                        <p><span data-preserver-spaces="true">The implementation plan will guide the organization in pushing ahead and setting standards. The business and administrative gathering will examine the issues with the investment firm, and they will keep the plans and manufacturing pack in a circle to finalize the problems in deploying the EHR-Telehealth Plan. As a clinical gatekeeper pioneer, my task will be to initially get comfortable with the ins and outs of this new technology with the help of the IT division, then train my nurses and make them proficient and all set for the utilization of technology.</span></p>
                        <p><span data-preserver-spaces="true">Hence, the responsibilities given to every division will help everyone grow together. Essentially, the workforce will transform into masters of the technology by helping each other with a strong coordinated effort.</span></p>
                        <h2><span data-preserver-spaces="true">Implementation Schedule</span></h2>
                        <p><span data-preserver-spaces="true">According to our gauge and implementation in past evaluations, implementing new technology will require no less than one and a half months. The Implementation Schedule will declare a bit-by-bit task that should be performed before completing the full-fledged installation of the EHR-Telehealth Framework. Thus, here is the finished schedule for implementing this new technology in our healthcare organization: First, we want to apply the security and chance evaluation methods to recognize the central questions in our setting.</span></p>
                        <p><span data-preserver-spaces="true">Besides, we must train our healthcare staff on a prototype to understand how the framework software and significant interface work. Then, we need to schedule a meeting in which every one of the concerned bodies is invited to determine whether they have the certainty to apply and utilize this new technology. Then, we need to Contact the IT division and coordinate with them, as they will be called first, assuming any disaster happens in the framework software and equipment.</span></p>
                        <p><span data-preserver-spaces="true">Finally, when all the healthcare staff is confident in the framework's deployment in the setting, a group of thoroughly prepared individuals will be set up to lead quality confirmation meetings after each understanding appointment. This group will help implement this technology and guarantee the consideration of patients using the EHR-Telehealth Framework.</span></p>
                        <ul>
                            <li>
                                <h3><span data-preserver-spaces="true">Transitioning to New Technology</span></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">Consequently, along these lines, according to our implementation schedule, the new technology will be effectively deployed in our healthcare organization in the time depicted (Loeb et al., 2020). Here emerges another inquiry: Will we immediately replace the old technology with the new one? The answer is in 'Negative'. We cannot wholly supplant the old technology since, in such a case that we do as such, this can have a few unfortunate results. All staff members are not required to turn in experts in the Integrated EHR-Telehealth Framework.</span></p>
                        <p><span data-preserver-spaces="true">We can assess our outcomes in light of our positive surveys from the end clients. Subsequently, after fulfilling the implementation schedule, the opportunity will come when there will be no need for old technology, and the EHR-Telehealth Framework will supplant it. Likewise, we should remember that this Integrated EHR-Telehealth Framework restricts work and analyses.</span></p>
                        <p><span data-preserver-spaces="true">There are a few options for every technology that is generally present in any framework. In this way, recall that the EHR-Telehealth Framework will not work in all cases as there are numerous sicknesses and healthcare issues it cannot handle. In contrast, old technology and techniques are the primary arrangements.</span></p>
                        <h2><span data-preserver-spaces="true">Strategies and Requirements of Staff Training</span></h2>
                        <p><span data-preserver-spaces="true">Before implementing new technology, staff training is the most troublesome task in any healthcare setting. It is the obligation of the concerned specialists, for example, in our situation, the IT office, Senior Nurses, and doctors, to train every individual regarding their job regarding the new technology. Cooperative collaboration is the most critical component to effectively implementing the latest technology and is key to establishing a complete.</span></p>
                        <p><span data-preserver-spaces="true">Trained healthcare setting. A SWOT investigation approach should be utilized to concentrate on every individual from staff. Along these lines, the trainers will consider every individual's insight holes. The person in question will come to know the capacities and abilities of the staff. The Framework Advancement Life Cycle (SDLC) is the strategy that can be involved before implementing the new technology, as this will direct the entire staff regarding the course of the fruitful deployment.</span></p>
                        <ul>
                            <li>
                                <h3><span data-preserver-spaces="true">EHR-Telehealth Training Plan</span></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">As in the partners meeting, we have finished with the distinguishing proof of our holes, and presently, we have begun to carry out the plan for the deployment of the EHR-Telehealth Framework, before which the learning of this new technology on prototype and through training meetings is vital. There ought to be some assigned timing, i.e.11, AM to 1 PM (Morning Meeting) and 6 PM to 8 PM (Evening Meeting) for the reason for day-to-day training meetings. The whole healthcare staff, including nurses and doctors, should be separated into two movements for training.</span></p>
                        <p><span data-preserver-spaces="true">For instance, if there are 50 staff members, 25 will attend the morning training meeting, and the other 25 will attend the evening meeting. Along these lines, the nurses will look into the training meetings and give them certainty to support their abilities. The timing division will assist the trainers with measuring the adequacy of their day-to-day meetings. They will set daily focuses to upgrade the nature of their training by taking criticisms and inputs (Ko et al., 2018).</span></p>
                        <p><span data-preserver-spaces="true">As in SDLC, the presumptions depend on accomplishing the practical deployment stage by dividing it into various phases. The timings are determined to apply the implementation plan to make the nurses and doctors aware of the new technology in a superior climate.</span></p>
                        <h2><span data-preserver-spaces="true">Strategy for Collaborating with Nurses and other Healthcare Providers</span></h2>
                        <p><span data-preserver-spaces="true">Requests to execute the new technology effectively are expected to involve cooperative efforts. This work will give nurses, doctors, and doctors certainty in carrying out their obligations and further developing the general healthcare infrastructure. For example, numerous strategies exist for collaborating with nurses and other healthcare providers.</span></p>
                        <p><span data-preserver-spaces="true">Correspondence is a key to creating patient-focused care. Along these lines, the nurses and patients assemble dependable relationships, and patients will help healthcare providers utilize Integrated EHR-Telehealth Technology for their treatment. When patients see the solid and cordial climate in a healthcare setting, the groups of patients and the patients themselves will trust the healthcare providers while using the new technology.</span></p>
                        <h3><span data-preserver-spaces="true">NURS FPX 6214 Assessment 3 Implementation Plan</span></h3>
                        <p><span data-preserver-spaces="true">In any case, a few factors could influence acknowledgment, including unfortunate correspondence, lack of patient cooperation, inconsistent outcomes, and lack of information on the EHR-Telehealth Framework (Anderson et al., 2019). Thus, for this reason, nurses and healthcare providers should take the initiative and the executive's abilities to reflect on the patient's brain science positively. Many administration styles are executed in different organizations according to their training, yet I suggest the transformational administration style as a medical caretaker pioneer for implementing this new technology.</span></p>
                        <p><span data-preserver-spaces="true">The transformational administration style suits the healthcare setting as it helps build a productive nursing environment that is fixated on powerful learning and development, one next to the other, with stable patient consideration (Blackwood et al., 2019).</span></p>
                        <p><span data-preserver-spaces="true">In this authority style, all the staff works cooperatively to generate results that could help the end client. The suspicions on which the strategy depends on the reactions from the end-clients when any new technology is carried out. By considering their input, we have fostered these strategies that could help healthcare providers upgrade patient security and healthcare using the latest technology.</span></p>
                        <h2><span data-preserver-spaces="true">Post-Deployment Telehealth Technology Evaluation and Maintenance Strategy</span></h2>
                        <p><span data-preserver-spaces="true">In the implementation plan, we must examine our technology evaluation and maintenance strategies before the deployment cycle. A separate office should be for day-to-day control and quality confirmation meetings with the nursing and other healthcare staff. The IT division will monitor every issue of the telehealth framework at the backend. Along these lines, the maintenance division will remain modern regarding technology issues.</span></p>
                        <p><span data-preserver-spaces="true">In this way, while implementing these strategies, the partner ought to be kept in a circle to have an outline of general occasions happening in that specific healthcare setting (Kumar &amp; Tian, 2020). By the day's end, an evaluation and maintenance ought to be introduced to the concerned specialists so that they can try to tackle the issues and the specialized troubles (Preaux et al., 2022). Maintenance groups ought to be trained well to such an extent that they ought to know every piece of that specific technology before its implementation (Kumar &amp; Tian, 2020). There ought to be a few individuals prescribed by the maker to lead maintenance exercises for specific healthcare technology.</span></p>
                        <ul>
                            <li>
                                <h3><span data-preserver-spaces="true">EHR-Telehealth Evaluation Strategy</span></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">In request to assess the technology, we will initially perform a SWOT (Strengths, Shortcomings, Potential open doors, Dangers) examination in coordination with the necessities appraisal approach. The partners ought to do a legitimate exploration while analyzing the significance of the EHR-Telehealth Framework. While evaluating, distinguish the holes in the old frameworks, issue ID, check the unwavering quality, and perform an expense examination to settle on a final decision.</span></p>
                        <p><span data-preserver-spaces="true">A few measures are needed to determine the progress of this new technology. These include cost contemplations, similarity, Framework Protection and Security software, Ease of use, Client experience, analytic interaction, and quality and security upgrades. Before applying these evaluation and maintenance strategies, many factors must be considered, such as financial plan control, trained staff, productivity, and chance appraisals.</span></p>
                        <h2><span data-preserver-spaces="true">Conclusion</span></h2>
                        <p><span data-preserver-spaces="true">The&nbsp;</span><a href="https://www.writinkservices.com/nurs-fpx-6214-assessment-3-implementation-plan/" target="_blank"><span data-preserver-spaces="true">NURS FPX 6214 Assessment 3 Implementation Plan</span></a><span data-preserver-spaces="true"> is vital in making a final choice. It gives certainty to the partners, who consider how the utilization of this new technology will affect their time and cash. To execute a new technology effectively, all the expected staff should be trained first on a prototype, and the healthcare organization should be prepared to confront the latest technology.</span></p>
                        <h2><span data-preserver-spaces="true">References</span></h2>
                        <p><span data-preserver-spaces="true">Anderson, R. J., Bloch, S., Armstrong, M., Stone, P. C., &amp; Low, J. T. S. (2019). Communication between healthcare professionals and relatives of patients approaching the end-of-life: A systematic review of qualitative evidence.&nbsp;</span><em><span data-preserver-spaces="true">Palliative Medicine</span></em><span data-preserver-spaces="true">,&nbsp;</span><em><span data-preserver-spaces="true">33</span></em><span data-preserver-spaces="true">(8), 926&ndash;941.&nbsp;</span><a href="https://doi.org/10.1177/0269216319852007" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.1177/0269216319852007</span></a></p>
                        <p><span data-preserver-spaces="true">Blackwood, D. H., Walker, D., Mythen, M. G., Taylor, R. M., &amp; Vindrola‐Padros, C. (2019). Barriers to advance care planning with patients as perceived by nurses and other healthcare professionals: A systematic review.&nbsp;</span><em><span data-preserver-spaces="true">Journal of Clinical Nursing</span></em><span data-preserver-spaces="true">,&nbsp;</span><em><span data-preserver-spaces="true">28</span></em><span data-preserver-spaces="true">(23-24), 4276&ndash;4297.&nbsp;</span><a href="https://doi.org/10.1111/jocn.15049" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.1111/jocn.15049</span></a></p>
                        <p><span data-preserver-spaces="true">Brown, J., Pope, N., Bosco, A. M., Mason, J., &amp; Morgan, A. (2020). Issues affecting nurses' capability to use digital technology: An integrative review.&nbsp;</span><em><span data-preserver-spaces="true">Journal of Clinical Nursing</span></em><span data-preserver-spaces="true">,&nbsp;</span><em><span data-preserver-spaces="true">29</span></em><span data-preserver-spaces="true">(15-16), 2801&ndash;2819.&nbsp;</span><a href="https://doi.org/10.1111/jocn.15321" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.1111/jocn.15321</span></a></p>
                        <p><span data-preserver-spaces="true">Ko, M., Wagner, L., &amp; Spetz, J. (2018). </span><strong><span data-preserver-spaces="true">Nursing home implementation of health information technology:</span></strong><span data-preserver-spaces="true"> Literature review finds inadequate investment in preparation, infrastructure, and training.&nbsp;</span><em><span data-preserver-spaces="true">INQUIRY: The Journal of Health Care Organization, Provision, and Financing</span></em><span data-preserver-spaces="true">,&nbsp;</span><em><span data-preserver-spaces="true">55</span></em><span data-preserver-spaces="true">, 004695801877890.&nbsp;</span><a href="https://doi.org/10.1177/0046958018778902" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.1177/0046958018778902</span></a></p>
                        <p><span data-preserver-spaces="true">Kumar, S., &amp; Tian, E. J. (2020). Critical factors in the development, implementation and evaluation of Telemedicine.&nbsp;</span><em><span data-preserver-spaces="true">Advances in Telemedicine for Health Monitoring: Technologies, Design and Applications</span></em><span data-preserver-spaces="true">, 1&ndash;14.&nbsp;</span><a href="https://doi.org/10.1049/pbhe023e_ch" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.1049/pbhe023e</span></a></p>
                        <p><span data-preserver-spaces="true">Loeb, A. E., Rao, S. S., Ficke, J. R., Morris, C. D., Riley, L. H., &amp; Levin, A. S. (2020). Departmental experience and lessons learned with accelerated introduction of Telemedicine during the COVID-19 crisis.&nbsp;</span><em><span data-preserver-spaces="true">Journal of the American Academy of Orthopaedic Surgeons</span></em><span data-preserver-spaces="true">,&nbsp;</span><em><span data-preserver-spaces="true">28</span></em><span data-preserver-spaces="true">(11).&nbsp;</span><a href="https://doi.org/10.5435/jaaos-d-20-00380" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.5435/jaaos-d-20-00380</span></a></p>
                        <p><span data-preserver-spaces="true">Preaux, J., Casades&uacute;s, M., &amp; Bernardo, M. (2022). A conceptual model to evaluate service quality of direct-to-consumer telemedicine consultation from a Patient Perspective.&nbsp;</span><em><span data-preserver-spaces="true">Telemedicine and e-Health</span></em><span data-preserver-spaces="true">.&nbsp;</span><a href="https://doi.org/10.1089/tmj.2022.0089" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.1089/tmj.2022.0089</span></a></p>
                        <p><span data-preserver-spaces="true">Velayati, F., Ayatollahi, H., Hemmat, M., &amp; Dehghan, R. (2022). Telehealth business models and their components: Systematic review.&nbsp;</span><em><span data-preserver-spaces="true">Journal of Medical Internet Research</span></em><span data-preserver-spaces="true">,&nbsp;</span><em><span data-preserver-spaces="true">24</span></em><span data-preserver-spaces="true">(3).&nbsp;</span><a href="https://doi.org/10.2196/33128" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.2196/33128</span></a></p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Nursfpx6214assessment3
