import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Mbafpx5007assessment2 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>MBA FPX 5007 Assessment 2 Managing the Toxic Leader</title>
                <meta name='description'
                    content='Struggling with toxic leadership? MBA FPX 5007 Assessment 2 Managing the Toxic Leader offers expert strategies. Read now for solutions!' />
                <meta name='keywords' content='MBA FPX 5007 Assessment 2 Managing the Toxic Leader' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>MBA FPX 5007 Assessment 2 < br /><span>Managing the Toxic Leader</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Mbafpx5007assessment2.webp" alt="MBA FPX 5007 Assessment 2 Managing the Toxic Leader" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><strong><em>MBA FPX 5007 Assessment 2 Managing the Toxic Leader</em></strong></li>
                        </ul>
                        <h2><strong>Leader's Performance Evaluation</strong></h2>
                        <p>From the situation concerning Jackie's leadership performance, issues of concern emerge in the going with focuses. She loathes the nursing staff for contradicting patient-related matters and has not been strong or consistent with the nursing staff.</p>
                        <p>For example, the clinical caretaker, Kyle, who had been consigned to manage a patient named K. C., whimpered to his boss, Jackie, about his shortcomings in handling the patient. Notwithstanding, rather than offering him the fitting help, Jackie pardoned him. Such missing help disturbs Kyle's careful work in giving authentic care to the patients and results in frustration and confusion for the nursing staff (Lindgren et al., 2020).</p>
                        <p>Similarly, Jackie's interpersonal cutoff communities have strikingly gotten out of the piece and are freed from communication in the given situation. She did not consider the abundance of communication and authentic coordination by not dropping the patient care conference nor illuminating Kyle and the recreational therapist. This confused people, expecting the gathering as the other gathering was unsuitable. Further, her unimportance in offering all due appreciation to the email from Kyle asking her for a gathering features one of the most concerning issues with her communication: her episodes of it, and she seldom responds when she ought to. Explore our assessment <a href="https://onlinecourseservices.us/mba-fpx-5007-assessment-1-leadership-styles">MBA FPX 5007 Assessment 1 Leadership Styles Application</a>&nbsp;for more information.</p>
                        <h2><strong>MBA FPX 5007 Assessment 2 Managing the Toxic Leader</strong></h2>
                        <p>Jackie's performance as a leader in the mental unit reveals violations of two head standards of professional performance: stress to the board, essential communication, and general help and leadership to convey accessories to her subordinates. The ANA guarantees collaboration, good prevention and safeguarding, and intense care, which relies on appropriate communication (ANA, 2020). Jackie's inconsequentiality in handing off principal subtleties, including cancellation of gatherings, has deterred the gathering's operations and learning limits, harming the patient's well-being (Moreira et al., 2019).</p>
                        <p>In addition, as the office's essential quality, Jackie is responsible for gathering or gathering labourers, particularly on issues connected with patient care, including the situation portrayed beforehand. The ANA suggests that nursing leadership should improve the environment to help clinical chaperons' preparation, progress, and patient promotion before investigating staff well-being necessities (ANA, 2020).</p>
                        <h2><strong>Conclusion</strong></h2>
                        <p>It is important to have a sharp and honest examination of Jackie's leadership performance inside the unit concentrating on psychiatry; it has a few issues. Her sad help and direction of the nursing pack, unequivocally handling strong patient frequencies, isn't required from a leader.</p>
                        <p>Furthermore, her communication shortcoming, since she seldom conveys on time or even responds to messages held in the unit's functioning, makes her a horrendous, strong person. It can now be seen from the conspicuous leadership measures or standards that Jackie is insufficient with reverence, by and large, or another.</p>
                        <p>One central concern that is monster for a leader to impact is communication, by which they can stay aware of and work with the gathering's exercises (Schot et al., 2019). In addition, helping and mentoring the trimming, particularly as to patient care issues, are among the wonderful positions of the chaperon chief (Wei et al., 2019).</p>
                        <p>Implications of Jackie's Behavior</p>
                        <p>Jackie's behaviour has undertones that impact the organizational undertaking, notion, and goals of conveying standard healthcare in an intense environment and guaranteeing a quantifiable positive contrast in cordial classes' well-being inside the populations of the served region.</p>
                        <h2><strong>Effects on Patients and Their Perceptions</strong></h2>
                        <p>The outcomes demonstrate that Jackie's sensitive help and direction to the nursing staff could, truthfully, impact patient care and the sort of inclusion that the patient is to go through.</p>
                        <p>Without reasonable leadership sponsorship and direction, the progressions happen with decreased degrees of patient satisfaction and terrible patient safety (Albalawi et al., 2020). This is contrary to the organization's mission to give the best healthcare administrations, and the general wellbeing well-being of individuals in the public field is negatively impacted.</p>
                        <ul>
                            <li>
                                <h3><strong>Impact On Professionalism and Quality of Care</strong></h3>
                            </li>
                        </ul>
                        <p>Consequently, Jackie's actions decrease the envisioned assertion of making exceptional patient care offices proffered by healthcare personnel who rely on performing at their best concerning clinical quality and safety.</p>
                        <p>She could take more extended periods before they convey and resolve patients' care concerns, consequently compromising the clinical standards and protection of the organization.</p>
                        <ul>
                            <li>
                                <h3><strong>Impact on Staff Satisfaction and Wellbeing</strong></h3>
                            </li>
                        </ul>
                        <p>Jackie's lack of regard, communication, support for, and care of staff presents a contradicting perspective and dazzling satisfaction and well-being of the staff. The lack of a positive organizational culture set at a crucial level by the leader about the demonstration of cooperation will likely exacerbate the degree of occupation satisfaction and organizational responsibility and add stress. This situation conflicts with the goals of watching the patient, arranged power, and staff's well-being and safety (S&oslash;vold et al., 2021).</p>
                        <ul>
                            <li>
                                <h3><strong>Implications and Consequences</strong></h3>
                            </li>
                        </ul>
                        <p>Preventing communication and disillusionment by genuinely zeroing in on the patient care issues coming from Jackie's carelessness and nonattendance of staff help is reasonably going to confound patients, which would lessen satisfaction and crumble the organizational trust.</p>
                        <p>Healthcare consumers or patients expect that their healthcare practitioners, leaders, or directors will take uncommon care of them and be responsive, blissful, and concerned about their administration's help (Irshad et al., 2021). Anticipating Jackie somehow sorted out a reasonable framework for missing her obligations and neglecting to go to unequivocal issues, the result would be antagonistic to the patients since they would undoubtedly get unsuitable encounters that would leave them conferring fundamentally no confidence in the organization.</p>
                        <ul>
                            <li>
                                <h3><strong>Compromised Patient Safety</strong></h3>
                            </li>
                        </ul>
                        <p>Unresponsiveness and failure to handle issues connected with patient care professionally expose different patients to the risk of being injured by their practitioner's carelessness. Patient safety can't be compromised in any unit of a healthcare organization.</p>
                        <p>Along these lines, patient safety leadership is central since it consolidates the coordination of all others to guarantee that standards and systems are done unequivocally. This variable confines the organization's capacity to monitor patient safety and could influence astonishing occasions, for example, patient damage and clinical misunderstandings that demonstrate over the top to the organization.</p>
                        <ul>
                            <li>
                                <h3><strong>Decreased Staff Morale and Engagement</strong></h3>
                            </li>
                        </ul>
                        <p>Jackie's inconsiderateness, nonattendance of concern, and carelessness in responding to staff matters may contribute to the demotivation and demoralization of the healthcare personnel. The personnel relies on the administration for direction, backing, and a fitting organizational culture (Wisetsri, 2020).</p>
                        <p>Right when Jackie ruins her leadership responsibilities, she encounters deterred staff, low work satisfaction, and perhaps low work performance among the educated subject matter experts. It can impact the reasonableness of the organization in various ways since it impacts the functioning plans.</p>
                        <ul>
                            <li>
                                <h3><strong>Negative Organizational Culture</strong></h3>
                            </li>
                        </ul>
                        <p>A portion of the catches precise in Jackie's situation consolidate demonstrating and/or supporting a hazardous organizational environment. Organizational leaders are straightforwardly responsible for an organization's culture. Jackie's behaviours and actions at work could have miserable effects, such as specialists' distrust, nonattendance of engagement in work, and hopeless communication inside the organization. This can induce a horrendous workspace, impact staff interaction, and put off achieving organizational targets and centres (Hamouche, 2021).</p>
                        <ul>
                            <li>
                                <h3><strong>Possible Grease and Erosion Concerns</strong></h3>
                            </li>
                        </ul>
                        <p>Nonetheless, Jackie's behaviour and continued need to resolve other bothersome issues related to patient care can have real ramifications for the organization. Failure to give genuine care and shield patients achieves real consequences, including tests by informed, instructed specialists and training or sanctions from composed professionals. These consequences can damage an organization's reputation, change its monetary position, and cause naughtiness in its ability to convey quality care (Gaur et al., 2021).</p>
                        <h2><strong>Action Plan</strong></h2>
                        <p>The performance improvement plan model that might be utilized in the portrayed situation is the Opening or Urge model to refresh Jackie's leadership performance. The Develop model, which is an acronym for Objective, Reality, Options, and Will, is an objective methodology that depicts targets, assesses the ongoing position, tracks down prospects of progress, and measures the situation with a person to change (Ibarra &amp; Scoular, 2019, p. 98).</p>
                        <p>The action plan will be implemented in 40 days with the objective that it doesn't fan out and that the most proportionate changes can be made. Nonetheless, in this wide period, express targets and exercises will be depicted to examine the reasonability of the advancement and change the actions toward progress.</p>
                        <h3><strong>MBA FPX 5007 Assessment 2 Managing the Toxic Leader</strong></h3>
                        <p>As a leader, Jackie should show unequivocal behaviours and qualities as the organization's critical direction spreads out. Such expectations should consolidate communication, cooperation, and conflict among directors, leadership, and evaluations (De Br&uacute;n et al., 2019). Strengthening Jackie's leadership performance could further cultivate healthcare conveyance and results among patients (Atiemo, 2023).</p>
                        <p>Other than the leader, the leader bears a ton of responsibility for determining the environment and conditions in which work is to be done in the organization. Expecting Jackie to strengthen her leadership common sense, she will remain mindful of everyone at her foundation to work in a positive environment that embraces cooperation and regards the public power help of others. This, consequently, accomplishes reasonable coordination of the well-being of workers and the end-client results, as needed to encourage the clinical care administrations further achieved (Gottlieb et al., 2021).</p>
                        <p><strong><em>Moreover,</em></strong> Jackie's more reachable leadership performance can especially impact patients' outcomes in patient safety, satisfaction, and well-being. Urgent and procedural patient-centeredness, leaders' short actions and reactions to patient aspirations and concerns, formal and relaxed patient-centeredness, and patient-centeredness as morals and worth.</p>
                        <p>Consequently, with the help and explanation, Jackie will genuinely need to change into a leader who will ponder patients' necessities, try to give them first-rate healthcare benefits, and spread out a favourable environment (&Ouml;zyurt et al., 2021).</p>
                        <ul>
                            <li>
                                <h3><strong>Assumptions</strong></h3>
                            </li>
                        </ul>
                        <p>An assumption in the action plan to further foster Jackie's leadership performance is that Jackie can get examination and consider her conduct. It is vital to understand that the plan is based on Jackie passing forward and that she understands the regions that require change and need to reach a more fundamental level. It expects that she has time and preparation to drive herself professionally and values the meaning of leadership in the healthcare structure and its outcomes for patients.</p>
                        <p>Along these lines, the plan consolidates the expectation that Jackie will need to participate in leadership, get ready, and prepare, as well as have great organizational assistance and the opportunity of an ideal environment to push the organization's update.</p>
                        <h2><strong>Conclusion</strong></h2>
                        <p>The evaluation of Jackie's performance as an unlucky, miserable leader is seen as not having exceptional actions and non-practices consistent with professional performance necessities. Her conduct and improvement have turned out to be counterproductive to accomplishing the organization's mission, vision, and targets. By and large, she has a command of economic resolutions while spreading out a wide action plan to handle the above issues: along these lines, she makes a degree to change into an unquestionable leader, offering an opportunity to better the clinical benefits and in extension, managing the patient's wellbeing.</p>
                        <p>Nonetheless, implementing this action plan depends upon Jackie, so it relies upon her to change and make. Consequently, the organization must help and drive rules for Jackie's leadership progress. The organization can endeavour to achieve the necessities of continuing ahead, offering clinical benefits <a href="https://www.writinkservices.com/nurs-fpx-5007-assessment-2-managing-the-toxic-leader/">MBA FPX 5007 Assessment 2 Managing the Toxic Leader Managing the Toxic Leader</a>&nbsp;administrations while additionally planning to further help the public power with the help of the patients.</p>
                        <h2><strong>References</strong></h2>
                        <p>Albalawi, A., Kidd, L., &amp; Cowey, E. (2020). Factors contributing to the patient safety culture in Saudi Arabia: A systematic review. <em>BMJ Open, 10</em>(10).<a href="https://doi.org/10.1136/bmjopen-2020-037875">https://doi.org/10.1136/bmjopen-2020-037875</a></p>
                        <p>American Nurses Association (ANA). (2020). ANA principles. <em>ANA</em>.<a href="https://www.nursingworld.org/practice-policy/nursing-excellence/official-position-statements/ana-principles/">https://www.nursingworld.org/practice-policy/nursing-excellence/official-position-statements/ana-principles/</a></p>
                        <p>Atiemo, P. (2023). Healthcare leadership attributes in changing culture (Publication No. 30528887) [Master&rsquo;s thesis, College of St. Scholastica]. <em>ProQuest</em>.<a href="https://www.proquest.com/openview/9e152a615a4a8dff3756fdb7653efc74/1?pq-origsite=gscholar&amp;cbl=18750&amp;diss=y">https://www.proquest.com/openview/9e152a615a4a8dff3756fdb7653efc74/1?pq-origsite=gscholar&amp;cbl=18750&amp;diss=y</a></p>
                        <p>Chien, L. J., Slade, D., Dahm, M. R., Brady, B., Roberts, E., Goncharov, L., Taylor, J., Eggins, S., &amp; Thornton, A. (2022). Improving patient-centered care through a tailored intervention addressing nursing clinical handover communication in its organizational and cultural context. <em>Journal of Advanced Nursing, 78</em>(5), 1413&ndash;1430.<a href="https://doi.org/10.1111/jan.15110">https://doi.org/10.1111/jan.15110</a></p>
                        <p>De Br&uacute;n, A., O&rsquo;Donovan, R., &amp; McAuliffe, E. (2019). Interventions to develop collectivistic leadership in healthcare settings: A systematic review. <em>BMC Health Services Research, 19</em>(1).<a href="https://doi.org/10.1186/s12913-019-3883-x">https://doi.org/10.1186/s12913-019-3883-x</a></p>
                        <p>Gaur, S., Kumar, R., Gillespie, S. M., &amp; Jump, R. L. P. (2021). Integrating principles of safety culture and just culture into nursing homes: Lessons from the pandemic. <em>Journal of the American Medical Directors Association, 23</em>(2).<a href="https://doi.org/10.1016/j.jamda.2021.12.017">https://doi.org/10.1016/j.jamda.2021.12.017</a></p>
                        <p>Gottlieb, L. N., Gottlieb, B., &amp; Bitzas, V. (2021). Creating empowering conditions for nurses with workplace autonomy and agency: How healthcare leaders could be guided by strengths-based nursing and healthcare leadership (SBNH-L). <em>Journal of Healthcare Leadership, 13</em>(13), 169&ndash;181.<a href="https://doi.org/10.2147/jhl.s221141">https://doi.org/10.2147/jhl.s221141</a></p>
                        <p>Hamouche, S. (2021). Human resource management and the COVID-19 crisis: Implications, challenges, opportunities, and future organizational directions. <em>Journal of Management &amp; Organization, 1</em>(1), 1&ndash;16.<a href="https://doi.org/10.1017/jmo.2021.15">https://doi.org/10.1017/jmo.2021.15</a></p>
                        <p>Ibarra, H., &amp; Scoular, A. (2019). Leadership: The leader is a coach on how to unleash innovation, energy, and commitment. <em>CHS Business</em>.<a href="https://www.chsbusiness.com/wp-content/uploads/leader_as_coach.pdf">https://www.chsbusiness.com/wp-content/uploads/leader_as_coach.pdf</a></p>
                        <p>Irshad, M., Majeed, M., &amp; Khattak, S. A. (2021). The combined effect of safety-specific transformational leadership and safety consciousness on the psychological well-being of healthcare workers. <em>Frontiers in Psychology, 12</em>.<a href="https://doi.org/10.3389/fpsyg.2021.688463">https://doi.org/10.3389/fpsyg.2021.688463</a></p>
                        <p>Labrague, L. J., Santos, J. A. A., &amp; Fronda, D. C. (2021). Factors associated with missed nursing care and nurse‐assessed quality of care during the COVID‐19 pandemic. <em>Journal of Nursing Management, 30</em>(1).<a href="https://doi.org/10.1111/jonm.13483">https://doi.org/10.1111/jonm.13483</a></p>
                        <p>Lindgren, B.-M., Molin, J., &amp; Graneheim, U. H. (2020). Balancing between a person-centered and a common staff approach: Nursing staff&rsquo;s experiences of good nursing practice for patients who self-harm. <em>Issues in Mental Health Nursing, 42</em>(6), 1&ndash;9.<a href="https://doi.org/10.1080/01612840.2020.1817206">https://doi.org/10.1080/01612840.2020.1817206</a></p>
                        <p>Moreira, F. T. L. D. S., Callou, R. C. M., Albuquerque, G. A., &amp; Oliveira, R. M. (2019). Effective communication strategies for managing disruptive behaviors and promoting patient safety. <em>Revista Gaucha de Enfermagem, 40</em>(spe).<a href="https://doi.org/10.1590/1983-1447.2019.20180308">https://doi.org/10.1590/1983-1447.2019.20180308</a></p>
                        <p>&Ouml;zyurt, E., Avci, K., &amp; Şenel, F. &Ccedil;. (2021). The impact of clinical leadership on quality and accreditation studies in health services. <em>Journal of Basic and Clinical Health Sciences, 5</em>(3), 221&ndash;232.<a href="https://doi.org/10.30621/jbachs.955272">https://doi.org/10.30621/jbachs.955272</a></p>
                        <p>Ree, E., &amp; Wiig, S. (2019). Linking transformational leadership, patient safety culture and work engagement in home care services. <em>Nursing Open, 7</em>(1).<a href="https://doi.org/10.1002/nop2.386">https://doi.org/10.1002/nop2.386</a></p>
                        <p>Schot, E., Tummers, L., &amp; Noordegraaf, M. (2019). I am working on working together: A systematic review of how healthcare professionals contribute to interprofessional collaboration. <em>Journal of Interprofessional Care, 34</em>(3), 1&ndash;11.<a href="https://doi.org/10.1080/13561820.2019.1636007">https://doi.org/10.1080/13561820.2019.1636007</a></p>
                        <p>Silverman, H. J., Kheirbek, R. E., Moscou-Jackson, G., &amp; Day, J. (2021). Moral distress in nurses caring for patients with COVID-19. <em>Nursing Ethics, 28</em>(7-8).<a href="https://doi.org/10.1177/09697330211003217">https://doi.org/10.1177/09697330211003217</a></p>
                        <p>S&oslash;vold, L. E., Naslund, J. A., Kousoulis, A. A., Saxena, S., Qoronfleh, M. W., Grobler, C., &amp; M&uuml;nter, L. (2021). Prioritizing the mental health and well-being of healthcare workers: An urgent global public health priority. <em>Frontiers in Public Health, 9</em>(1).<a href="https://doi.org/10.3389/fpubh.2021.679397">https://doi.org/10.3389/fpubh.2021.679397</a></p>
                        <p>Wei, H., Roberts, P., Strickler, J., &amp; Corbett, R. W. (2019). Nurse leaders&rsquo; strategies to foster nurse resilience. <em>Journal of Nursing Management, 27</em>(4), 681&ndash;687.<a href="https://doi.org/10.1111/jonm.12736">https://doi.org/10.1111/jonm.12736</a></p>
                        <p>Wisetsri, W. (2020). The influence of leadership, work motivation, and organizational culture on job performance. <em>International Journal of Psychosocial Rehabilitation, 24</em>(5), 7336&ndash;7350.<a href="https://doi.org/10.37200/ijpr/v24i5/pr2020768">https://doi.org/10.37200/ijpr/v24i5/pr2020768</a></p>
                        <p>Xiang, Y.-T., Zhao, Y.-J., Liu, Z.-H., Li, X.-H., Zhao, N., Cheung, T., &amp; Ng, C. H. (2020). The COVID-19 outbreak and psychiatric hospitals in China: Managing challenges through mental health service reform. <em>International Journal of Biological Sciences, 16</em>(10), 1741&ndash;1744.<a href="https://doi.org/10.7150/ijbs.45072">https://doi.org/10.7150/ijbs.45072</a></p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Mbafpx5007assessment2
