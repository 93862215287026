import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Nursfpx6212assessment1 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>NURS FPX 6212 Assessment 1 Quality and Safety Gap</title>
                <meta name='description'
                    content='Understand the NURS FPX 6212 Assessment 1 Quality and Safety Gap in healthcare. Discover solutions to bridge the gap and improve patient outcomes.' />
                <meta name='keywords' content='NURS FPX 6212 Assessment 1 Quality and Safety Gap' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>NURS FPX 6212 Assessment 1 < br /><span>Quality and Safety Gap Analysis</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Nursfpx6212assessment1.webp" alt="NURS FPX 6212 Assessment 1 Quality and Safety Gap" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><strong><em><span data-preserver-spaces="true">NURS FPX 6212 Assessment 1 Quality and Safety Gap Analysis.</span></em></strong></li>
                        </ul>
                        <h2><span data-preserver-spaces="true">Quality and Safety Gap Analysis</span></h2>
                        <p><span data-preserver-spaces="true">Research by Mynař&iacute;kov&aacute; et al. (2020) reveals that millions of Americans are projected to be affected by hospital-acquired infections (HAIs) each year, with more than a million of those individuals estimated to kick the pail because of complications from these infections. It is indicated that avoiding and decreasing the repeat of these infections, which can harm the provision and security of patient care, requires phenomenal nursing care.</span></p>
                        <p><span data-preserver-spaces="true">Furthermore, according to Mynař&iacute;kov&aacute; et al. (2020), inadequate adherence to nursing care interventions can cause increased healthcare costs, unfavourable occasions, falls, wounds, prescription mistakes, decreased patient satisfaction, and increased mortality rates. This research aims to assess the efficacy and proficiency of a continuous quality improvement program expected to hack down the nonventilated hospital-acquired pneumonia (NV-HAP) rate while saving lives and healthcare dollars.&nbsp;Explore our assessment&nbsp;</span><a href="https://onlinecourseservices.us/nurs-fpx-6212-assessment-2-executive-summary" target="_blank"><span data-preserver-spaces="true">NURS FPX 6212 Assessment 2 Executive Summary</span></a><span data-preserver-spaces="true"> for more information.</span></p>
                        <h2><span data-preserver-spaces="true">The Identified Systemic Problem</span></h2>
                        <p><span data-preserver-spaces="true">With a longstanding history of innovation and taking a stab at better patient outcomes, the Biloxi VA Medical Center acknowledged the requirement for change when it came to dealing with the consistency and quality of oral care given to inpatient and local area living concentration (CLC) occupants that would decrease the repeat of hospital-acquired pneumonia while saving lives and health care dollars. This charter was named Undertaking HAPPEN, with the acronym standing for Hospital Acquired Pneumonia Prevention by Engaging Medical Attendants.</span></p>
                        <p><span data-preserver-spaces="true">The Diffusion of Greatness Board tracked down that modifications to oral care implementation, oral care education, and oral care documentation would help in decreasing cases of nonventilated hospital-acquired pneumonia (NV-HAP) as occupants of our long-term care facility, the CLC, and the acute inpatient units awaiting placement are veterans of a greater, frail age with additional functional.</span></p>
                        <ul>
                            <li>
                                <h3><span data-preserver-spaces="true">Preventing Hospital-Acquired Pneumonia</span></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">Disabilities and basic medical ailments put them at an increased gamble of acquiring muscular diseases, with pneumonia being the leading cause of dismalness and mortality in the long-term care population. Research reveals that non-ventilator-associated hospital-acquired pneumonia (NVHAP) has a mortality rate of 15-30%, is associated with readmission rates, and relaxes hospital stays by as long as 15 days. It affects 1 in every 100 hospitalized patients (Munro et al., 2021).</span></p>
                        <p><span data-preserver-spaces="true">The NURS FPX 6212 Assessment 1 Quality and Safety Gap Analysis&nbsp;highlights how the Endeavor HAPPEN charter initiated the implementation of the national VAAES oral care note and new oral care units to chip away at the education and implementation given to the veterans while giving the recorded health factors to meet the national benchmark oral care documentation goal of 80%."</span></p>
                        <p><span data-preserver-spaces="true">Notwithstanding, pushing ahead with this charter turned out to be a battle when earlier management of the Acute Care unit faced the impacts of Coronavirus and staff burnout. The acute care unit faced the effects of Coronavirus burnout, which resulted in staffing shortages, staff turnover, and the lack of the unit's oral care champions, who could give continued education to staff. Meanwhile, the unit was confused about duplicated options of oral care packs, which caused the past management team not to complete a final decision based on consumer feedback.</span></p>
                        <ul>
                            <li>
                                <h3><span data-preserver-spaces="true">Challenges in Oral Care Documentation</span></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">Amongst these barriers identified was the documentation inconsistency inside the new VAAES oral care note. The unit had not been gathering its performance measures for Adventure HAPPEN because of the ongoing national data retrieval cutoff season of noon. Two confirmed cases of NV-HAP out of 1,170 inpatient stays were found in the baseline data gathered between January 2020 and March 2021. This indicates a 0.02 occasion proportion or a rate estimated at 20 for every 1000 hospitalized patients.</span></p>
                        <p><span data-preserver-spaces="true">The total estimated cost to the facility was $80,000 or $40,000 per confirmed case. Along with the national implementation of the VAAES oral care note, new oral care packs, and further created oral care education given to the veterans, the facility planned to push ahead with a reduction goal of half for fiscal years 2021 and 2022, along with a national benchmark goal of 80% for the VAAES oral care note documentation.</span></p>
                        <p><span data-preserver-spaces="true">Another SWOT analysis revealed the heaviness of retaining oral care champions amongst staff turnover, which compromised gathering the national 80% oral care documentation benchmark goal, showing a unit compliance rate of 65.5%. Amongst these barriers identified was the documentation inconsistency inside the new VAAES oral care note.</span></p>
                        <h2><span data-preserver-spaces="true">Proposed Practice Changes</span></h2>
                        <p><span data-preserver-spaces="true">This quality and safety project's forefront is executing standardized, proof-based practice interventions to decrease NV-HAP repeat. A systematic report shows that oral care practices are essential to lowering the colonization of bacteria in the oral cavity, consequently reducing the gamble of NV-VAP and VAP by 25% to 40% (Kallet, 2019). In addition, key performance indicators (KPIs) were contemplated, revealing an essential need for a revamp of the VAAES oral care note documentation timeframe to be carried out so that each shift of oral care is accounted for within the same 24-hour term.</span></p>
                        <p><span data-preserver-spaces="true">This approach will give the standard documentation to meet the national VAAES oral care performance measures benchmark goal of 80%. The informatics team will continue evaluating unit compliance by pulling the announced health factors inside the VAAES note monthly to frame documentation adherence and provide remediation and education. Lastly, confining the oral care supplies to one do-all pack will give consumer feedback, limit confusion, create purchases from stakeholders, and give them a sensation of engagement and value.</span></p>
                        <h3><span data-preserver-spaces="true">NURS FPX 6212 Assessment 1 Quality and Safety Gap Analysis</span></h3>
                        <p><span data-preserver-spaces="true">Further recommendations incorporate measurable goals and proposed results to conclude whether our implementation is effective. Therefore, the momentary goal will be to sign up. Moreover, four oral care champions, two on each shift, will be trained on the expected performance and documentation measures to be met. The goal is to set an improvement rate of 5% each quarter, with a benchmark goal of 80% within nine months.</span></p>
                        <p><span data-preserver-spaces="true">The focal plane of the long-term goal will be to retain the two oral care champions for each shift yet increase the total number of oral care champions to eight to mitigate turnover and give continuing education to staff individuals while maintaining a benchmark of 80% in oral care performance measures as reflected in the VAAES National Oral Care Documentation Note. Picking two oral care champions for each shift will give consistent training, engage staff in completing oral care and documentation, and educate patients on how oral care is associated with pneumonia.</span></p>
                        <h2><span data-preserver-spaces="true">PrioritizedPrioritized Practice Changes</span></h2>
                        <p><span data-preserver-spaces="true">Several strategic interventions will sustain this organization's need to replicate an oral care intervention to chip away at the consistency and quality of care given to inpatient veterans at the Biloxi VA Medical Center and decrease the rate of NV-HAP while saving lives and healthcare dollars. Regardless, executing a standardized two-times day oral care compliance program can reduce the rate of ventilator-acquired pneumonia (VAP) and nonventilated hospital-acquired pneumonia (NVHAP) by 25% to 40% (Kallet, 2019).</span></p>
                        <p><span data-preserver-spaces="true">Secondly, increasing staff retention and enlistment will mitigate the turnover of oral care champions who continue to educate team members and veterans. Lastly, the revamp of the ongoing documentation timeframe will be executed so that each shift of oral care is accounted for within the same 24-hour term. This approach will provide the necessary documentation to meet the national VAAES oral care performance measures benchmark of 80%.</span></p>
                        <h2><span data-preserver-spaces="true">Fostering a Culture of Quality and Safety</span></h2>
                        <p><span data-preserver-spaces="true">According to research, high medical attendant turnover is the leading cause of staff burnout, adverse falls, pressure wounds, and sentinel occasions. Additionally, effective medical attendant leaders are essential to advance an alternate, exhaustive, and motivated multidisciplinary team, which can increase staff retention and decrease medical blunders and patient dissatisfaction (Smama'h et al., 2023). Threats may continue to ascend as the healthcare field is in a constant state of change.</span></p>
                        <p><span data-preserver-spaces="true">Therefore, the organization and unit should mitigate turnover by backfilling positions in advance, conducting post-business outlines to legitimize staff departures, and acting on nursing enrollment and retention. Staff turnover, which incorporates the turnover of oral care champions, confines the organization's ability to retain appropriately trained staff to maintain the 80% national benchmark goal.</span></p>
                        <p><span data-preserver-spaces="true">The organization also gambles losing healthcare dollars and repayments if a patient is diagnosed with hospital-acquired pneumonia and the patient chart does not mirror the necessary VAAES oral care note documentation. These threats are straightforwardly related to the obstruction of Adventure HAPPEN's mission statement to deal with the consistency and quality of oral care given to inpatient veterans while reducing the repeat of pneumonia and saving lives and healthcare dollars.</span></p>
                        <h2><span data-preserver-spaces="true">Organizational Culture Effect on Safety and Quality</span></h2>
                        <p><span data-preserver-spaces="true">An ideal-to-go leader should have the capability of assessing their organization or unit's environment and have the option to understand its demands and guide any potential threats toward its goals while further creating health care quality and safety. Executing a strategic plan for pushing their unit or organization ahead is paramount to remaining serious and allows interaction for designing, carrying out, and evaluating process enhancements (Winter, 2021). Because sustained leaders and managers play a vital and pivotal role in the healthcare framework, they should maintain characteristics that cultivate trust, regard, and professionalism while keeping lines of communication clear.</span></p>
                        <ul>
                            <li>
                                <h3><span data-preserver-spaces="true">Building a Safety Culture</span></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">OrganizationalOrganizational culture aims to establish a safety culture that brilliantly lights on patient safety, and the cutoff focuses on patient injury. Complex ailment management and staffing shortages continue to make healthcare increasingly annoying, which raises the gamble of high patient-to-sustain ratios and clinical screws. Effective and proficient communication between healthcare leaders and their labour force fosters teamwork and forestalls a blame culture.</span></p>
                        <p><span data-preserver-spaces="true">Additionally, transparent communication, cooperation, teamwork, healthy workplaces, and encouraging transformational leaders can reduce attrition, decrease resistance to organizational changes, and increase specialist satisfaction (Britto et al., 2020).</span></p>
                        <h2><span data-preserver-spaces="true">Conclusion</span></h2>
                        <p><span data-preserver-spaces="true">The mission of the Bay Coast Veterans Healthcare Framework (GCVHCS) is to offer quality administration for veterans by regarding and caring for their physical, psychological, and spiritual health. It encapsulates a vision to engage veterans through a partnership beyond treating infection. It takes a stab at optimal health and a positive healthcare experience (About Us | VA Channel Coast Health Care | Veterans Affairs, 2023). Therefore, healthcare organizations should work on patient safety by adopting and executing the five place standards of high-reliability organizations (HROs), primarily centred around analyzing, decreasing, and forestalling patient harm.</span></p>
                        <p><span data-preserver-spaces="true">As attendant leaders, we should analyze internal and external key performance indicators (KPIs) to help facilitate achieving organizational performance goals (Smith &amp; Johnson, 2018). In&nbsp;</span><a href="https://www.studocu.com/en-us/document/capella-university/health-care-quality-and-safety-management/nurs-fpx-6212-assessment-1-quality-and-safety-gap-analysis/77278487" target="_blank"><span data-preserver-spaces="true">NURS FPX 6212 Assessment 1 Quality and Safety Gap Analysis</span></a><span data-preserver-spaces="true">, organizational leaders should continue to evaluate the proficiency and effectiveness of continuous quality improvement initiatives to serve our veterans more readily while decreasing mortality and HAI rates.</span></p>
                        <h2><span data-preserver-spaces="true">References</span></h2>
                        <p><em><span data-preserver-spaces="true">About us | VA Gulf Coast health care | Veterans Affairs</span></em><span data-preserver-spaces="true">. (2023, June 1). Veterans Affairs.&nbsp;</span><u><span data-preserver-spaces="true">https://</span></u><a href="http://www.va.gov/gulf-coast-health-care/about-us/" target="_blank"><span data-preserver-spaces="true">www.va.gov/gulf-coast-health-care/about-us/</span></a></p>
                        <p><span data-preserver-spaces="true">Brito Ferreira, V., Coelho Amestoy, S., Reis da Silva, G. T., de Lima Trindade, L., Reis dos Santos, I. A., &amp; Alves Galhardo Varanda, P. (2020). Transformational leadership in nursing practice: challenges and strategies.&nbsp;</span><em><span data-preserver-spaces="true">Revista Brasileira de Enfermagem</span></em><span data-preserver-spaces="true">,&nbsp;</span><em><span data-preserver-spaces="true">73</span></em><span data-preserver-spaces="true">, 1&ndash;7.&nbsp;</span><a href="https://doi.org/10.1590/0034-7167-2019-0364" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.1590/0034-7167-2019-0364</span></a></p>
                        <p><span data-preserver-spaces="true">Kallet, R. H. (2019). Ventilator bundles in transition: from prevention of ventilator-associated pneumonia to prevention of ventilator-associated events...new horizons symposium: ventilator-associated events - surveillance and understanding of the arc congress 2018, December 4-7, 2018, Las Vegas, Nevada.&nbsp;</span><em><span data-preserver-spaces="true">Respiratory Care</span></em><span data-preserver-spaces="true">,&nbsp;</span><em><span data-preserver-spaces="true">64</span></em><span data-preserver-spaces="true">(8), 994&ndash;1006.&nbsp;</span><u><a href="https://doi.org/10.4187/respcare.06966" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.4187/respcare.06966</span></a></u></p>
                        <p><span data-preserver-spaces="true">Munro, S. C., Baker, D., Giuliano, K. K., Sullivan, S. C., Haber, J., Jones, B. E., Crist, M. B., Nelson, R. E., Carey, E., Lounsbury, O., Lucatorto, M., Miller, R., Pauley, B., &amp; Klompas, M. (2021). Nonventilator hospital-acquired pneumonia: A call to action: Recommendations from the National Organization to Prevent Hospital-Acquired Pneumonia (NOHAP) among nonventilated patients.&nbsp;</span><em><span data-preserver-spaces="true">Infection Control and Hospital Epidemiology, 42</span></em><span data-preserver-spaces="true">(8), 991&ndash;996.&nbsp;</span><u><a href="https://doi-org.library.capella.edu/10.1017/ice.2021.239" target="_blank"><span data-preserver-spaces="true">https://doi-org.library.capella.edu/10.1017/ice.2021.239</span></a></u></p>
                        <p><span data-preserver-spaces="true">Mynař&iacute;kov&aacute;, E., Jaro&scaron;ov&aacute;, D., Jan&iacute;kov&aacute;, E., Plevov&aacute;, I., Polansk&aacute;, A., &amp; Zelen&iacute;kov&aacute;, R. (2020). Occurrence of hospital-acquired infections about missed nursing care: A literature review.&nbsp;</span><em><span data-preserver-spaces="true">Central European Journal of Nursing &amp; Midwifery</span></em><span data-preserver-spaces="true">,&nbsp;</span><em><span data-preserver-spaces="true">11</span></em><span data-preserver-spaces="true">(1), 43&ndash;49.&nbsp;</span><u><a href="https://doi.org/10.15452/CEJNM.2020.11.0007" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.15452/CEJNM.2020.11.0007</span></a></u></p>
                        <p><span data-preserver-spaces="true">Smama'h, Y., Eshah, N. F., Al-Oweidat, I. A., Rayan, A., &amp; Nashwan, A. J. (2023). The impact of nurse managers' leadership styles on nurses' motivation and turnover intention among Jordanian nurses.&nbsp;</span><em><span data-preserver-spaces="true">Journal of Healthcare Leadership, 15</span></em><span data-preserver-spaces="true">, 19-&nbsp;</span><u><a href="https://doi.org/10.2147/JHL.S394601" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.2147/JHL.S394601</span></a></u></p>
                        <p><span data-preserver-spaces="true">Smith, C. M., &amp; Johnson, C. S. (2018). Preparing nurse leaders in nursing professional development: Leadership programs.&nbsp;</span><em><span data-preserver-spaces="true">Journal for Nurses in Professional Development, 34</span></em><span data-preserver-spaces="true">(3), 158&ndash;161.&nbsp;</span><u><a href="https://doi.org/10.1097/NND.0000000000000434" target="_blank"><span data-preserver-spaces="true">https://doi.org/10.1097/NND.0000000000000434</span></a></u></p>
                        <p><span data-preserver-spaces="true">Winter, L. F. (2021). Strategic planning: A roadmap to success at the concert.&nbsp;</span><em><span data-preserver-spaces="true">Radiation Therapist</span></em><span data-preserver-spaces="true">,&nbsp;</span><em><span data-preserver-spaces="true">30</span></em><span data-preserver-spaces="true">(2), 214&ndash;217.</span></p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Nursfpx6212assessment1
