import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Jus212topic6 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>JUS 212 Topic 6 Differential Association Theory</title>
                <meta name='description'
                    content="Explore the JUS 212 Topic 6 Differential Association Theory, analyzing how individuals learn criminal behavior through interactions and societal influences." />
                <meta name='keywords' content='JUS 212 Topic 6 Differential Association Theory' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>JUS 212 Topic 6 < br /><span>Differential Association Theory</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Jus212topic6.webp" alt="JUS 212 Topic 6 Differential Association Theory" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><strong><em>JUS 212 Topic 6 Differential Association Theory</em></strong></li>
                        </ul>
                        <h2><strong>Differential Association Theory</strong></h2>
                        <p>The differential association theory was made by a criminologist named Edwin H. Sutherland in 1939. This theory is about "the principle that criminal acts are related to an individual's openness to an overabundance amount of antisocial attitudes and values" (Siegel, 2018). Donald Cressey further expanded this theory, who was an associate of Sutherland after Sutherland passed on in 1950. There are 7 basic principles that the differential association theory follows. These include: "that criminal behavior is learned, learning is a result of interaction, Criminal strategies are learned, Point of view on the legal code influence points of view and drives, Differential associations may vary in repeat, duration, need, and intensity, &hellip;," (Siegel, 2018). This paper will explain what kind of cliques are found in a high school setting and a criminal setting, and then, on the off chance, the differential association theory can explain the behaviors of the cliques of that age.</p>
                        <h2><strong>Cliques in a High School Setting</strong></h2>
                        <p>In high school, certain groups of individuals/cliques are usually framed depending on social classes, ages, interests, religions, and so forth. The most stereotypical cliques are the athletes, geeks, cheerleaders, gamers, menaces, etc. According to Andrews, instincts play a crucial part in who individuals decide to be companions with and what cliques to join; individuals usually have wants, and cliques can be explained with the differential association theory. "Individuals who engage in antisocial activities also see and accept that their dearest companions and close associates engage in antisocial activities" (2018).</p>
                        <p>Cliques of individuals who partake in criminal behavior usually find companions who do as well, whether taking medications, stealing, etc. Gym rats, for example, are known to be popular and party a great deal, so usually they find companions who are also fitness junkies and or partiers. Using the differential association theory, cliques are explained by peers learning behaviors from other companions and forming cliques because they are familiar and share the same interests.</p>
                        <h2><strong>Cliques Relative Propensity to Involvement in Crime</strong></h2>
                        <p>Cliques in high school that have a propensity to be involved in crime are because of social influence and usually want to not adhere to guidelines. Usually, the cliques who party are the main ones getting involved with crimes during high school because, generally, alcohol and medications are involved. According to Rokven et al.," He demonstrated that the impact of delinquent companions on an individual's probability of committing a crime is more grounded among individuals who are considerably more profoundly attached and who invest relatively more energy with their companions" (2016). <br /><br />The differential association theory demonstrates that behavior is influenced and learned, not randomly gained, especially criminal behavior. Regardless of whether one fits in with a particular gathering that does criminal behavior, part of the time, peer strain can make somebody feel like they need to find a place with certain cliques. Most teenagers in high school will do anything to find a place with the more notable or "popular" kids. "In case an admired companion drinks and smokes, it makes it significantly easier for an ally to engage in those behaviors and to accept they are appropriate" (Siegel, 2018).</p>
                        <h2><strong>Differential Association Theory Explaining the Behaviors of Groups of that Age</strong></h2>
                        <p>The differential association theory can explain the behaviors of groups at the high school age. According to Siegel, "Acquiring a behavior is a social learning process, not a political or legal interaction" (2018). Teenagers love to attempt to find a place with specific groups to make more companions, and on the off chance that one faction has criminal behavior, a couple of youths will follow their example and learn their behaviors. High school kids spend at least 8 hours a day, 5 days seven days together, which is a great deal of time for young people to learn behaviors from the companions around them. Peer pressure is a significant thing that youths will deal with while trying to fit in.</p>
                        <h2><strong>Conclusion</strong></h2>
                        <p>In conclusion, the differential association theory is about individuals being introduced to criminal behavior, making one also want to do criminal behavior. The differential association theory also explains why individuals join certain cliques/groups. The cliques who usually do criminal behavior learn these behaviors from the other individuals in the gathering. Most teenagers will do things they are uncomfortable with to&nbsp;<a href="https://www.studocu.com/en-us/document/grand-canyon-university/criminal-behavior-and-victimology/differential-association-theory-jus-212/10015058">JUS 212 Topic 6 Differential Association Theory</a>&nbsp;find a place with peers who they fit as "cool" or "popular." This is important because the differential association theory can explain why specific individuals lean towards criminal behavior more than others.</p>
                        <h2><strong>References</strong></h2>
                        <p>Andrews, E. L. (2014, December 4). New study explores why cliques thrive in some high schools more than others. <em>Stanford Graduate School of Education.</em> Retrieved from<a href="https://ed.stanford.edu/news/new-study-explores-why-cliques-thrive-some-high-schools-more-others">https://ed.stanford.edu/news/new-study-explores-why-cliques-thrive-some-high-schools-more-others</a></p>
                        <p>Rokven, J. J., Boer, G. D., Tolsma, J., &amp; Ruiter, S. (2016, December 28). How friends&rsquo; involvement in crime affects the risk of offending and desistance. <em>European Journal of Criminology.</em> Retrieved from<a href="https://journals.sagepub.com/doi/full/10.1177/1477370816684150">https://journals.sagepub.com/doi/full/10.1177/1477370816684150</a></p>
                        <p>Siegel, L. J. (2018). <em>Criminology: Theories, patterns, and typologies</em> (13th ed.). Thomson Wadsworth.</p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Jus212topic6