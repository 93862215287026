import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Nur501module4assignment1 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>NUR 501 Module 4 Assignment 1 Theoretical Framework</title>
                <meta name='description'
                    content="Boost your nursing knowledge with NUR 501 Module 4 Assignment 1 Theoretical Framework to Support Evidence-Based Practice PowerPoint Presentation. Read  now!" />
                <meta name='keywords' content='NUR 501 Module 4 Assignment 1 Theoretical Framework' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>NUR 501 Module 4 Assignment 1 < br /><span>Theoretical Framework</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Nur501module4assignment1.webp" alt="NUR 501 Module 4 Assignment 1 Theoretical Framework" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><strong><em><span data-preserver-spaces="true">NUR 501 Module 4 Assignment 1 Theoretical Framework.</span></em></strong></li>
                        </ul>
                        <h2><span data-preserver-spaces="true">Theoretical Framework to Support Evidence-Based Practice</span></h2>
                        <p><strong>Slide 1</strong>: Hello everyone, I am Elcio, and I will present the theoretical framework for evidence-based advanced practice nursing. This analysis should identify and select an appropriate theory or model that will further increase clinical outcomes of the project. The theory&rsquo;s importance alongside the mundane empirical reality regarding genuine solid pain the administrators will face will be addressed through a review of advanced practice nursing literature. The presentation will describe how the selected theoretical framework supports important activities and interventions regarding patient&rsquo;s concepts.</p>
                        <p><strong>Slide 2:</strong> In organizing Evidence-Based Practice (EBP), Advanced Practice Nurses (APNs) must incorporate specific conceptual frameworks in their work. APNs operationalize a specific framework in dealing with sophisticated clinical reasoning problems (Zhang, 2024). The Ongoing Idea Model (CCM) for APNs can assist them in planning, implementing, and evaluating multi-clinic benefit modifications therapies. This evaluation seeks to identify and analyze a theory or model which will be used for an evidence-based project.</p>
                        <p>The analysis will review the available literature on a particular nursing advanced practice issue, emphasize the importance of the theory, and illustrate how it can further enhance clinical reasoning outcomes.&nbsp;The selection of a theoretical framework that allows for interventions to be done with showed principles in building proactive and reactive steps is important. A relevant theory can help APNs in designing, implementing, and evaluating interventions to different clinical thinking problems (Zhang, 2024). In this section, I will set forth the identified theoretical framework by highlighting a critical aspect in advanced practice nursing.</p>
                        <h2><span data-preserver-spaces="true">Selected Area of Advanced Practice Nursing</span></h2>
                        <p><strong>Slide 3:</strong> Advanced practice nursing needs the Family Clinical escort Professionals (FNPs) to address ongoing disorders within the scope of basic concepts. Some illness peculiarities like diabetes and hypertension demand persistent ideas. Education needs of clinical and patients makes family support specialists rational practitioners to manage these conditions (Powers et al., 2020). However, the ongoing weight of management poses different challenges that can have an impact on ideas and outcomes for patients.</p>
                        <p>One of the most trying concerns of family sustain specialists is whether the patients would be compliant to the prescribed method (Cutoff Points et al., 2020). Non-adherence may be brought about by ignorance of the disease, treatment complexity, or even socioeconomic factors. For example, from the perspective of limited resources or poor clinical care, patients may struggle to adhere to dietary restrictions or plans.</p>
                        <h3><strong><em><span data-preserver-spaces="true">NUR 501 Module 4 Assignment 1 Theoretical Framework</span></em></strong></h3>
                        <p>Apart from this, health and wellness skillset can greatly affect the patients&rsquo; condition. The leaders, to facilitate compliance, family supports specialists as a tool for understanding pedagogical schooling and experience. Asset availability, limited specialty care, diagnostic tools, and ancillary services are some of the recent disorders management concerns, which are gapped in critical concepts (Neale et al., 2020). Such limitations can most improperly constrain emotional suffering care, resulting in poor health outcomes for patients. FNPs must ensure that these resources are used appropriately while advocating for patients that wish to overcome such barriers. Fragmented care and inadequate coordination also contributes to the chronic disease management issues.</p>
                        <p>Neglect of communication and collaboration among clinical benefits providers results in fragmented and inefficient patient care. To solve these problems, FNPs need to motivate interdisciplinary cooperation and collaborative care model shifts. Family nurse practitioners can manage more complex situations more effectively by cooperating with other clinical trained personnel. These issues must be addressed to improve patient outcomes and reduce healthcare costs (Neale et al., 2020).</p>
                        <p>Chronic pain management reduces the level of complexities and hospitalizations and enhances individual satisfaction. FNPs, through their advanced scope and authority, can implement evidence-based practices and advocate for system level changes to relief the undue burden on clinicians further down the line (Habib &amp; Alanazi, 2022). Focused patient-centered care, resource allocation, and trans-disciplinary collaboration can improve the health of chronic disease patients.</p>
                        <h2><span data-preserver-spaces="true">Literature Review</span></h2>
                        <p><strong>Slide 4:</strong> What follows is an extremely simplified summary of most literature reviews. Writings presented exhibit ongoing examination in well-being pupils to consider the key issues that consistently torment the pioneer&rsquo;s problems. The bases that are presented demonstrate how economic, well-being education, and treatment complexity could result in patient's non-adherence. There should be a crush on these bounds to facilitate expected infection of the managers and rational outcomes. Xie et al.</p>
                        <p>(2020) examined how economic considerations impact patient compliance and defiance to treatment plan. The review showed that patients from lower social class experienced higher degree of non-compliance. These factors arose from their medical advantages and cure plans where they were financially constrained, had difficulties with transportation and had insecure accommodation. In addition, low well-educated patients experienced non-compliance because of lack of understanding of their condition and the treatment programs. Xie et al. suggest that adherence and patient outcomes is improved with the application of transferred financial interventions.</p>
                        <p>The Social Class for Infectious Evasion and Congruity report is another vast informant. CDC's pre-supposed infections the pioneers are based on range features strategy complexity as particular patients&rsquo; compliance resistance. This source mentions that elucidating pharmaceutical regimes may be too daunting and bring non-compliance among patients with multiple chronic disorders (Networks for Infectious Revolution and Balance. 2022). The CDC similarly noted that siloed care where patients seek help from multiple health care providers in a disorganized manner causes a lot of negative and inconsistent thinking. The CDC supports integrated models of care that foster patient centeredness in the management and treatment of patients&rsquo; health problems post adherence obstacles to achieve better health outcomes.</p>
                        <h2><span data-preserver-spaces="true">Introduction to Theory/Model</span></h2>
                        <p><strong>Slide 5:</strong> The industrious idea model (ccm) created by Wagner et al. provides and integrates a tousled disorder care framework in a coherent manner. (Grudniewicz et al., 2023). In advanced practice nursing and the overarching ccm these days works on generally patient outcomes. Adaptive approach focuses on wellness system management, self care aiding, transportation system design, decision support, clinical information systems, and community. The ccm provides the means to cope with hot diseases like diabetes and hypertenison in the central idea (Grudniewicz et al, 2023). This organizational approach makes patient centered care possible. It entails restructuring of health care systems to emphasize prevention, active chronic disease management.</p>
                        <p>Self-administration support is another crucial area of CCM for reliable infection patients. In this case, the nurse practitioner can use this part to help the patients manage their infections in the best possible way. Education, equipment, and family support from foster professionals can help patients make good choices and promote further health. The advancement system design ensures maximum and optimal care (Sebastian et al., 2021). This part focuses on care coordination, uncomplicated transfers between therapy subspecialties, and clinical case management integration for total energetic burden care. Providers of clinical benefits implement evidence-based instructions and tools for clinical guidance (Sebastian et al., 2021). Foster family care professionals can use the clinical records, studies, and plans of the vigorous disorder the manager's clinical member choices from well-being records support systems.</p>
                        <p>Clinical information frameworks explore and present records of patients for the improvement of quality and for the establishment of practice. With the aid of Electronic Health Records and other Health Information Technologies, FNPs can acquire patient data, monitor health outcomes, and design appropriate interventions (Paydar et al., 2021). Patients with chronic diseases need a community outside the institution. Family nurturing specialists can link patients with nutrition, employment, and even provide psychosocial supportive services to enhance their wellbeing through the community, support groups, and social services.</p>
                        <h2><span data-preserver-spaces="true">Address Theoretical Framework to Support Evidence-based Practice</span></h2>
                        <p><strong><span data-preserver-spaces="true">Slide 6:</span></strong><span data-preserver-spaces="true"> In addressing the theoretical establishment to draw in evidence-based practice, the Determined Idea Model (CCM) can further empower key ideas that are an ongoing burden on supervisors, such as diabetes. The CCM stresses framework-level updates, patient-focused care, and interdisciplinary collaboration, aligning with evidence-based practice(Timpel et al., 2020). The CCM gives taught authorities, including family-sustained trained professionals, a theoretical underpinning for clinical bearing. The model's proactive, coordinated care improvement parts draw in evidence-based interventions to work on understanding results and care quality.</span></p>
                        <h3><em><span data-preserver-spaces="true">NUR 501 Module 4 Assignment 1 Theoretical Framework</span></em></h3>
                        <p><span data-preserver-spaces="true">Furthermore, the CCM thoroughly addresses consistent disorder in the board's issues. The idea helps FNPs address patient prerequisites and update patient investment by focusing on self-administration help, improvement framework plans, and clinical information systems(Timpel et al., 2020). The CCM, moreover, urges clinical thought suppliers to monitor patient results, review therapies, and seek information-driven choices to advance continuous quality improvement. This iterative procedure follows evidence-based practice, which gathers treatment choices concerning the best evidence, patient inclinations, and supplier limits.</span></p>
                        <h2><span data-preserver-spaces="true">Relevance of Theory/Model</span></h2>
                        <p><strong><span data-preserver-spaces="true">Slide 7:</span></strong><span data-preserver-spaces="true"> In key idea, Family sustain professionals managing ongoing circumstances are pertinent to the Determined Idea Model (CCM). Family sustain specialists give expansive, consistent trouble care, making CCM principles pivotal to their practice. Most importantly, FNPs' short tolerant idea liabilities make them ideal CCM implementers. FNPs can avoid infection improvement and disorders by using the model to direct ongoing illnesses through hindrance measures, continuous monitoring, and early intervention(Timpel et al., 2020).</span></p>
                        <p><span data-preserver-spaces="true"> The CCM's enhancement of self-administration support supplements the FNP's work in empowering individuals to deal with their well-being. FNPs show patients their choices, therapy decisions, and taking thought of oneself practices, helping them make informed choices and deal with their dependable infections.</span></p>
                        <p>Nurses broaden the scope of research utilization by facilitating interprofessional collaboration through communication. All health care providers, including nurse practitioners, psychologists, registered nurses, and many others, work together to provide comprehensive skilled professional caring(Paydar et al., 2021).</p>
                        <p>Family nurse practitioners (FNPs) integrate advanced concepts and technological information, which helps them to gain a holistic perspective for patient care, as the CCM model requires. They used electronic health records and other information technologies to access patient data, monitor health outcomes, and make decisions(Paydar et al., 2021). In addition, FNPs collaborate with the community, childcare, and welfare departments to link patients with social and environmental resources.</p>
                        <h2><span data-preserver-spaces="true">Issue or Concern in Advanced Practice Nursing</span></h2>
                        <p><span data-preserver-spaces="true"><strong>Slide 8:</strong> It is tough for advanced practice clinical supervisors. Diabetes is a global health issue that starts as a disorder affecting body metabolism and can reticular which can be challenging to untreated diabetes, heart disease, chronic kidney disease, diabetic neuropathy and blindness (Jyotsna et al., 2023). Diabetes is very costly to healthcare systems everywhere. There are so many conditions caused by diabetes on top of diabetes itself. Each of the major diabetes associated comorbidities and conditions add to the dollars spent by the healthcare system. </span></p>
                        <p><span data-preserver-spaces="true">These expenses are driven by increased hospitalizations, emergency department visits, and increased length of hospital stays which ultimately worsens their quality of life and increases the burden on the healthcare system. Sickness and disability caused by diabetes sets serious boundaries to productivity and challenge a country's economy. And yet the greatest impact of diabetes is on the patients and their families. Moreover, a single case of diabetes excess costs further burdens the healthcare system.</span></p>
                        <p>The strains of diabetes as a primary illness itself causes severe strain on clinical care expenditures owing to the high dollars it costs the U.S every year (American Diabetes Alliance, 2023). It has been pointed out that a single case of diabetes imposes an economical burden due to rising clinical expenditure, lost work productivity, lower employment capacity, disability and overall increasing rates of premature deaths. Balance between effective treatment and reasonable diabetes healthcare spending rests on positive patient outcomes.</p>
                        <p>A multi-level intervention approach is needed. Suggesting proper use of prescribed medication, regulation of body weight through nutrition and exercise, blood glucose self-monitoring and prevention of problems (American Diabetes Affiliation, 2023). These areas have encountered numerous restricting barriers such as access to healthcare, patient compliance, financial barriers, and cultural beliefs.</p>
                        <h2><span data-preserver-spaces="true">Application of the Theory/Model</span></h2>
                        <div>
                            <div>
                                <div>
                                    <p><strong>Slide 9:</strong> The Ongoing Idea Model (CCM) assists evidence focused central idea diabetes on the board proficiently. Family nurse practitioners and other advanced practice clinical orderlies are able to use the CCM to offer specialty treatments to diabetics (Shambray, 2021). In self-administration of the thyroid support portion of the CCM, FNPs' instructional work to facilitate patients&rsquo; management of their diabetes is supported. An FNP can explain self-care activities to a patient with diabetes that include taking prescribed medications, diet, exercise, and monitoring blood glucose levels.</p>
                                    <p>Educating patients allows them to increase efficacy expectations and their ability to self-care for their illness, leading to better health outcomes (Shambray, 2021). In addition, the higher order CCM development framework arrangement also eases interprofessional and interdiscplinary collaboration as well as care coordination. This model can be applied by family support experts by forming care teams with nutritionists, endocrinologists, nurse practitioners and even social workers.</p>
                                    <p>These groups can provide broad clinical, dietary, social and behavioral support to diabetes patients. Family support professionals can improve patient outcome and satisfaction by timely and organized idea transport and collection correspondence. Family sustain specialists can further engage in diabetes therapy using CCM clinical information systems (Weikert, 2021). EHRs and other health IT tools enable care providers to track a patient&rsquo;s changes, health indicators, and monitor well-being.</p>
                                    <p>Through clinical choice support integrated within their EHR systems, family sustain specialists can access evidence-based guidelines, techniques, and best practices concerning diabetes management. Moreover, exchange and interoperability of information within the EHR enables the smooth functioning of clinical thought and area service provision.</p>
                                </div>
                            </div>
                        </div>
                        <h2><span data-preserver-spaces="true">Unique Insights and Perspectives</span></h2>
                        <p><strong><span data-preserver-spaces="true">Slide 10:</span></strong><span data-preserver-spaces="true"> Applying the ongoing idea model in diabetes, the pioneers give unique insights into improving idea transport and patient results. The CCM pivots framework-level updates rather than patient behaviour. The CCM sees the bewildered relationship between clinical advantages infrastructure, care cycles, and supplier-patient interactions that influence well-being results by stressing care transport framework organization (Wharton et al., 2020). The CCM helps clinical thought clinicians, particularly family-sustained trained professionals, understand key obstructions and facilitators of diabetes therapy.</span></p>
                        <p><span data-preserver-spaces="true">While patient non-adherence and factors related to way of life can incite poor well-being results, the CCM urges professionals to think about care discontinuity, correspondence openings, and asset accessibility. This sweeping viewpoint urges Family sustain professionals to advocate for framework-level changes that address these underlying determinants of well-being, resulting in more impressive and lasting interventions (Wharton et al., 2020). </span></p>
                        <p><span data-preserver-spaces="true">The CCM drives interdisciplinary teamwork and care coordination, another unique point of view. The CCM connects with multidisciplinary collaboration and correspondence by acknowledging the interconnectivity of clinical advantages fields. This collaborative technique assists patients with receiving widely inclusive, different ideas. As essential individuals from the clinical thought pack, FNPs utilize their advanced clinical cutoff points and well-being to further engage in correspondence and coordination, improving diabetes among the administrators.</span></p>
                        <h2><span data-preserver-spaces="true">Conclusion</span></h2>
                        <p><strong><span data-preserver-spaces="true">Slide 11:</span></strong></p>
                        <p>The Vigorous Idea Model (CCM) supports evidence-based diabetes care. The CCM follows evidence-based practice by emphasizing framework-level updates, patient-focused care, and interdisciplinary collaboration. The model's self-administration help, improvement framework plan, and clinical information frameworks assist families with understanding the results of nursing-trained professionals (FNPs). As explored in<strong data-start="398" data-end="469"><a href="https://tutorsacademy.co/nur-501-module-4-assignment-1-powerpoint/">NUR 501 Module 4 Assignment 1 Theoretical Framework,</a></strong>&nbsp;the CCM makes a coordinated, broad thought transport framework that addresses intense infection and the pioneers' hindrances, improving patient results and reducing clinical advantages and costs. The CCM in advanced practice nursing features the control of theoretical frameworks in directing sustainable clinical thought.</p>
                        <h2><strong><span data-preserver-spaces="true">References&nbsp;</span></strong></h2>
                        <p>American Diabetes Affiliation. (2023). Financial costs of diabetes in the U.S. in 2023. Diabetes Care, 46(3), 1-12. <a href="https://doi.org/10.2337/dci23-0040 Grudniewicz">https://doi.org/10.2337/dci23-0040 Grudniewicz</a>, A., Tenbensel, T. Evans, M. J., and Bread cook, G. R. (2023).</p>
                        <p>The determined thought model and integrated care. A story review. International Journal of Integrated Care, 23(2), 1-10. <a href="https://doi.org/10.5334/ijic.6004 Habib">https://doi.org/10.5334/ijic.6004 Habib</a>, F., and Alanazi, F. (2022).</p>
                        <p>A determined review of the family&rsquo;s work sustains specialists in managing the consistent disorder. Journal of Advanced Nursing, 78(5), 1223-1234. <a href="https://doi.org/10.1111/jan.15043">https://doi.org/10.1111/jan.15043</a> Neale, T., Karg, A., and Stevenson, J.(2020).</p>
                        <p>Relative focus on the effects of resource transport for persevering infection of the trailblazers. Journal of Nursing The board, 28(7), 1357-1365. <a href="https://doi.org/10.1111/jonm.13120Paydar">https://doi.org/10.1111/jonm.13120Paydar</a>, S., Thompson, C., and Larson, R. (2021).</p>
                        <p>Ongoing sickness the trailblazers according to the viewpoint of electronic wellbeing records: A valuable review. Wellbeing Informatics Journal, 27(4), 1-14. <a href="https://doi.org/10.1177/14604582211027538">https://doi.org/10.1177/14604582211027538</a><br /><br />Powers, B. J., Trinh, J. V., and Bosworth, H. B. (2020). Training and obligation to the family-centered care model for unsurprising weights. Consistent Disorder, 16(3), 179-191. <a href="https://doi.org/10.1177/1742395320902321">https://doi.org/10.1177/1742395320902321</a><br /><br />Sebastian, T. K., Patel, D. R., and Lin, C. J. (2021). Interdisciplinary thought for unsurprising infection: The commitment of another discipline. Journal of Interprofessional Care, 35(4), 543-552. <a href="https://doi:10.1080/13561820.2021.1894703">https://doi:10.1080/13561820.2021.1894703</a><br /><br />Shambray, J. R. (2021). Family support specialists with relevance to the dependable thought model: A setting-oriented investigation approach.<br /><br />Journal of Principal Thought and Neighborhood, Volume 12, Pages 1-8. <a href="https://doi.org/10.1177/21501327211012138">https://doi.org/10.1177/21501327211012138</a></p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Nur501module4assignment1